// src/pages/Auth/SignUp.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signUpUser, checkEmailExistence } from '../../redux/auth/authThunks';
import { useNavigate } from 'react-router-dom';
import { clearErrors } from '../../redux/auth/authSlice'; // Import clearErrors action
import { useTranslation } from 'react-i18next'; // Import useTranslation

const SignUp = () => {
  const { t, i18n } = useTranslation(); // Access the translation function
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [signupError, setSignupError] = useState(''); // Error to show if email exists

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, signUpError } = useSelector((state) => state.auth); // Signup error from Redux

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validate the inputs
  const handleValidation = () => {
    let isValid = true;

    if (!name) {
      setNameError(t('auth.signUp.pleaseEnterName'));
      isValid = false;
    } else {
      setNameError('');
    }

    if (!email) {
      setEmailError(t('auth.signUp.pleaseEnterEmailAddress'));
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError(t('auth.signUp.pleaseEnterValidEmail'));
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError(t('auth.signUp.pleaseEnterPassword'));
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError(t('auth.signUp.passwordLengthError'));
      isValid = false;
    } else {
      setPasswordError('');
    }

    return isValid;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (handleValidation()) {
      // Check if the email already exists before proceeding to sign up
      dispatch(checkEmailExistence(email))
        .unwrap()
        .then((response) => {
          if (response.status === 1) {
            // Email already exists
            setSignupError(t('auth.signUp.emailExistsError'));
          } else {
            // Proceed with signup if the email does not exist
            dispatch(signUpUser({ name, email, password }))
              .unwrap()
              .then(() => {
                navigate('/'); // Redirect after successful sign-up
              })
              .catch((error) => {
                setSignupError(error || t('auth.signUp.errorDuringSignup'));
              });
          }
        })
        .catch(() => {
          setSignupError(t('auth.signUp.errorCheckingEmail'));
        });
    }
  };

  // Clear errors when email changes
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(''); // Reset email error on change
    if (signUpError || signupError) {
      dispatch(clearErrors()); // Clear server-side errors when the user starts typing
      setSignupError('');
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-50"
      dir={isArabic ? 'rtl' : 'ltr'} // Handle directionality
    >
      <div
        className={`w-full max-w-md p-8 bg-white rounded-lg shadow-lg ${
          isArabic ? 'text-right' : 'text-left' // Optional: Adjust text alignment
        }`}
      >
        <h2 className="mb-6 text-2xl font-extrabold text-gray-900">{t('auth.signUp.title')}</h2>

        {/* Name Field */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            {t('auth.signUp.name')}
          </label>
          <input
            type="text"
            id="name"
            className={`block w-full px-3 py-2 mt-1 border ${
              nameError ? 'border-red-500' : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            placeholder={t('auth.signUp.enterYourName')}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(''); // Reset error on input change
            }}
          />
          {nameError && <p className="mt-2 text-sm text-red-500">{nameError}</p>}
        </div>

        {/* Email Field */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {t('auth.signUp.email')}
          </label>
          <input
            type="email"
            id="email"
            className={`block w-full px-3 py-2 mt-1 border ${
              emailError || signupError ? 'border-red-500' : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            placeholder={t('auth.signUp.enterYourEmail')}
            value={email}
            onChange={handleEmailChange} // Handle email change
          />
          {emailError && <p className="mt-2 text-sm text-red-500">{emailError}</p>}
          {signupError && <p className="mt-2 text-sm text-red-500">{signupError}</p>} {/* Server-side error */}
        </div>

        {/* Password Field */}
        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            {t('auth.signUp.password')}
          </label>
          <input
            type="password"
            id="password"
            className={`block w-full px-3 py-2 mt-1 border ${
              passwordError ? 'border-red-500' : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            placeholder={t('auth.signUp.enterYourPassword')}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(''); // Reset error on input change
            }}
          />
          {passwordError && <p className="mt-2 text-sm text-red-500">{passwordError}</p>}
        </div>

        {/* Remember Me Checkbox */}
        <div className="flex items-center mb-4">
          <input
            id="remember-me"
            type="checkbox"
            className="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-500"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <label
            htmlFor="remember-me"
            className={`block ml-2 mr-2 text-sm text-gray-900 ${
              isArabic ? 'text-right' : 'text-left'
            }`}
          >
            {t('auth.signUp.rememberMe')}
          </label>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isLoading ? 'bg-gray-500' : 'bg-black'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          >
            {isLoading ? t('auth.signUp.signingUp') : t('auth.signUp.registerButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
