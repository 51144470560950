// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import auth_en from './locales/en/auth.json';
import help_en from './locales/en/help.json';
import store_en from './locales/en/store_en.json';
import delivery_en from './locales/en/delivery_en.json';
import timing_en from './locales/en/timing_en.json';
import locationinfo_en from './locales/en/locationinfo_en.json';
import manageStore_en from './locales/en/manageStore_en.json';
import home_en from './locales/en/home_en.json';
import mainScreen_en from './locales/en/mainScreen_en.json';
import storeInfo_en from './locales/en/storeInfo_en.json';
import productsList_en from './locales/en/productsList_en.json';
import ordersSummary_en from './locales/en/ordersSummary_en.json';
import productInfoPage_en from './locales/en/productInfoPage_en.json'; // New import

import auth_ar from './locales/ar/auth.json';
import help_ar from './locales/ar/help.json';
import store_ar from './locales/ar/store_ar.json';
import delivery_ar from './locales/ar/delivery_ar.json';
import timing_ar from './locales/ar/timing_ar.json';
import locationinfo_ar from './locales/ar/locationinfo_ar.json';
import manageStore_ar from './locales/ar/manageStore_ar.json';
import home_ar from './locales/ar/home_ar.json';
import mainScreen_ar from './locales/ar/mainScreen_ar.json';
import storeInfo_ar from './locales/ar/storeInfo_ar.json';
import productsList_ar from './locales/ar/productsList_ar.json';
import ordersSummary_ar from './locales/ar/ordersSummary_ar.json';
import productInfoPage_ar from './locales/ar/productInfoPage_ar.json'; // New import
import productSizeColorPage_en from './locales/en/productSizeColorPage_en.json';
import productSizeColorPage_ar from './locales/ar/productSizeColorPage_ar.json';
import productImagePage_en from './locales/en/productImagePage_en.json';
import productImagePage_ar from './locales/ar/productImagePage_ar.json';
import viewAllProducts_en from './locales/en/viewAllProducts_en.json';
import viewAllProducts_ar from './locales/ar/viewAllProducts_ar.json';
import manageProduct_en from './locales/en/manageProduct_en.json'
import manageProduct_ar from './locales/ar/manageProduct_ar.json';
import headerEn from './locales/en/header_en.json';
import headerAr from './locales/ar/header_ar.json';
import footerEn from './locales/en/footer_en.json';
import footerAr from './locales/ar/footer_ar.json';
import confirmationModalEn from './locales/en/confirmationmodal_en.json';
import confirmationModalAr from './locales/ar/confirmationmodal_ar.json';
import sidebarEn from './locales/en/sidebar_en.json';
import sidebarAr from './locales/ar/sidebar_ar.json';
i18n
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        auth: auth_en,
        help: help_en,
        store: store_en,
        delivery: delivery_en,
        timing: timing_en,
        locationinfo: locationinfo_en,
        manageStore: manageStore_en,
        home: home_en,
        mainScreen: mainScreen_en,
        storeInfo: storeInfo_en,
        productsList: productsList_en,
        ordersSummary: ordersSummary_en,
        productInfoPage: productInfoPage_en ,
        productSizeColorPage: productSizeColorPage_en,
        productImagePage: productImagePage_en,
        viewAllProducts: viewAllProducts_en,
        manageProduct: manageProduct_en,
        header: headerEn,
        footer: footerEn,
        confirmationModal: confirmationModalEn,
        sidebar: sidebarEn
      },
      ar: {
        auth: auth_ar,
        help: help_ar,
        store: store_ar,
        delivery: delivery_ar,
        timing: timing_ar,
        locationinfo: locationinfo_ar,
        manageStore: manageStore_ar,
        home: home_ar,
        mainScreen: mainScreen_ar,
        storeInfo: storeInfo_ar,
        productsList: productsList_ar,
        ordersSummary: ordersSummary_ar,
        productInfoPage: productInfoPage_ar,
        productSizeColorPage: productSizeColorPage_ar,
        productImagePage: productImagePage_ar,
        viewAllProducts: viewAllProducts_ar,
        manageProduct: manageProduct_ar,
        header: headerAr,
        footer: footerAr,
        confirmationModal: confirmationModalAr,
        sidebar: sidebarAr
      }
    },
    fallbackLng: 'en', // Fallback language
    debug: true, // Enable debug for development
    ns: [
      'auth',
      'help',
      'store',
      'delivery',
      'timing',
      'locationinfo',
      'manageStore',
      'home',
      'mainScreen',
      'storeInfo',
      'productsList',
      'ordersSummary',
      'productInfoPage',
      'productSizeColorPage',
      'productImagePage',
      'viewAllProducts',
      'manageProduct',
      'header',
      'footer',
      'confirmationModal',
      'sidebar'
    ],
    defaultNS: 'auth', // Default namespace
    interpolation: {
      escapeValue: false // React already safes from XSS
    },
    react: {
      useSuspense: false // Disable suspense for easier debugging
    }
  });

export default i18n;
