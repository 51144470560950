// src/pages/Auth/ContinueOptionScreen.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmailExistence } from '../../redux/auth/authThunks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import FeatureSection from '../../components/FeatureSection'; // Import the FeatureSection component
import TestimonialSection from '../../components/TestimonialSection.js';
import StepsSection from '../../components/StepsSection.js';
import CTASection from '../../components/CTASection.js';
import FAQSection from '../../components/FAQSection.js';

const ContinueOptionScreen = () => {
  const { t, i18n } = useTranslation(); // Access the translation function and i18n instance
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const [email, setEmail] = useState(''); // State to store email
  const [emailError, setEmailError] = useState(''); // State for email validation error
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, emailExists, error } = useSelector((state) => state.auth); // Redux state

  // Regular expression for validating email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Handle navigation based on the result of the email check
  useEffect(() => {
    if (emailExists === true) {
      navigate('/signin', { state: { email } }); // Pass email to the SignIn page
    } else if (emailExists === false) {
      navigate('/signup'); // If email doesn't exist, go to SignUp
    }
  }, [emailExists, navigate, email]);

  // Clear error message when the email becomes valid
  useEffect(() => {
    if (email && emailRegex.test(email)) {
      setEmailError(''); // Clear error if the email is valid
    }
  }, [email]);

  // Validate email format and handle dispatch
  const handleContinue = () => {
    if (!email) {
      setEmailError(t('auth.continueOption.errorPleaseEnterEmail'));
      toast.error(t('auth.continueOption.errorPleaseEnterEmail'));
    } else if (!emailRegex.test(email)) {
      setEmailError(t('auth.continueOption.errorPleaseEnterValidEmail'));
      toast.error(t('auth.continueOption.errorPleaseEnterValidEmail'));
    } else {
      dispatch(checkEmailExistence(email)); // Check if email exists
    }
  };

  // Handle language toggle
  const handleLanguageToggle = () => {
    const newLanguage = isArabic ? 'en' : 'ar';
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="flex flex-col min-h-screen text-white bg-gray-900">
      {/* Top Section - Authentication Form */}
      <div className="flex flex-col flex-grow lg:flex-row">
        {/* Left Side - Professional 3D E-commerce Image */}
        <div
          className="relative hidden bg-center bg-cover lg:flex lg:w-1/2"
          style={{ backgroundImage: 'url(/images/markaz-3d-hero.jpg)' }} // Replace with your 3D e-commerce image path
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-60"></div>
          {/* Overlay Text */}
          <div className="relative z-10 flex items-center justify-center h-full px-10">
            <h2 className="text-5xl font-extrabold leading-tight text-center">
              Join <span className="text-green-500">Millions</span> of Satisfied Customers
            </h2>
          </div>
        </div>

        {/* Right Side - Form Section */}
        <div className="flex flex-col justify-center w-full px-8 py-12 bg-gray-800 lg:w-1/2">
          <div
            className={`max-w-md w-full mx-auto bg-gray-900 rounded-lg shadow-lg p-8 relative ${
              isArabic ? 'text-right' : 'text-left'
            }`}
          >
            {/* Decorative Elements */}
            <div className="absolute top-0 right-0 w-16 h-16 -mt-8 -mr-8 bg-green-500 rounded-full opacity-20"></div>
            <div className="absolute bottom-0 left-0 w-16 h-16 -mb-8 -ml-8 bg-green-500 rounded-full opacity-20"></div>

            <h1 className="mb-4 text-3xl font-extrabold text-center">
              Welcome Back!
            </h1>
            <p className="mb-6 text-center text-gray-400">
              Please enter your email to continue.
            </p>

            <p className="mb-4 text-xl font-semibold text-center">
              Sign Up or Log In
            </p>

            {/* Email Input */}
            <div className="mt-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                Email Address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError('');
                }}
                className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                  emailError ? 'border-red-500' : 'border-gray-600'
                } rounded-md focus:outline-none focus:ring-2 focus:ring-green-500`}
                placeholder="you@example.com"
              />
              {emailError && <p className="mt-2 text-sm text-red-500">{emailError}</p>}
            </div>

            {isLoading && (
              <div className="flex justify-center mt-4">
                <div className="w-8 h-8 border-4 border-t-4 border-gray-400 rounded-full animate-spin"></div>
              </div>
            )}

            {error && <p className="mt-2 text-red-500">{error}</p>}

            {/* Continue Button */}
            <div className="mt-6">
              <button
                onClick={handleContinue}
                disabled={isLoading}
                className={`w-full py-2 px-4 rounded-md shadow-lg text-sm font-medium text-white ${
                  isLoading
                    ? 'bg-gray-600 cursor-not-allowed'
                    : 'bg-green-500 hover:bg-green-600'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-200`}
              >
                {isLoading ? 'Processing...' : 'Continue'}
              </button>
            </div>

            {/* Language Toggle */}
            <div className="flex justify-center mt-4">
              <button
                onClick={handleLanguageToggle}
                className="text-sm text-gray-400 hover:text-gray-200 focus:outline-none"
              >
                {isArabic ? 'English' : 'العربية'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Feature Section */}
      <FeatureSection />
      <TestimonialSection/>
      <StepsSection/>
      <CTASection/>
      <FAQSection/>
    </div>
  );
};

export default ContinueOptionScreen;
