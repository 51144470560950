// // src/redux/order/orderSlice.js
// import { createSlice } from '@reduxjs/toolkit';
// import {
//   fetchOrders,
//   acceptOrder,
//   initiateRefund,
//   refundRequest,
// } from './orderThunks';

// const initialState = {
//   orders: [],
//   isLoading: false,
//   error: null,
//   hasNextPage: true,
//   currentPage: 1,
// };

// const orderSlice = createSlice({
//   name: 'order',
//   initialState,
//   reducers: {
//     resetOrders: (state) => {
//       state.orders = [];
//       state.currentPage = 1;
//       state.hasNextPage = true;
//     },
//     incrementPage: (state) => {
//       state.currentPage += 1;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       // Fetch Orders
//       .addCase(fetchOrders.pending, (state) => {
//         state.isLoading = true;
//         state.error = null;
//       })
//       .addCase(fetchOrders.fulfilled, (state, action) => {
//         state.isLoading = false;
//         const { orders, hasNextPage } = action.payload;
//         state.orders = [...state.orders, ...orders];
//         state.hasNextPage = hasNextPage;
//       })
//       .addCase(fetchOrders.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//       })
//       // Accept Order
//       .addCase(acceptOrder.fulfilled, (state, action) => {
//         const updatedOrder = action.payload;
//         state.orders = state.orders.map((order) =>
//           order.id === updatedOrder.id ? updatedOrder : order
//         );
//       })
//       // Initiate Refund
//       .addCase(initiateRefund.fulfilled, (state, action) => {
//         const updatedOrder = action.payload;
//         state.orders = state.orders.map((order) =>
//           order.id === updatedOrder.id ? updatedOrder : order
//         );
//       })
//       // Refund Request
//       .addCase(refundRequest.fulfilled, (state, action) => {
//         const updatedOrder = action.payload;
//         state.orders = state.orders.map((order) =>
//           order.id === updatedOrder.id ? updatedOrder : order
//         );
//       });
//   },
// });

// export const { resetOrders , incrementPage} = orderSlice.actions;
// export default orderSlice.reducer;
