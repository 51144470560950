// src/pages/StoreInfo.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessCategories,
  fetchStoreDetails,
  createOrUpdateStore,
} from '../../redux/store/storeThunks';
import { FaArrowLeft } from 'react-icons/fa';
import CircularLoader from '../../components/Shared/CircularLoader';
import { uploadImageAPI } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const StoreInfo = ({ nextStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('store'); // Set 'store' as default namespace
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';
  const { categories, isLoadingCategories, store, isSavingStore } = useSelector(
    (state) => state.store
  );

  const [formValues, setFormValues] = useState({
    nameBusiness: '',
    nameBusinessAr: '',
    mobileNo: '',
    businiessCategory: '',
    instagram: '',
    website: '',
    storeType: '', // Ensure this maps correctly to what the API expects
    image: null,
    storeId: '', // Initialize storeId (will hold the store's _id)
  });

  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  // Load vendorId from local storage (this will remain constant for the session)
  const user = JSON.parse(localStorage.getItem('user')); 
  const vendorId = user ? user._id : null;

  // Fetch categories and store details on component load
  useEffect(() => {
    dispatch(getBusinessCategories());

    // Fetch store details if the vendorId exists
    if (vendorId) {
      dispatch(fetchStoreDetails(vendorId));
    }
  }, [dispatch, vendorId]);

  // Populate form with existing store data if available
  useEffect(() => {
    if (store) {
      const updatedFormValues = {
        nameBusiness: store.nameBusiness || '',
        nameBusinessAr: store.nameBusinessAr || '',
        mobileNo: store.mobileNo || '',
        businiessCategory: store.businiessCategory || '',
        instagram: store.instagram || '',
        website: store.website || '',
        storeType: store.storeType || '', // Correct mapping of storeType
        image: store.image || null,
        storeId: store._id || '', // Capture the store's _id
      };
      setFormValues(updatedFormValues);
      setImagePreview(store.image || null);
    }
  }, [store]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // Handle image upload
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploadingImage(true);
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);

      try {
        const response = await uploadImageAPI(file);

        if (response.data.status === 1) {
          const imageUrl = response.data.message;
          setFormValues((prev) => ({ ...prev, image: imageUrl }));
        } else {
          setErrors((prev) => ({ ...prev, image: t('residualText.imageUploadError') }));
        }
      } catch (error) {
        setErrors((prev) => ({ ...prev, image: t('residualText.imageUploadFailed') }));
      } finally {
        setIsUploadingImage(false);
      }
    }
  };

  // Form validation
  const validateForm = () => {
    const { nameBusiness, nameBusinessAr, mobileNo, businiessCategory, storeType } = formValues;
    let formErrors = {};

    if (!nameBusiness) formErrors.nameBusiness = t('residualText.storeNameRequired');
    if (!nameBusinessAr) formErrors.nameBusinessAr = t('residualText.storeNameArabicRequired');
    if (!mobileNo) formErrors.mobileNo = t('residualText.mobileNumberRequired');
    else if (mobileNo.length !== 8) formErrors.mobileNo = t('residualText.mobileNumberInvalid');
    if (!businiessCategory) formErrors.businiessCategory = t('residualText.businessCategoryRequired');
    if (!storeType) formErrors.storeType = t('residualText.storeTypeRequired');

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Submit form
  const handleSubmit = async () => {
    if (validateForm()) {
      const storeData = {
        ...formValues,
        vendorId, // Add vendorId to the payload
        image: formValues.image,
      };

      // Pass storeData to createOrUpdateStore thunk
      const resultAction = await dispatch(createOrUpdateStore(storeData));

      // Check for fulfilled action
      if (createOrUpdateStore.fulfilled.match(resultAction)) {
        const updatedStore = resultAction.payload;

        // Update formValues with the new storeId if a new store was created
        if (!formValues.storeId && updatedStore._id) {
          setFormValues((prev) => ({
            ...prev,
            storeId: updatedStore._id, // Ensure storeId is stored for future updates
          }));
        }

        // Move to the next step, passing storeData including the storeId
        nextStep({ updatedStore });
      }
    }
  };

  if (isLoadingCategories) return <CircularLoader />;

  return (
    <div className="min-h-screen p-1 bg-white" dir={t('dir')}> {/* Dynamically set direction */}
      <button
        className="flex items-center gap-2 mb-4 text-gray-800 transition-colors duration-200 hover:text-gray-600 focus:outline-none"
        onClick={() => navigate('/')}
      >
        <FaArrowLeft className="mr-2" /> {t('back')}
      </button>
      <h1  dir={isArabic ? 'rtl' : 'ltr'} className="mb-8 text-3xl font-bold text-center text-gray-900 ">{t('storeInformation')}</h1>

      <form className="max-w-3xl mx-auto space-y-8">
        {/* Store Name */}
        <div className="md:flex md:space-x-6">
          <div className="flex-1 mb-4">
            <label className="block text-lg font-semibold text-gray-700">
              {t('storeName')} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="nameBusiness"
              value={formValues.nameBusiness}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            />
            {errors.nameBusiness && <p className="text-sm text-red-500">{errors.nameBusiness}</p>}
          </div>

          {/* Store Name (Arabic) */}
          <div className="flex-1 mb-4">
            <label className="block mr-4 text-lg font-semibold text-gray-700">
              {t('storeNameArabic')} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="nameBusinessAr"
              value={formValues.nameBusinessAr}
              onChange={handleChange}
              className="w-full p-3 mr-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            />
            {errors.nameBusinessAr && <p className="text-sm text-red-500">{errors.nameBusinessAr}</p>}
          </div>
        </div>

        {/* Store Category */}
        <div className="mb-4">
          <label className="block text-lg font-semibold text-gray-700">
            {t('storeCategory')} <span className="text-red-500">*</span>
          </label>
          <select
            name="businiessCategory"
            value={formValues.businiessCategory}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            <option value="">{t('selectCategory')}</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
               {currentLanguage === 'ar' ? category.nameAr : category.nameEn} {/* Use Arabic name if available */}
              </option>
            ))}
          </select>
          {errors.businiessCategory && (
            <p className="text-sm text-red-500">{errors.businiessCategory}</p>
          )}
        </div>

        {/* WhatsApp Mobile */}
        <div className="mb-4">
          <label className="block text-lg font-semibold text-gray-700">
            {t('whatsappMobile')} <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            name="mobileNo"
            value={formValues.mobileNo}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder={t('enterEightDigits')}
          />
          {errors.mobileNo && <p className="text-sm text-red-500">{errors.mobileNo}</p>}
        </div>

        {/* Instagram */}
        <div className="mb-4">
          <label className="block text-lg font-semibold text-gray-700">{t('instagram')}</label>
          <input
            type="url"
            name="instagram"
            value={formValues.instagram}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        {/* Website */}
        <div className="mb-4">
          <label className="block text-lg font-semibold text-gray-700">{t('website')}</label>
          <input
            type="url"
            name="website"
            value={formValues.website}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        {/* Store Type */}
        <div className="mb-4">
          <label className="block text-lg font-semibold text-gray-700">
            {t('storeType')} <span className="text-red-500">*</span>
          </label>
          <div className="flex space-x-6">
            <label className="flex items-center gap-2" >
              <input
                type="radio"
                name="storeType"
                value="STORE"
                checked={formValues.storeType === 'STORE'}
                onChange={handleChange}
                className="mr-2"
              />
              {t('physical')}
            </label>
            <label className="flex items-center gap-2 ">
              <input
                type="radio"
                name="storeType"
                value="ONLINE"
                checked={formValues.storeType === 'ONLINE'}
                onChange={handleChange}
                className="mr-2"
              />
              {t('online')}
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="storeType"
                value="HOME"
                checked={formValues.storeType === 'HOME'}
                onChange={handleChange}
                className="mr-2"
              />
              {t('home')}
            </label>
          </div>
          {errors.storeType && <p className="text-sm text-red-500">{errors.storeType}</p>}
        </div>

        {/* Store Image Upload */}
        <div className="mb-4">
          <label className="block mt-4 text-lg font-semibold text-gray-700">{t('storeImage')}</label>
          <input
            type="file"
            name="image"
            onChange={handleImageChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <p className="text-sm text-gray-500">{t('noFileChosen')}</p>
          {imagePreview && (
            <div className="mt-2">
              <img
                src={imagePreview}
                alt={t('storePreview')}
                className="object-cover w-24 h-24 border border-gray-300 rounded-md"
              />
            </div>
          )}
          {errors.image && <p className="text-sm text-red-500">{errors.image}</p>}
        </div>

        {/* Save and Next Button */}
        <button
          type="button"
          onClick={handleSubmit}
          className="w-full px-4 py-2 text-lg font-semibold text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500"
          disabled={isSavingStore || isUploadingImage}
        >
          {isSavingStore || isUploadingImage ? t('saving') : t('saveAndNext')}
        </button>
      </form>
    </div>
  );
};

export default StoreInfo;