// src/components/Dashboard/StoreInfo.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const StoreInfo = ({ storeData, isAdmin }) => {
  const { t } = useTranslation('storeInfo'); // Initialize with 'storeInfo' namespace
  const navigate = useNavigate();

  const handleEditStore = () => {
    if (isAdmin) {
      // For admin, navigate to /manage-store without storeId
      navigate('/manage-store');
    } else {
      // For regular users, navigate to /manage-store
      navigate('/manage-store');
    }
  };

  return (
    <div className="p-8 bg-white border border-gray-200 rounded-lg shadow-sm">
      <div className="flex flex-col space-y-6 md:flex-row md:items-start md:justify-between md:space-y-0">
        <div className="flex items-center space-x-6">
          <img
            src={storeData?.image || 'https://via.placeholder.com/112'}
            alt={t('alt.storeLogo')} // Translated alt text
            className="object-cover rounded-lg shadow-sm w-28 h-28"
          />
          <div>
            <h1 className="text-3xl font-bold text-gray-800">
              {storeData?.nameBusiness || t('storeDetails.storeName')} {/* Translated default store name */}
            </h1>
            <p className="mt-1 text-gray-500">
              {storeData?.mobileNo || t('storeDetails.noMobile')} {/* Translated default mobile number */}
            </p>
            <p className="mt-1 text-gray-500">
              {storeData?.website || t('storeDetails.noWebsite')} {/* Translated default website */}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-3 md:items-end">
          <button
            className="px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
            onClick={handleEditStore}
          >
            {t('buttons.editStore')} {/* Translated button label */}
          </button>
          <div
            className={`inline-flex items-center px-3 py-1 text-sm font-medium rounded-md ${
              storeData?.storeReviewStatus === '1'
                ? 'bg-green-100 text-green-800'
                : storeData?.storeReviewStatus === '0'
                ? 'bg-yellow-100 text-yellow-800'
                : storeData?.storeReviewStatus === '-1'
                ? 'bg-red-100 text-red-800'
                : 'bg-gray-100 text-gray-800'
            }`}
          >
            {storeData?.storeReviewStatus === '1'
              ? t('status.approved')
              : storeData?.storeReviewStatus === '0'
              ? t('status.underReview')
              : storeData?.storeReviewStatus === '-1'
              ? t('status.rejected')
              : t('status.unknown')}
          </div>
        </div>
      </div>

      {/* Missing Information Warnings */}
      {storeData && (
        <div className="mt-6 space-y-4">
          {!storeData.image && (
            <div className="flex items-center p-4 border-l-4 border-yellow-500 rounded-md bg-yellow-50">
              <span className="mr-3 text-xl">⚠️</span>
              <div>
                <p className="text-sm text-yellow-700">
                  {t('warnings.missingImage')}{' '}
                  <button
                    className="font-semibold text-blue-600 underline hover:text-blue-800"
                    onClick={handleEditStore}
                  >
                    {t('warnings.addImage')}
                  </button>
                </p>
              </div>
            </div>
          )}
          {!storeData.deliveryType && (
            <div className="flex items-center p-4 border-l-4 border-yellow-500 rounded-md bg-yellow-50">
              <span className="mr-3 text-xl">⚠️</span>
              <div>
                <p className="text-sm text-yellow-700">
                  {t('warnings.missingDeliveryType')}{' '}
                  <button
                    className="font-semibold text-blue-600 underline hover:text-blue-800"
                    onClick={handleEditStore}
                  >
                    {t('warnings.specifyDeliveryType')}
                  </button>
                </p>
              </div>
            </div>
          )}
          {(!storeData.shopTimings || storeData.shopTimings.length === 0) && (
            <div className="flex items-center p-4 border-l-4 border-yellow-500 rounded-md bg-yellow-50">
              <span className="mr-3 text-xl">⚠️</span>
              <div>
                <p className="text-sm text-yellow-700">
                  {t('warnings.missingTimings')}{' '}
                  <button
                    className="font-semibold text-blue-600 underline hover:text-blue-800"
                    onClick={handleEditStore}
                  >
                    {t('warnings.setTimings')}
                  </button>
                </p>
              </div>
            </div>
          )}
          {!storeData.location && (
            <div className="flex items-center p-4 border-l-4 border-yellow-500 rounded-md bg-yellow-50">
              <span className="mr-3 text-xl">⚠️</span>
              <div>
                <p className="text-sm text-yellow-700">
                  {t('warnings.missingLocation')}{' '}
                  <button
                    className="font-semibold text-blue-600 underline hover:text-blue-800"
                    onClick={handleEditStore}
                  >
                    {t('warnings.setLocation')}
                  </button>
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StoreInfo;
