// src/components/Shared/CircularLoader.js

import React from 'react';

const CircularLoader = () => {
  return (
    <div
      className="flex items-center justify-center min-h-screen bg-gray-50"
      role="status"
      aria-label="Loading"
    >
      <div className="relative w-32 h-32">
        {/* Outer Box */}
        <div className="absolute inset-0 border-4 border-blue-500 rounded-lg animate-pulse-slow"></div>

        {/* Spinning Circles */}
        <svg
          className="absolute top-0 left-0 w-full h-full"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Gradient Definitions */}
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#3B82F6" /> {/* Blue-500 */}
              <stop offset="100%" stopColor="#2563EB" /> {/* Blue-600 */}
            </linearGradient>
            <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#10B981" /> {/* Emerald-500 */}
              <stop offset="100%" stopColor="#059669" /> {/* Emerald-600 */}
            </linearGradient>
          </defs>

          {/* Outer Circle */}
          <circle
            cx="50"
            cy="50"
            r="45"
            stroke="url(#gradient1)"
            strokeWidth="5"
            fill="none"
            strokeLinecap="round"
            className="animate-spin-slow"
          />

          {/* Middle Circle */}
          <circle
            cx="50"
            cy="50"
            r="35"
            stroke="url(#gradient2)"
            strokeWidth="5"
            fill="none"
            strokeLinecap="round"
            className="animate-spin-fast"
          />

          {/* Inner Circle */}
          <circle
            cx="50"
            cy="50"
            r="25"
            stroke="#FFFFFF"
            strokeWidth="5"
            fill="none"
            strokeLinecap="round"
            strokeDasharray="100, 200"
            className="animate-spin-reverse"
          />
        </svg>

        {/* Central Markaz Text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-lg font-bold text-[#06550f]">Markazz</span>
        </div>
      </div>

      {/* Custom Styles for Animations */}
      <style jsx>{`
        @keyframes spin-slow {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes spin-fast {
          0% {
            transform: rotate(360deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }

        @keyframes spin-reverse {
          0% {
            transform: rotate(360deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }

        @keyframes pulse-slow {
          0%, 100% {
            opacity: 1;
          }
          50% {
            opacity: 0.6;
          }
        }

        .animate-spin-slow {
          animation: spin-slow 8s linear infinite;
        }

        .animate-spin-fast {
          animation: spin-fast 4s linear infinite;
        }

        .animate-spin-reverse {
          animation: spin-reverse 6s linear infinite;
        }

        .animate-pulse-slow {
          animation: pulse-slow 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default CircularLoader;
