// src/components/Dashboard/ProductsList.js 

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaSearch, FaTimes, FaEdit, FaTrash } from 'react-icons/fa';
import ConfirmationModal from '../ConfirmationModal';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

// Loader styling
const override = `
  display: block;
  margin: 0 auto;
`;

const ProductsList = ({
  products,
  isLoading,
  error,
  searchInput,
  setSearchInput,
  clearSearch,
  noProductsFound,
  handleEditProduct,
  openDeleteModal,
  isModalOpen,
  confirmDeleteProduct,
  closeDeleteModal,
  isLoadingMore,
  subsequentError,
  totalPages,
  currentPage,
  handleNextPage,
  handlePreviousPage,
  productToDelete,
}) => {
  const { t } = useTranslation('productsList','confirmationModal','viewAllProducts'); // Initialize with 'productsList' namespace
  const navigate = useNavigate();

  const renderProductStatus = (status, reason) => {
    let statusText = '';
    let statusColor = '';

    switch (status) {
      case '-1':
        statusText = t('status.rejected');
        statusColor = 'bg-red-500';
        break;
      case '0':
        statusText = t('status.underReview');
        statusColor = 'bg-yellow-500';
        break;
      case '1':
        statusText = t('status.approved');
        statusColor = 'bg-green-500';
        break;
      default:
        statusText = t('status.unknown');
        statusColor = 'bg-gray-500';
    }

    return (
      <div className={`px-2 py-1 text-xs font-semibold text-white rounded ${statusColor}`}>
        {statusText}
        {status === '-1' && reason && (
          <div className="mt-1 text-xs">{t('messages.reason')}: {reason}</div>
        )}
      </div>
    );
  };

  const renderPrice = (product) => {
    const originalPrice = parseFloat(product.showPrice) || 0;
    const discountPercentage = parseFloat(product.showDiscountPercentage) || 0;

    const hasValidPrice = originalPrice > 0;
    const hasValidDiscount = discountPercentage > 0 && discountPercentage < 100;

    if (hasValidPrice && hasValidDiscount) {
      const discountedPrice = originalPrice * (1 - discountPercentage / 100);
      return (
        <div>
          <span className="text-gray-500 line-through">
            {t('currency.KWD')} {originalPrice.toFixed(2)}
          </span>
          <span className="ml-2 text-green-600">
            {t('currency.KWD')} {discountedPrice.toFixed(2)}
          </span>
        </div>
      );
    } else if (hasValidPrice) {
      return <span>{t('currency.KWD')} {originalPrice.toFixed(2)}</span>;
    } else {
      return <span>{t('currency.KWD')} 0.00</span>;
    }
  };

  return (
    <div className="min-h-screen p-4 bg-gray-50 md:p-6">
      {/* Header Section */}
      <div className="flex flex-col items-center justify-between mb-4 space-y-4 md:flex-row md:space-y-0">
        <h2 className="text-2xl font-extrabold text-gray-800 md:text-3xl">{t('titles.products')}</h2>
        <div className="flex space-x-2 md:space-x-4">
          <button
            className="flex items-center px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={() => navigate('/manage-product')}
          >
            <FaPlus className="mr-2 text-base md:text-lg" /> {t('buttons.addNewProduct')}
          </button>
          <button
            className="flex items-center px-4 py-2 text-white bg-gray-800 rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-600"
            onClick={() => navigate('/products')}
          >
            {t('buttons.viewAllProducts')}
          </button>
        </div>
      </div>

      {/* Search Field */}
      <div className="mb-6 md:mb-8">
        <div className="relative">
          <input
            type="text"
            placeholder={t('search.placeholder')}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="w-full p-2 pl-10 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label={t('search.ariaLabel')}
          />
          <FaSearch className="absolute text-gray-400 left-3 top-3" />
          {searchInput && (
            <button
              type="button"
              onClick={clearSearch}
              className="absolute text-gray-400 right-3 top-3 hover:text-gray-600 focus:outline-none"
              aria-label={t('search.clearAriaLabel')}
            >
              <FaTimes />
            </button>
          )}
        </div>
      </div>

      {/* No Products Found Message */}
      {noProductsFound && (
        <div className="p-6 text-center text-gray-600 bg-white rounded-md shadow">
          {t('messages.noProductsFound')}
        </div>
      )}

      {/* Product List as Cards */}
      {!noProductsFound && (
        <>
          {products && products.length > 0 ? (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {products.map((product) => (
                <div
                  key={product._id}
                  className="flex flex-col p-4 bg-white border border-gray-200 rounded-lg shadow hover:shadow-lg"
                  style={{ height: '400px', minHeight: '400px' }}
                >
                  {/* Product Image */}
                  <div className="relative flex-shrink-0 h-32 mb-4 overflow-hidden rounded-md">
                    {product.baseImages && product.baseImages.length > 0 ? (
                      <img
                        src={product.baseImages[0].url}
                        alt={product.productName}
                        className="object-contain w-full h-full"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/fallback-image.jpg';
                        }}
                      />
                    ) : (
                      <div className="flex items-center justify-center w-full h-full bg-gray-100">
                        <span className="text-gray-500">{t('messages.noImageAvailable')}</span>
                      </div>
                    )}
                    {/* Status Badge */}
                    <div className="absolute top-2 left-2">
                      {renderProductStatus(product.productReviewStatus, product.reason)}
                    </div>
                  </div>

                  {/* Product Details */}
                  <h3 className="text-lg font-semibold text-gray-800 truncate">
                    {product.productName}
                  </h3>
                  <p className="text-sm text-gray-700 truncate">
                    <strong>{t('labels.ID')}:</strong> {product.barcode || product._id}
                  </p>
                  <p className="text-sm text-gray-700 truncate">
                    <strong>{t('labels.sizes')}:</strong>{' '}
                    {product.sizes && product.sizes.length > 0
                      ? product.sizes.join(', ')
                      : t('labels.notAvailable')}
                  </p>
                  <p className="text-sm text-gray-700 truncate">
                    <strong>{t('labels.colors')}:</strong>{' '}
                    {product.colors && product.colors.length > 0
                      ? product.colors.map((color) => color.en).join(', ')
                      : t('labels.notAvailable')}
                  </p>
                  <p className="mb-2 text-sm text-gray-700">
                    <strong>{t('labels.stock')}:</strong> {product.totalStock}
                    {product.totalStock < 5 && (
                      <span className="ml-2 text-red-500">{t('labels.lowStock')}</span>
                    )}
                  </p>
                  <p className="mb-3 text-lg font-bold text-gray-800">
                    {renderPrice(product)}
                  </p>

                  {/* Actions */}
                  <div className="flex mt-auto space-x-2">
                    <button
                      className="flex items-center justify-center flex-1 px-2 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onClick={() => handleEditProduct(product._id)}
                      aria-label={t('buttons.editAriaLabel', { productName: product.productName })}
                    >
                      <FaEdit className="mr-1" /> {t('buttons.edit')}
                    </button>
                    <button
                      className="flex items-center justify-center flex-1 px-2 py-2 text-sm text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                      onClick={() => openDeleteModal(product._id)}
                      aria-label={t('buttons.deleteAriaLabel', { productName: product.productName })}
                    >
                      <FaTrash className="mr-1" /> {t('buttons.delete')}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center p-8 text-gray-600 bg-white rounded-md shadow">
              {t('messages.noProductsAvailable')}
            </div>
          )}

          {/* Loader during Infinite Scroll */}
          {isLoadingMore && (
            <div className="flex items-center justify-center mt-8">
              <ClipLoader
                color="#4A90E2"
                loading={isLoadingMore}
                css={override}
                size={35}
              />
            </div>
          )}

          {/* Error Message for Subsequent Pages */}
          {subsequentError && (
            <div className="flex items-center justify-center mt-8">
              <div className="p-6 text-red-500 bg-red-100 rounded-md shadow-md">
                {t('messages.errorDeletingProduct', { error })}
              </div>
            </div>
          )}

          {/* Confirmation Modal */}
          <ConfirmationModal
            isOpen={isModalOpen}
            title={t('modals.confirmDeletionTitle')}
            message={t('messages.deleteConfirmation')}
            onConfirm={confirmDeleteProduct}
            onCancel={closeDeleteModal}
          />
        </>
      )}
    </div>
  );
};

export default ProductsList;
