// src/redux/vendor/vendorThunks.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getVendorDetailAPI, updateVendorAPI } from '../../services/api';

// Thunk to fetch vendor details
export const getVendorDetail = createAsyncThunk(
  'vendor/getVendorDetail',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await getVendorDetailAPI(id);
      if (response.data.status === 1) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message || 'Failed to fetch vendor details.');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);

// Thunk to update vendor details
export const updateVendor = createAsyncThunk(
  'vendor/updateVendor',
  async ({ id, name }, { rejectWithValue }) => {
    try {
      const response = await updateVendorAPI(id, { name });
      if (response.data.status === 1) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message || 'Failed to update vendor.');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
  }
);
