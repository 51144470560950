import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation('footer');

  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  return (
    <footer
      className="w-full py-6 text-white bg-gray-900"
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      <div className="container mx-auto w-[90%] flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <p className="text-gray-400">
            © {new Date().getFullYear()} {t('companyName')}. {t('allRightsReserved')}
          </p>
        </div>

        <nav className={`flex ${isArabic ? 'space-x-reverse' : ''} space-x-6`}>
          <Link
            to="/privacy-policy"
            className="transition hover:text-yellow-300"
          >
            {t('links.termsAndConditions')}
          </Link>
          <Link
            to="/contact-us"
            className="transition hover:text-yellow-300"
          >
            {t('links.contactUs')}
          </Link>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
