// src/pages/LocationInfo.js
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createOrUpdateStore } from '../../redux/store/storeThunks';
import {
  GoogleMap,
  MarkerF,
  InfoWindow,
  Autocomplete,
  useJsApiLoader,
  CircleF,
} from '@react-google-maps/api';
import CircularLoader from '../../components/Shared/CircularLoader';
import { useTranslation } from 'react-i18next';
import { FaMapMarkerAlt, FaRulerCombined } from 'react-icons/fa'; // Importing icons from react-icons

// Google Maps container style
const containerStyle = {
  width: '100%',
  height: '100%', // Make it responsive
};

// Default center point for the map (29.195103, 47.742321)
const defaultCenter = {
  lat: 29.195103,
  lng: 47.742321,
};

// Load Google Maps libraries
const libraries = ['places'];

const LocationInfo = ({ nextStep, storeData, onPrev }) => {
  const dispatch = useDispatch();

  // Initialize translation
  const { t, i18n } = useTranslation('locationinfo'); // Set 'locationinfo' as default namespace

  // Use your Google Maps API Key
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Ensure this is secured

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries,
  });

  // Log storeData to inspect it
  console.log('storeData:', storeData);

  const vendorId = storeData?.vendorId || null;
  const deliveryType = storeData?.deliveryType;

  const [location, setLocation] = useState({
    lat: storeData?.location?.lat || defaultCenter.lat,
    lng: storeData?.location?.lng || defaultCenter.lng,
    address: storeData?.location?.address || '',
    placeId: storeData?.location?.placeId || '',
  });

  const [radius, setRadius] = useState(storeData?.radiusStore?.radius || 1000);
  const [zoom, setZoom] = useState(12);
  const [autocomplete, setAutocomplete] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [infoWindowContent, setInfoWindowContent] = useState('');

  // State to manage active tab: 'location' or 'radius'
  const [activeTab, setActiveTab] = useState('location');

  useEffect(() => {
    if (storeData?.location) {
      setLocation(storeData.location);
    }
    if (storeData?.radiusStore?.radius) {
      setRadius(storeData.radiusStore.radius);
    }
  }, [storeData]);

  // Adjust the title based on deliveryType
  const title =
    deliveryType === 'VENDOR-DELIVERY'
      ? t('title.VENDOR-DELIVERY')
      : t('title.OTHER');

  // Function to update location state without dispatching immediately
  const updateLocation = (lat, lng, address, placeId) => {
    setLocation({ lat, lng, address, placeId });
    setInfoWindowContent(address);
    setInfoWindowOpen(true);
    setZoom(15);
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { location: { lat: latitude, lng: longitude } },
          (results, status) => {
            if (status === 'OK' && results[0]) {
              const address = results[0].formatted_address;
              const placeId = results[0].place_id;
              updateLocation(latitude, longitude, address, placeId);
            } else {
              alert(t('errors.geocodeFailure') + ': ' + status);
            }
          }
        );
      });
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        const placeId = place.place_id;
        updateLocation(lat(), lng(), place.formatted_address, placeId);
      }
    }
  };

  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const address = results[0].formatted_address;
        const placeId = results[0].place_id;
        updateLocation(lat, lng, address, placeId);
      }
    });
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const address = results[0].formatted_address;
        const placeId = results[0].place_id;
        updateLocation(lat, lng, address, placeId);
      }
    });
  };

  const handleRadiusChange = (e) => {
    const newRadius = parseInt(e.target.value, 10);
    setRadius(newRadius);
  };

  const handleSubmit = async () => {
    // Validation
    if (
      !location.lat ||
      !location.lng ||
      !location.address ||
      !location.placeId
    ) {
      alert(t('errors.locationRequired'));
      return;
    }

    if (deliveryType === 'VENDOR-DELIVERY' && radius === 0) {
      alert(t('errors.radiusRequired'));
      return;
    }

    // Prepare finalStoreData
    const finalStoreData = {
      ...storeData,
      location,
      vendorId,
      ...(deliveryType === 'VENDOR-DELIVERY' && {
        radiusStore: {
          radius,
          center: { coordinates: [location.lat, location.lng] },
        },
      }),
      // Map _id to storeId for the thunk
      storeId: storeData._id,
    };

    // Optionally remove _id if the backend doesn't expect it alongside storeId
    // delete finalStoreData._id;

    // Log the final store data before dispatching
    console.log('Final Store Data being sent to API:', finalStoreData);

    // Dispatch the thunk
    const result = await dispatch(createOrUpdateStore(finalStoreData));

    // Handle success or failure
    if (createOrUpdateStore.fulfilled.match(result)) {
      nextStep();
    } else {
      console.error('Failed to update store:', result.payload);
      alert(t('errors.updateFailed'));
    }
  };

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <CircularLoader />
      </div>
    );
  }

  const direction = i18n.dir();

  return (
    <div
      className="container max-w-4xl p-8 mx-auto bg-white rounded-lg shadow-md"
      dir={direction}
    >
      <h2 className="mb-6 text-3xl font-bold text-gray-800">{title}</h2>

      {/* Tab Navigation */}
      <div className="mb-8">
        <div className="flex border-b border-gray-200">
          <button
            className={`flex items-center mr-4 px-4 py-2 font-semibold text-lg rounded-t transition-colors duration-200 ${
              activeTab === 'location'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-600 hover:text-blue-600'
            } focus:outline-none`}
            onClick={() => setActiveTab('location')}
          >
            <FaMapMarkerAlt className="mr-2" />
            {t('tabs.location')}
          </button>
          {deliveryType === 'VENDOR-DELIVERY' && (
            <button
              className={`flex items-center px-4 py-2 font-semibold text-lg rounded-t transition-colors duration-200 ${
                activeTab === 'radius'
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-gray-600 hover:text-blue-600'
              } focus:outline-none`}
              onClick={() => setActiveTab('radius')}
            >
              <FaRulerCombined className="mr-2" />
              {t('tabs.radius')}
            </button>
          )}
        </div>
      </div>

      {/* Tab Content */}
      <div className="flex flex-col mb-8 space-y-6">
        {activeTab === 'location' && (
          <div className="flex flex-col gap-6 md:flex-row md:gap-8">
            {/* Use Current Location Button */}
            <button
              className="flex items-center justify-center px-6 py-3 font-semibold text-white transition-transform duration-200 transform bg-green-600 rounded-md hover:bg-green-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500"
              onClick={handleUseCurrentLocation}
              aria-label={t('useCurrentLocation')}
            >
              <svg
                className="w-5 h-5 mr-2 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2v2M12 20v2M4.93 4.93l1.42 1.42M17.66 17.66l1.42 1.42M2 12h2M20 12h2M4.93 19.07l1.42-1.42M17.66 6.34l1.42-1.42"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <circle
                  cx="12"
                  cy="12"
                  r="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
              {t('useCurrentLocation')}
            </button>

            {/* Autocomplete Search */}
            <div className="flex-1">
              <label
                className="block mb-2 text-lg font-medium text-gray-700"
                htmlFor="autocomplete"
              >
                {t('searchLocation')}
              </label>
              <Autocomplete onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged}>
                <input
                  id="autocomplete"
                  type="text"
                  placeholder={t('searchPlaceholder')}
                  className="w-full p-3 transition-colors duration-200 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  aria-label={t('searchLocation')}
                />
              </Autocomplete>
            </div>
          </div>
        )}

        {activeTab === 'radius' && deliveryType === 'VENDOR-DELIVERY' && (
          <div className="p-6 bg-gray-100 rounded-lg shadow-inner">
            <label
              className="block mb-4 text-lg font-medium text-gray-700"
              htmlFor="radius"
            >
              {t('selectDeliveryRadius')}
            </label>
            <div className="flex items-center">
              <input
                id="radius"
                type="range"
                min="100"
                max="120000" // Maximum value is 120,000 meters
                step="100"
                value={radius}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                onChange={handleRadiusChange}
                aria-valuemin="100"
                aria-valuemax="120000"
                aria-valuenow={radius}
                aria-label={t('selectDeliveryRadius')}
              />
            </div>
            {/* Custom Thumb */}
            <div className="flex items-center justify-between mt-2 text-sm text-gray-500">
              <span>100 m</span>
              <span>60,000 m</span>
              <span>120,000 m</span>
            </div>
            <p className="mt-4 text-lg text-gray-700">
              <span className="font-semibold text-gray-800">{t('selectedRadius')}:</span>{' '}
              {radius.toLocaleString()} meters
            </p>
          </div>
        )}
      </div>

      {/* Google Map */}
      <div className="mb-6">
        <div className="w-full overflow-hidden rounded-lg shadow-lg h-96">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: location.lat, lng: location.lng }}
            zoom={zoom}
            onClick={handleMapClick}
            options={{
              zoomControl: true,
              draggable: true,
              streetViewControl: false,
              mapTypeControl: false,
            }}
          >
            <MarkerF
              position={{ lat: location.lat, lng: location.lng }}
              draggable
              onDragEnd={handleMarkerDragEnd}
              onClick={() => setInfoWindowOpen(true)}
            />
            {infoWindowOpen && infoWindowContent && (
              <InfoWindow
                position={{ lat: location.lat, lng: location.lng }}
                onCloseClick={() => setInfoWindowOpen(false)}
              >
                <div className="text-gray-800">{infoWindowContent}</div>
              </InfoWindow>
            )}
            {deliveryType === 'VENDOR-DELIVERY' && (
              <CircleF
                center={{ lat: location.lat, lng: location.lng }}
                radius={radius}
                options={{
                  strokeColor: '#10B981',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#10B981',
                  fillOpacity: 0.2,
                }}
              />
            )}
          </GoogleMap>
        </div>
        <p className="mt-4 text-lg text-gray-700">
          <span className="font-semibold text-gray-800">{t('selectedAddress')}:</span>{' '}
          {location.address}
        </p>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={onPrev}
          className="flex items-center justify-center px-6 py-3 text-lg font-semibold text-white transition-transform duration-200 transform bg-gray-700 rounded-md hover:bg-gray-800 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-600"
        >
          {/* Optional Icon */}
          <svg
            className="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M15 19l-7-7 7-7"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
          {t('previous')}
        </button>
        <button
          type="button"
          className="flex items-center justify-center px-6 py-3 text-lg font-semibold text-white transition-transform duration-200 transform bg-blue-600 rounded-md hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={handleSubmit}
        >
          {t('saveAndNextButton')}
          {/* Optional Icon */}
          <svg
            className="w-5 h-5 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M5 13l4 4L19 7"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default LocationInfo;
