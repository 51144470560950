// src/pages/Timing.js
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOrUpdateStore } from '../../redux/store/storeThunks'
import CircularLoader from '../../components/Shared/CircularLoader'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next' // Import useTranslation hook

const Timing = ({ nextStep, prevStep, storeData, onPrev }) => {
  const dispatch = useDispatch()
  const { isSavingStore } = useSelector((state) => state.store)
  const { t } = useTranslation('timing')
  const defaultTimings = useMemo(
    () => [
      {
        isOpen: true,
        day: 'SUN',
        timeRanges: [{ openingTime: '', closingTime: '' }],
      },
      {
        isOpen: true,
        day: 'MON',
        timeRanges: [{ openingTime: '', closingTime: '' }],
      },
      {
        isOpen: true,
        day: 'TUE',
        timeRanges: [{ openingTime: '', closingTime: '' }],
      },
      {
        isOpen: true,
        day: 'WED',
        timeRanges: [{ openingTime: '', closingTime: '' }],
      },
      {
        isOpen: true,
        day: 'THU',
        timeRanges: [{ openingTime: '', closingTime: '' }],
      },
      {
        isOpen: true,
        day: 'FRI',
        timeRanges: [{ openingTime: '', closingTime: '' }],
      },
      {
        isOpen: true,
        day: 'SAT',
        timeRanges: [{ openingTime: '', closingTime: '' }],
      },
    ],
    []
  )

  // Initialize shopTimings
  const [shopTimings, setShopTimings] = useState(defaultTimings)
  const [errors, setErrors] = useState({})

  // Extract deliveryType from storeData to determine the title
  const deliveryType = storeData?.deliveryType || ''

  // Set the title based on deliveryType
  const timingTitle =
    deliveryType === 'VENDOR-DELIVERY'
      ? t('timing.deliveryTimings')
      : t('timing.pickupTimings')

  // Function to round time to the nearest half hour
  const roundTimeToNearestHalfHour = (time24h) => {
    if (!time24h || time24h.trim() === '') return ''
    const time = DateTime.fromFormat(time24h, 'HH:mm')
    if (!time.isValid) return ''

    const minutes = time.minute
    let roundedMinutes = 0
    let adjustedTime = time

    if (minutes <= 14) {
      roundedMinutes = 0
    } else if (minutes <= 44) {
      roundedMinutes = 30
    } else {
      roundedMinutes = 0
      adjustedTime = time.plus({ hours: 1 })
    }

    return adjustedTime
      .set({ minute: roundedMinutes, second: 0 })
      .toFormat('HH:mm')
  }

  // Convert 12-hour format time to 24-hour format for <input type="time" />
  const convertTo24Hour = (time12h) => {
    if (!time12h || time12h.trim() === '') return '' // Handle empty time values
    const parsedTime = DateTime.fromFormat(time12h, 'h:mm a')
    if (!parsedTime.isValid) return '' // Handle invalid time values
    return parsedTime.toFormat('HH:mm')
  }

  // Convert 24-hour format back to 12-hour format for submission
  const convertTo12Hour = (time24h) => {
    if (!time24h || time24h.trim() === '') return '' // Handle empty time values
    const parsedTime = DateTime.fromFormat(time24h, 'HH:mm')
    if (!parsedTime.isValid) return '' // Handle invalid time values
    return parsedTime.toFormat('h:mm a')
  }

  // Prepopulate time ranges with 24-hour format
  const prePopulateTimings = useCallback((timings) => {
    return timings.map((day) => ({
      ...day,
      isOpen: day.isOpen !== undefined ? day.isOpen : true, // Ensure isOpen is set
      timeRanges: day.timeRanges.map((range) => {
        return {
          openingTime: convertTo24Hour(range.openingTime),
          closingTime: convertTo24Hour(range.closingTime),
        }
      }),
    }))
  }, [])

  // Initialize timings on component mount
  useEffect(() => {
    try {
      console.log('storeData in Timing component:', storeData)
      if (
        storeData &&
        storeData.shopTimings &&
        storeData.shopTimings.length > 0
      ) {
        // Prepopulate shopTimings from existing storeData
        setShopTimings(prePopulateTimings(storeData.shopTimings))
      } else {
        // Use defaultTimings for new users or when shopTimings is empty
        setShopTimings(defaultTimings)
      }
    } catch (error) {
      console.error('Error initializing timings:', error)
      // Use defaultTimings in case of error
      setShopTimings(defaultTimings)
    }
  }, [storeData, prePopulateTimings, defaultTimings])

  // Validate time ranges to ensure no empty fields
  const validateTimings = () => {
    let formErrors = {}

    shopTimings.forEach((day) => {
      if (day.isOpen) {
        day.timeRanges.forEach((range) => {
          if (!range.openingTime || !range.closingTime) {
            formErrors[day.day] = t('timing.errors.openingClosingRequired', {
              day: t(`timing.day.${day.day}`),
            })
          } else {
            const openingTime = DateTime.fromFormat(range.openingTime, 'HH:mm')
            const closingTime = DateTime.fromFormat(range.closingTime, 'HH:mm')
            // Check if openingTime and closingTime are valid
            if (!openingTime.isValid || !closingTime.isValid) {
              formErrors[day.day] = t('timing.errors.invalidTimeFormat', {
                day: t(`timing.day.${day.day}`),
              })
            } else if (openingTime.equals(closingTime)) {
              formErrors[day.day] = t('timing.errors.sameOpeningClosing', {
                day: t(`timing.day.${day.day}`),
              })
            }
          }
        })
      }
    })

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  // Update time inputs for a particular day and time range
  const handleChange = (dayIndex, rangeIndex, field, value) => {
    const updatedTimings = [...shopTimings]

    // Round the time value before setting it
    const roundedTime = roundTimeToNearestHalfHour(value)

    updatedTimings[dayIndex].timeRanges[rangeIndex][field] = roundedTime
    setShopTimings(updatedTimings)
  }

  // Add a new time range for a particular day
  const addTimeRange = (dayIndex) => {
    const updatedTimings = [...shopTimings]
    updatedTimings[dayIndex].timeRanges.push({
      openingTime: '',
      closingTime: '',
    })
    setShopTimings(updatedTimings)
  }

  // Remove a time range for a particular day
  const removeTimeRange = (dayIndex, rangeIndex) => {
    const updatedTimings = [...shopTimings]
    updatedTimings[dayIndex].timeRanges.splice(rangeIndex, 1)
    setShopTimings(updatedTimings)
  }

  // Toggle open/close for a particular day
  const toggleDayOpen = (dayIndex) => {
    const updatedTimings = [...shopTimings]
    updatedTimings[dayIndex].isOpen = !updatedTimings[dayIndex].isOpen
    setShopTimings(updatedTimings)
  }

  // Handle form submission
  const handleSubmit = async () => {
    if (validateTimings()) {
      const updatedShopTimings = shopTimings.map((day) => ({
        ...day,
        timeRanges: day.timeRanges.map((range) => ({
          openingTime: convertTo12Hour(range.openingTime),
          closingTime: convertTo12Hour(range.closingTime),
        })),
      }))

      const user = JSON.parse(localStorage.getItem('user'))
      const vendorId = user ? user._id : null

      const updatedStoreData = {
        ...(storeData || {}),
        shopTimings: updatedShopTimings,
        vendorId,
      }

      // Include storeId if it exists; otherwise, omit it to create a new store
      if (storeData && storeData._id) {
        updatedStoreData.storeId = storeData._id
      }

      try {
        const result = await dispatch(
          createOrUpdateStore(updatedStoreData)
        ).unwrap()
        console.log('Store updated successfully:', result)
        nextStep(result) // Move to the next step
      } catch (error) {
        console.error('Failed to update store:', error)
      }
    }
  }

  // New function to handle copying current day's timings to all other open days
  const handleCopyToAll = (sourceDayIndex) => {
    const sourceDayTimings = shopTimings[sourceDayIndex].timeRanges
    const updatedTimings = shopTimings.map((day, index) => {
      if (index === sourceDayIndex || !day.isOpen) {
        return day
      }
      return {
        ...day,
        timeRanges: sourceDayTimings.map((range) => ({
          ...range,
        })),
      }
    })
    setShopTimings(updatedTimings)
  }

  // Render loader if data is being saved
  if (isSavingStore) {
    return <CircularLoader />
  }

  // Render the component
  try {
    return (
      <div
        className="max-w-3xl min-h-screen p-6 mx-auto bg-white"
        dir={t('timing.dir')}
      >
        {/* Dynamically set direction */}
        <h2 className="mb-6 text-3xl font-semibold text-gray-900">
          {timingTitle}
        </h2>
        <form className="space-y-6">
          {/* Existing Timings Section */}
          {shopTimings.map((day, dayIndex) => (
            <div key={day.day} className="p-4 border rounded-lg shadow-sm">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-semibold text-gray-800">
                  {t(`timing.day.${day.day}`)}
                </h3>
                <div className="flex items-center gap-2">
                  <span className="mr-2 text-gray-700">{t('timing.open')}</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={day.isOpen}
                      onChange={() => toggleDayOpen(dayIndex)}
                      className="sr-only peer"
                    />
                    <div className="h-6 bg-gray-200 rounded-full w-11 peer-focus:ring-green-300 peer-checked:bg-green-600"></div>
                  </label>
                </div>
              </div>

              {day.isOpen && (
                <>
                  {day.timeRanges.map((range, rangeIndex) => (
                    <div
                      key={rangeIndex}
                      className="flex items-end gap-4 mb-4 space-x-4"
                    >
                      <div className="flex-1 ">
                        <label className="block mb-1 text-gray-700">
                          {t('timing.openingTime')}
                        </label>
                        <input
                          type="time"
                          name="openingTime"
                          value={range.openingTime || ''}
                          onChange={(e) =>
                            handleChange(
                              dayIndex,
                              rangeIndex,
                              'openingTime',
                              e.target.value
                            )
                          }
                          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                      </div>
                      <div className="flex-1">
                        <label className="block mb-1 text-gray-700">
                          {t('timing.closingTime')}
                        </label>
                        <input
                          type="time"
                          name="closingTime"
                          value={range.closingTime || ''}
                          onChange={(e) =>
                            handleChange(
                              dayIndex,
                              rangeIndex,
                              'closingTime',
                              e.target.value
                            )
                          }
                          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                      </div>
                      {day.timeRanges.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeTimeRange(dayIndex, rangeIndex)}
                          className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
                        >
                          {t('timing.remove')}
                        </button>
                      )}
                    </div>
                  ))}

                  <div className="flex items-center gap-4">
                    <button
                      type="button"
                      onClick={() => addTimeRange(dayIndex)}
                      className="px-4 py-2 mb-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                    >
                      {t('timing.addTimeRange')}
                    </button>

                    {/* New "Copy to All" Button */}
                    <button
                      type="button"
                      onClick={() => handleCopyToAll(dayIndex)}
                      className="px-4 py-2 mb-2 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none"
                    >
                      {t('timing.copyTimingsToAll')}
                    </button>
                  </div>

                  {errors[day.day] && (
                    <p className="mt-2 text-sm text-red-500">
                      {errors[day.day]}
                    </p>
                  )}
                </>
              )}
            </div>
          ))}

          <div className="flex justify-between">
            <button
              type="button"
              onClick={onPrev}
              className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-gray-700"
            >
              {t('timing.previous')}
            </button>
            <button
              type="submit"
              className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-dark-green"
              onClick={handleSubmit}
            >
              {t('timing.saveAndNextButton')}
            </button>
          </div>
        </form>
      </div>
    )
  } catch (error) {
    console.error('Error rendering Timing component:', error)
    return <div>An error occurred while loading the Timing component.</div>
  }
}

export default Timing
