// src/components/StepsSection.js

import React from 'react';

const steps = [
  {
    id: 1,
    title: 'Signup for Free',
    description: 'Create your account through our website or mobile app with just your phone number.',
  },
  {
    id: 2,
    title: 'Add Profile Information',
    description: 'Complete your profile by providing your email and store name so that we can identify you.',
  },
  {
    id: 3,
    title: 'Add Address Information',
    description: 'Provide all address details of your business.',
  },
  {
    id: 4,
    title: 'Add ID & Bank Information',
    description: 'Add your ID & business-related details. Include necessary bank information for payments.',
  },
  {
    id: 5,
    title: 'List Products',
    description: 'Add products to your store through the seller center. Start selling as soon as your products go live after going through quality control.',
  },
];

const StepCard = ({ step }) => {
  return (
    <div className="flex flex-col items-center p-6 text-center transition-shadow duration-300 bg-gray-800 rounded-lg shadow-lg hover:shadow-2xl">
      <div className="flex items-center justify-center w-12 h-12 mb-4 text-white bg-green-500 rounded-full">
        <span className="font-bold">{step.id}</span>
      </div>
      <h3 className="mb-2 text-xl font-semibold text-green-500">{step.title}</h3>
      <p className="text-gray-300">{step.description}</p>
    </div>
  );
};

const StepsSection = () => {
  return (
    <section className="py-16 bg-gray-900">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="mb-12 text-4xl font-extrabold text-center text-white">
          Getting Started with Markaz
        </h2>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {steps.map((step) => (
            <StepCard key={step.id} step={step} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
