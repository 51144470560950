// src/pages/Auth/ForgotPasswordChangeScreen.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../redux/auth/authThunks';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FaArrowRight } from 'react-icons/fa'; // Import an arrow icon
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ForgotPasswordChangeScreen = () => {
  const { t, i18n } = useTranslation(); // Access the translation function and i18n instance
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const verifyId = queryParams.get('verifyId'); // Assuming verifyId is part of the query params

  const handleSubmit = () => {
    if (password.length < 6) {
      toast.error(t('auth.changePassword.errorPasswordLength'));
      return;
    }

    dispatch(changePassword({ email, password }))
      .unwrap()
      .then((response) => {
        if (response.status === 1) {
          toast.success(t('auth.changePassword.passwordChangedSuccess'));
          navigate('/signin');
        } else {
          toast.error(t('auth.changePassword.passwordChangeFailed'));
        }
      })
      .catch((err) => {
        const errorMsg = err || t('auth.changePassword.errorOccurred');
        toast.error(errorMsg);
        console.error('Error in password change:', err);
      });
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-50"
      dir={isArabic ? 'rtl' : 'ltr'} // Handle directionality
    >
      <div
        className={`w-full max-w-md p-8 bg-white rounded-lg shadow-lg ${
          isArabic ? 'font-cairo text-right' : 'font-rubik text-left'
        }`}
      >
        <h2 className="mb-6 text-2xl font-extrabold text-gray-900">
          {t('auth.changePassword.title')}
        </h2>

        {/* Password Field */}
        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            {t('auth.changePassword.passwordLabel')}
          </label>
          <input
            type="password"
            id="password"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
            placeholder={t('auth.changePassword.passwordPlaceholder')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isLoading ? 'bg-gray-500' : 'bg-black'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          >
            {isLoading ? t('auth.changePassword.submitting') : t('auth.changePassword.submitButton')}
          </button>
        </div>

        {/* Display Server-side Error */}
        {error && <p className="mt-4 text-red-500">{t('auth.changePassword.errorOccurred')}</p>}

        {/* Sign In Link */}
        <div className="flex items-center justify-center mt-6">
          <span className="mr-2 text-gray-600">{t('auth.changePassword.goToSignIn')}</span>
          <button
            onClick={() => navigate('/signin')}
            className="flex items-center text-green-500 hover:text-green-700 focus:outline-none"
          >
            {t('auth.changePassword.signIn')} <FaArrowRight className="ml-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordChangeScreen;
