import { createSlice } from '@reduxjs/toolkit'
import {
  checkEmailExistence,
  signInUser,
  signUpUser,
  verifyEmailForgotPassword,
  verifyOtp,
  changePassword,
} from './authThunks'

// Load the user from localStorage
let storedUser = null
try {
  const userString = localStorage.getItem('user')
  if (userString) {
    storedUser = JSON.parse(userString)
  }
} catch (error) {
  console.error('Error parsing user data from localStorage:', error)
  localStorage.removeItem('user') // Clear invalid user data
}

const initialState = {
  isLoading: false,
  emailExists: null,
  error: null,
  signInError: null,
  signUpError: null,
  otpError: null,
  forgotPasswordError: null,
  changePasswordError: null,
  isLoggedIn: !!storedUser, // Check if user is logged in based on localStorage
  user: storedUser, // The user object from localStorage
  otpVerified: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false
      state.user = null
      localStorage.removeItem('user') 
      localStorage.removeItem('token'); // Remove the token from localStorage// Remove the user from localStorage on logout
    },
    resetOtpVerification: (state) => {
      state.otpVerified = false
    },
    clearErrors: (state) => {
      state.error = null
      state.signInError = null
      state.signUpError = null
      state.otpError = null
      state.forgotPasswordError = null
      state.changePasswordError = null
    },
  },
  extraReducers: (builder) => {
    // Handle email existence check
    builder
      .addCase(checkEmailExistence.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.emailExists = null
      })
      .addCase(checkEmailExistence.fulfilled, (state, action) => {
        state.isLoading = false
        state.emailExists = action.payload?.status === 1 // Email exists if status is 1
      })
      .addCase(checkEmailExistence.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error checking email existence'
      })

    // Handle Sign In
    builder
      .addCase(signInUser.pending, (state) => {
        state.isLoading = true
        state.signInError = null // Clear previous errors
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user; // Store user data
        localStorage.setItem('user', JSON.stringify(action.payload.user)); // Save user in localStorage
      
        // Store the token
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.isLoading = false
        state.signInError = action.payload || 'Error signing in'
      })
    // Handle Sign Up
    builder
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true
        state.signUpError = null
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        const { status, data, message } = action.payload

        if (status === 1) {
          // Successful sign-up
          state.isLoading = false
          state.isLoggedIn = true
          state.user = data
          localStorage.setItem('user', JSON.stringify(data)) // Persist user in localStorage
        } else {
          // Failed sign-up, show error message
          state.isLoading = false
          state.signUpError = message || 'Error signing up'
        }
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.isLoading = false
        state.signUpError = action.payload || 'Error signing up'
      })

    // Handle Forgot Password Email Verification
    builder
      .addCase(verifyEmailForgotPassword.pending, (state) => {
        state.isLoading = true
        state.forgotPasswordError = null
      })
      .addCase(verifyEmailForgotPassword.fulfilled, (state) => {
        state.isLoading = false
        state.forgotPasswordError = null
      })
      .addCase(verifyEmailForgotPassword.rejected, (state, action) => {
        state.isLoading = false
        state.forgotPasswordError =
          action.payload || 'Error verifying email for password reset'
      })

    // Handle OTP Verification
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.isLoading = true
        state.otpError = null
        state.otpVerified = false
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.isLoading = false
        state.otpVerified = true
        state.otpError = null
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false
        state.otpVerified = false
        state.otpError = action.payload || 'Error verifying OTP'
      })

    // Handle Change Password
    builder
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true
        state.changePasswordError = null
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isLoading = false
        state.changePasswordError = null
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false
        state.changePasswordError = action.payload || 'Error changing password'
      })
  },
})

export const { logout, resetOtpVerification, clearErrors } = authSlice.actions
export default authSlice.reducer
