import { createSlice } from '@reduxjs/toolkit';
import {
  fetchStoreDetails,
  createOrUpdateStore,
  getBusinessCategories,
} from './storeThunks';

const initialState = {
  store: null,
  categories: [],
  storeInfo: {},         // Store Info data
  deliveryInfo: {},      // Delivery Info data
  storeTimings: [],      // Store timings
  locationInfo: {},      // Location info
  isLoading: false,
  isLoadingCategories: false,
  isSavingStore: false,
  error: null,
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    updateStoreInfo: (state, action) => {
      state.storeInfo = { ...state.storeInfo, ...action.payload };
    },
    updateDeliveryInfo: (state, action) => {
      state.deliveryInfo = { ...state.deliveryInfo, ...action.payload };
    },
    updateStoreTimings: (state, action) => {
      state.storeTimings = action.payload;
    },
    updateLocationInfo: (state, action) => {
      state.locationInfo = { ...state.locationInfo, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    // Fetch Store Details
    builder
      .addCase(fetchStoreDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStoreDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.store = action.payload;
      })
      .addCase(fetchStoreDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Error fetching store details';
      });

    // Fetch Business Categories
    builder
      .addCase(getBusinessCategories.pending, (state) => {
        state.isLoadingCategories = true;
      })
      .addCase(getBusinessCategories.fulfilled, (state, action) => {
        state.isLoadingCategories = false;
        state.categories = action.payload;
      })
      .addCase(getBusinessCategories.rejected, (state, action) => {
        state.isLoadingCategories = false;
        state.error = action.payload;
      });

    // Create/Update Store
    builder
      .addCase(createOrUpdateStore.pending, (state) => {
        state.isSavingStore = true;
      })
      .addCase(createOrUpdateStore.fulfilled, (state, action) => {
        state.isSavingStore = false;
        state.store = action.payload.data;
      })
      .addCase(createOrUpdateStore.rejected, (state, action) => {
        state.isSavingStore = false;
        state.error = action.payload;
      });
  },
});

export const {
  updateStoreInfo,
  updateDeliveryInfo,
  updateStoreTimings,
  updateLocationInfo,
} = storeSlice.actions;

export default storeSlice.reducer;
