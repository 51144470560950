// src/pages/Auth/SignIn.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInUser } from '../../redux/auth/authThunks';
import { clearErrors } from '../../redux/auth/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SignIn = () => {
  const { t, i18n } = useTranslation(); // Access the translation function and i18n instance
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, signInError } = useSelector((state) => state.auth);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    const savedPassword = localStorage.getItem('rememberedPassword');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setEmailError(!emailRegex.test(inputEmail) ? t('auth.signIn.pleaseEnterValidEmail') : '');
    dispatch(clearErrors()); // Clear errors when retrying
  };

  const handlePasswordChange = (e) => {
    const inputPassword = e.target.value;
    setPassword(inputPassword);
    setPasswordError(inputPassword.length === 0 ? t('auth.signIn.pleaseEnterPassword') : '');
    dispatch(clearErrors()); // Clear errors when retrying
  };

  const handleSubmit = () => {
    let isValid = true;

    if (!email) {
      setEmailError(t('auth.signIn.pleaseEnterEmail'));
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError(t('auth.signIn.pleaseEnterValidEmail'));
      isValid = false;
    }

    if (!password) {
      setPasswordError(t('auth.signIn.pleaseEnterPassword'));
      isValid = false;
    }

    if (isValid) {
      dispatch(signInUser({ email, password }))
        .unwrap()
        .then(() => {
          if (rememberMe) {
            localStorage.setItem('rememberedEmail', email);
            localStorage.setItem('rememberedPassword', password);
          } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
          }
          navigate('/');
        })
        .catch(() => {
          // Handle server-side errors if needed
        });
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-50"
      dir={isArabic ? 'rtl' : 'ltr'} // Handle directionality
    >
      <div
        className={`w-full max-w-md p-8 bg-white rounded-lg shadow-lg ${
          isArabic ? 'font-cairo text-right' : 'font-rubik text-left'
        }`}
      >
        <h2 className="mb-6 text-2xl font-extrabold text-gray-900">{t('auth.signIn.title')}</h2>

        {/* Email Field */}
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            {t('auth.signIn.email')}
          </label>
          <input
            type="email"
            id="email"
            className={`block w-full px-3 py-2 mt-1 border ${
              emailError ? 'border-red-500' : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            placeholder={t('auth.signIn.enterYourEmail')}
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && <p className="mt-2 text-sm text-red-500">{emailError}</p>}
        </div>

        {/* Password Field */}
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            {t('auth.signIn.password')}
          </label>
          <input
            type="password"
            id="password"
            className={`block w-full px-3 py-2 mt-1 border ${
              passwordError ? 'border-red-500' : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            placeholder={t('auth.signIn.enterYourPassword')}
            value={password}
            onChange={handlePasswordChange}
          />
          {passwordError && <p className="mt-2 text-sm text-red-500">{passwordError}</p>}
        </div>

        {/* Remember Me Checkbox */}
        <div className="flex items-center mb-4">
          <input
            id="remember-me"
            type="checkbox"
            className="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-500"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <label
            htmlFor="remember-me"
            className="block ml-2 mr-2 text-sm text-gray-900"
          >
            {t('auth.signIn.rememberMe')}
          </label>
        </div>

        {/* Sign In Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isLoading ? 'bg-gray-500' : 'bg-black'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          >
            {isLoading ? t('auth.signIn.signingIn') : t('auth.signIn.title')}
          </button>
        </div>

        {/* Forgot Password and Sign Up Buttons */}
        <div className="flex items-center justify-between mt-4">
          <button
            onClick={handleForgotPassword}
            className="text-sm text-green-500 hover:text-green-700 focus:outline-none"
          >
            {t('auth.signIn.forgotPassword')}
          </button>

          <button
            onClick={handleSignUp}
            className="text-sm text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            {t('auth.signIn.createAccount')}
          </button>
        </div>

        {/* Login Error Handling */}
        {signInError && (
          <p className="mt-4 text-sm text-red-500">{t('auth.signIn.signInError')}</p>
        )}
      </div>
    </div>
  );
};

export default SignIn;
