// src/components/Dashboard/OrdersSummary.js

import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const OrdersSummary = () => {
  const { t } = useTranslation('ordersSummary'); // Initialize with 'ordersSummary' namespace

  const summaryData = [
    {
      title: t('titles.todayOrders'),
      count: 0,
      color: 'bg-blue-500',
    },
    {
      title: t('titles.yesterdayOrders'),
      count: 0,
      color: 'bg-indigo-500',
    },
    {
      title: t('titles.totalOrders'),
      count: 0,
      color: 'bg-purple-500',
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-6 mt-10 sm:grid-cols-3">
      {summaryData.map((item, index) => (
        <div
          key={index}
          className={`p-6 text-center text-white rounded-lg shadow-sm hover:shadow-md ${item.color}`}
        >
          <h2 className="text-xl font-semibold">{item.title}</h2>
          <p className="mt-3 text-5xl font-bold">{item.count}</p>
        </div>
      ))}
    </div>
  );
};

export default OrdersSummary;
