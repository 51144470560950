// src/pages/Delivery.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOrUpdateStore,
  fetchStoreDetails,
} from '../../redux/store/storeThunks';
import CircularLoader from '../../components/Shared/CircularLoader';
import { getDeliveryOptionsAPI } from '../../services/api';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Delivery = ({ nextStep, onPrev }) => {
  const dispatch = useDispatch();
  const { isSavingStore, store } = useSelector((state) => state.store);

  // Initialize translation
  const { t, i18n } = useTranslation('delivery'); // Set 'delivery' as default namespace
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  // Retrieve vendorId from local storage
  const user = JSON.parse(localStorage.getItem('user'));
  const vendorId = user ? user._id : null;

  // Set initial form values
  const [formValues, setFormValues] = useState({
    deliveryType: '',
    deliveryDay: '',
    deliveryChargesKwd: 0,
    freeDeliveryAvailable: true, // Checked by default
    minOrderForFreeDelivery: 0,
    prepareHour: '',
    storeId: '',
  });

  const [deliveryOptions, setDeliveryOptions] = useState({
    isAllowVendorDelivery: false,
    isAllowSellerDelivery: false,
  });

  const [errors, setErrors] = useState({});

  // New state variable for toggle switch
  const [showMinOrderToggle, setShowMinOrderToggle] = useState(false);

  // Fetch available delivery options when the component mounts
  useEffect(() => {
    const fetchDeliveryOptions = async () => {
      try {
        const response = await getDeliveryOptionsAPI();
        if (response.data.status === 1) {
          setDeliveryOptions(response.data.data);
        }
      } catch (error) {
        console.error('Failed to fetch delivery options:', error);
      }
    };
    fetchDeliveryOptions();
  }, []);

  // Fetch store details if store data is missing
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchStoreDetails(vendorId));
    }
  }, [vendorId, dispatch]);

  // Populate form with existing store data if available
  useEffect(() => {
    if (store) {
      // Check if 'store' is an array (admin user) and select the first store
      const storeData = Array.isArray(store) ? store[0] : store;

      setFormValues({
        deliveryType: storeData.deliveryType || '',
        deliveryDay:
          storeData.deliveryDay !== undefined
            ? storeData.deliveryDay.toString()
            : '',
        deliveryChargesKwd: storeData.deliveryChargesKWD || 0,
        freeDeliveryAvailable:
          storeData.freeDeliveryAvailable !== undefined
            ? storeData.freeDeliveryAvailable
            : true,
        minOrderForFreeDelivery:
          storeData.minOrderForFreeDelivery !== undefined &&
          storeData.minOrderForFreeDelivery !== null
            ? parseFloat(storeData.minOrderForFreeDelivery)
            : 0,
        prepareHour: storeData.prepareHour ? `${storeData.prepareHour}H` : '',
        storeId: storeData._id || '',
      });
      // Initialize showMinOrderToggle based on existing data
      setShowMinOrderToggle(
        storeData.minOrderForFreeDelivery !== undefined &&
          storeData.minOrderForFreeDelivery > 0
      );
    }
  }, [store]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'deliveryChargesKwd') {
      const deliveryCharges = parseFloat(value);
      setFormValues((prevValues) => ({
        ...prevValues,
        deliveryChargesKwd: isNaN(deliveryCharges) ? 0 : deliveryCharges,
      }));

      // Uncheck freeDeliveryAvailable and show min order toggle when charges increase from zero
      if (deliveryCharges > 0) {
        setFormValues((prevValues) => ({
          ...prevValues,
          freeDeliveryAvailable: false,
        }));
        setShowMinOrderToggle(false);
      }
    } else if (name === 'freeDeliveryAvailable') {
      setFormValues((prevValues) => ({
        ...prevValues,
        freeDeliveryAvailable: checked,
        deliveryChargesKwd: checked ? 0 : prevValues.deliveryChargesKwd,
      }));
      // Hide Free Delivery on Minimum Order section
      if (checked) {
        setShowMinOrderToggle(false);
      }
    } else if (name === 'minOrderForFreeDelivery') {
      const minOrderValue = parseFloat(value);
      setFormValues((prevValues) => ({
        ...prevValues,
        minOrderForFreeDelivery: isNaN(minOrderValue) ? 0 : minOrderValue,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  // Handle amount increment and decrement for numeric fields
  const handleAmountChange = (field, increment) => {
    setFormValues((prevValues) => {
      const currentValue = parseFloat(prevValues[field]) || 0; // Ensure it's a number
      const newValue = Math.max(0, currentValue + increment);

      const newValues = { ...prevValues, [field]: newValue };

      if (field === 'deliveryChargesKwd') {
        // Uncheck freeDeliveryAvailable and hide min order toggle when charges increase from zero
        if (newValue > 0) {
          newValues.freeDeliveryAvailable = false;
          setShowMinOrderToggle(false);
        }
      }

      return newValues;
    });
  };

  // Validate form before submission
  const validateForm = () => {
    const { deliveryType, deliveryDay, prepareHour } = formValues;
    const errors = {};

    if (!deliveryType) {
      errors.deliveryType = t('errors.deliveryTypeRequired');
    } else if (deliveryType === 'VENDOR-DELIVERY' && !deliveryDay) {
      errors.deliveryDay = t('errors.deliveryDayRequired');
    } else if (deliveryType === 'OUR-DELIVERY' && !prepareHour) {
      errors.prepareHour = t('errors.prepareHourRequired');
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit form and proceed to the next step
  const handleSubmit = async () => {
    if (!vendorId) {
      console.error('Vendor ID is missing.');
      return;
    }

    if (validateForm()) {
      const prepareHourMapping = {
        '0H': 0,
        '1H': 1,
        '2H': 2,
        '3H': 3,
        '4H': 4,
        '6H': 6,
        '12H': 12,
        '24H': 24,
      };
      const numericPrepareHour = prepareHourMapping[formValues.prepareHour] || 0;

      // Prepare store data for submission
      const storeDataToSave = {
        ...store,
        vendorId,
        deliveryType: formValues.deliveryType,
        deliveryDay: formValues.deliveryDay
          ? parseInt(formValues.deliveryDay)
          : undefined,
        deliveryChargesKWD: formValues.deliveryChargesKwd,
        freeDeliveryAvailable: formValues.freeDeliveryAvailable,
        minOrderForFreeDelivery: showMinOrderToggle
          ? formValues.minOrderForFreeDelivery
          : 0,
        prepareHour: numericPrepareHour,
        storeId: formValues.storeId,
      };

      // Dispatch the update API call
      const result = await dispatch(createOrUpdateStore(storeDataToSave));

      // Check for successful response
      if (createOrUpdateStore.fulfilled.match(result)) {
        const updatedStore = result.payload;

        // Update formValues with the storeId if necessary
        if (!formValues.storeId && updatedStore.storeId) {
          setFormValues((prev) => ({
            ...prev,
            storeId: updatedStore.storeId,
          }));
        }

        nextStep(updatedStore); // Move to the next step
      } else {
        console.error('Failed to update store:', result.payload);
      }
    }
  };

  if (isSavingStore || !store) {
    return <CircularLoader />;
  }

  return (
    <div
      className="max-w-3xl min-h-screen p-6 mx-auto bg-white"
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      {/* Dynamically set direction */}
      <h2 className="mb-6 text-3xl font-semibold text-gray-900">
        {t('delivery.fulfillmentChannel')}
      </h2>

      <form className="space-y-8">
        {/* Fulfillment Channel */}
        <div className="space-y-4">
          <label className="block text-xl font-semibold text-gray-800">
            {t('delivery.fulfillmentChannel')}
          </label>
          {deliveryOptions.isAllowVendorDelivery && (
            <label className="flex items-center gap-2 space-x-3">
              <input
                type="radio"
                name="deliveryType"
                value="VENDOR-DELIVERY"
                checked={formValues.deliveryType === 'VENDOR-DELIVERY'}
                onChange={handleChange}
                className="text-green-600 form-radio"
              />
              <span className="text-lg text-gray-700">
                {t('delivery.vendorDelivery')}
              </span>
            </label>
          )}
          {deliveryOptions.isAllowSellerDelivery && (
            <label className="flex items-center gap-2 space-x-3">
              <input
                type="radio"
                name="deliveryType"
                value="OUR-DELIVERY"
                checked={formValues.deliveryType === 'OUR-DELIVERY'}
                onChange={handleChange}
                className="text-green-600 form-radio"
              />
              <span className="text-lg text-gray-700">
                {t('delivery.markazDelivery')}
              </span>
            </label>
          )}
          {errors.deliveryType && (
            <p className="text-sm text-red-500">{errors.deliveryType}</p>
          )}
        </div>

        {/* Vendor Delivery Options */}
        {formValues.deliveryType === 'VENDOR-DELIVERY' && (
          <>
            <div className="space-y-4">
              <label className="block text-xl font-semibold text-gray-800">
                {t('delivery.selectDeliveryDay')}
              </label>
              <select
                name="deliveryDay"
                value={formValues.deliveryDay}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
              >
                <option value="">{t('delivery.selectDeliveryDay')}</option>
                <option value="0">{t('delivery.sameDay')}</option>
                <option value="1">{t('delivery.oneDay')}</option>
                <option value="2">{t('delivery.twoDays')}</option>
                <option value="3">{t('delivery.threeDays')}</option>
              </select>
              {errors.deliveryDay && (
                <p className="text-sm text-red-500">{errors.deliveryDay}</p>
              )}
            </div>

            {/* Delivery Charges */}
            <div className="space-y-4">
              <label className="block text-xl font-semibold text-gray-800">
                {t('delivery.deliveryCharges')}
              </label>
              <div className="flex items-center gap-4 space-x-2">
                <button
                  type="button"
                  onClick={() =>
                    handleAmountChange('deliveryChargesKwd', -0.25)
                  }
                  className="px-4 py-2 text-white bg-gray-700 rounded-l-lg hover:bg-gray-800"
                >
                  -
                </button>
                <input
                  type="number"
                  name="deliveryChargesKwd"
                  value={formValues.deliveryChargesKwd.toFixed(3)}
                  onChange={handleChange}
                  className="w-24 p-3 text-center bg-gray-100 border border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  onClick={() =>
                    handleAmountChange('deliveryChargesKwd', 0.25)
                  }
                  className="px-4 py-2 text-white bg-gray-700 rounded-r-lg hover:bg-gray-800"
                >
                  +
                </button>
              </div>
            </div>

            {/* Free Delivery Checkbox */}
            <div className="space-y-4">
              <label className="block text-xl font-semibold text-gray-800">
                {t('delivery.freeDelivery')}
              </label>
              <div className="flex items-center gap-2 space-x-3">
                <input
                  type="checkbox"
                  name="freeDeliveryAvailable"
                  checked={formValues.freeDeliveryAvailable}
                  onChange={handleChange}
                  className="text-green-600 form-checkbox"
                />
                <span className="text-lg text-gray-700">
                  {t('delivery.enableFreeDelivery')}
                </span>
              </div>
            </div>

            {/* Free Delivery on Minimum Order */}
            {!formValues.freeDeliveryAvailable &&
              formValues.deliveryChargesKwd > 0 && (
                <div className="space-y-4">
                  <label className="block text-xl font-semibold text-gray-800">
                    {t('delivery.freeDeliveryOnMinOrder')}
                  </label>
                  {/* Toggle Button */}
                  <div className="flex items-center space-x-3">
                    <label className="relative inline-flex items-center cursor-pointer">
                      {/* Hidden checkbox, not tied to visual state */}
                      <input
                        type="checkbox"
                        checked={false} // Keeps the checkbox unchecked
                        onChange={() => {
                          // Toggle the input field visibility and styling
                          setShowMinOrderToggle(!showMinOrderToggle);
                        }}
                        className="sr-only peer"
                      />
                      {/* Use conditional class for the green color */}
                      <div
                        className={`h-6 w-11 rounded-full transition-colors ${
                          showMinOrderToggle ? 'bg-green-600' : 'bg-gray-200'
                        }`}
                      ></div>
                      <span className="ml-3 mr-4 text-lg text-gray-700">
                        {showMinOrderToggle ? t('delivery.enable') : t('delivery.disable')}
                      </span>
                    </label>
                  </div>
                  {/* Conditionally show/hide the input field */}
                  {showMinOrderToggle && (
                    <div className="flex items-center gap-4 mt-4 space-x-2">
                      <button
                        type="button"
                        onClick={() =>
                          handleAmountChange('minOrderForFreeDelivery', -0.25)
                        }
                        className="px-4 py-2 text-white bg-gray-700 rounded-l-lg hover:bg-gray-800"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        name="minOrderForFreeDelivery"
                        value={
                          typeof formValues.minOrderForFreeDelivery === 'number'
                            ? formValues.minOrderForFreeDelivery.toFixed(3)
                            : '0.000'
                        }
                        onChange={handleChange}
                        className="w-32 p-3 text-center bg-gray-100 border border-gray-300 rounded-md"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          handleAmountChange('minOrderForFreeDelivery', 0.25)
                        }
                        className="px-4 py-2 text-white bg-gray-700 rounded-r-lg hover:bg-gray-800"
                      >
                        +
                      </button>
                    </div>
                  )}
                </div>
              )}
          </>
        )}

        {/* Markaz Delivery Options */}
        {formValues.deliveryType === 'OUR-DELIVERY' && (
          <div className="space-y-4">
            <label className="block text-xl font-semibold text-gray-800">
              {t('delivery.preparationHour')}
            </label>
            <select
              name="prepareHour"
              value={formValues.prepareHour}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">{t('delivery.selectPreparationHour')}</option>
              <option value="0H">0 {t('delivery.preparationHour')}</option>
              <option value="1H">1 {t('delivery.preparationHour')}</option>
              <option value="2H">2 {t('delivery.preparationHour')}</option>
              <option value="3H">3 {t('delivery.preparationHour')}</option>
              <option value="4H">4 {t('delivery.preparationHour')}</option>
              <option value="6H">6 {t('delivery.preparationHour')}</option>
              <option value="12H">12 {t('delivery.preparationHour')}</option>
              <option value="24H">24 {t('delivery.preparationHour')}</option>
            </select>
            {errors.prepareHour && (
              <p className="text-sm text-red-500">{errors.prepareHour}</p>
            )}
          </div>
        )}

        <div className="flex justify-between">
          <button
            type="button"
            onClick={onPrev}
            className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-gray-700"
          >
            {t('delivery.previous')}
          </button>
          <button
            type="submit"
            className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-dark-green"
            onClick={handleSubmit}
          >
            {t('delivery.saveAndNextButton')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Delivery;
