// src/pages/Sidebar.js

import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  FaHome,
  FaBoxes,
  FaChartLine,
  FaUsers,
  FaStore,
  FaSignOutAlt,
  FaUser, // Imported FaUser for Vendor Detail
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/authSlice';
import { useTranslation } from 'react-i18next';
// import logo from '../../assets/Images/markazzlogo.png';

const Sidebar = ({ isSidebarOpen }) => {
  const { t, i18n } = useTranslation('sidebar');
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/signin');
  };

  // Load vendorId from local storage (this will remain constant for the session)
  const user = JSON.parse(localStorage.getItem('user'));
  const vendorId = user ? user._id : null;

  const menuItems = [
    { key: 'home', path: '/', icon: <FaHome /> },
    { key: 'products', path: '/products', icon: <FaBoxes /> },
    // { key: 'orders', path: '/orders', icon: <FaChartLine /> },
    // { key: 'customers', path: '/customers', icon: <FaUsers /> },
    { key: 'manageStore', path: '/manage-store', icon: <FaStore /> },
    // Conditionally add "My Detail Vendor" if vendorId exists
    vendorId && { key: 'myDetailVendor', path: `/vendor-detail/${vendorId}`, icon: <FaUser /> },
  ].filter(Boolean); // Filter out any falsey values (e.g., undefined if vendorId is null)

  return (
    <aside
      className={`fixed top-0 left-0 z-20 h-full bg-gray-900 transition-all duration-300 ${
        isSidebarOpen ? 'w-64' : 'w-16'
      }`}
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      {/* Sidebar Content */}
      <div className="flex flex-col h-full">
        {/* Sidebar Header */}
        <div
          className={`flex items-center h-16 px-4 bg-gray-800 ${
            isArabic ? 'flex-row-reverse' : ''
          }`}
        >
          {/* <img src={logo} alt="Logo" className="h-8" /> */}
          {isSidebarOpen && (
            <span
              className={`text-xl font-semibold text-[#06550f] ${
                isArabic ? 'mr-2' : 'ml-2'
              }`}
            >
              {t('siteTitle') || 'Markaz'} {/* Fallback to 'Markaz' if translation is missing */}
            </span>
          )}
        </div>
        {/* Navigation */}
        <nav className="flex-1 mt-2 overflow-hidden">
          <ul className="px-2 space-y-2">
            {menuItems.map((item, index) => (
              <li key={index} className="relative group">
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex gap-4 items-center ${
                      isArabic ? 'flex-row-reverse' : ''
                    } px-4 py-2 rounded-md text-gray-200 hover:bg-gray-800 ${
                      isActive ? 'bg-gray-800' : ''
                    }`
                  }
                >
                  <span className="text-lg">{item.icon}</span>
                  {isSidebarOpen && (
                    <span className={`${isArabic ? 'mr-3' : 'ml-3'}`}>
                      {t('menuItems.' + item.key) || item.key}
                    </span>
                  )}
                </NavLink>
                {/* Tooltip */}
                {!isSidebarOpen && (
                  <span
                    className={`absolute hidden px-2 py-1 text-sm text-white bg-black rounded whitespace-nowrap ${
                      isArabic ? 'right-full mr-2' : 'left-full ml-2'
                    } group-hover:block`}
                  >
                    {t('menuItems.' + item.key) || item.key}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </nav>
        {/* Logout Button */}
        <div className="p-4">
          <button
            onClick={handleLogout}
            className={`flex gap-2 items-center ${
              isArabic ? 'flex-row-reverse' : ''
            } w-full px-4 py-2 text-sm font-medium text-gray-200 bg-gray-800 rounded-md hover:bg-gray-700`}
          >
            <FaSignOutAlt className="text-lg" />
            {isSidebarOpen && (
              <span className={`${isArabic ? 'mr-3' : 'ml-3'}`}>
                {t('buttons.logout') || 'Logout'}
              </span>
            )}
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
