// src/redux/product/productSlice.js

import { createSlice } from '@reduxjs/toolkit'
import {
  fetchProductCategories,
  fetchOccasions,
  createProduct,
  fetchProductDetails, // Newly imported
  updateProductDetails,
  fetchVendorProducts,
  deleteProduct,
  fetchColors,
} from './productThunks'

const initialState = {
  product: null,
  products: [], // Add this line to define the 'products' property
  categories: [],
  occasions: [],
  colors: [],
  hasNextPage: false, // Indicates if there's a next page
  nextPage: null, // The number of the next page
  isLoading: false, // For initial loading
  isLoadingMore: false, // For pagination loading
  error: null,
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductInfo: (state, action) => {
      state.product = { ...state.product, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    // Fetch product categories
    builder
      .addCase(fetchProductCategories.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchProductCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.categories = action.payload
      })
      .addCase(fetchProductCategories.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error fetching categories'
      })

    // Fetch occasions
    builder
      .addCase(fetchOccasions.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchOccasions.fulfilled, (state, action) => {
        state.isLoading = false
        state.occasions = action.payload
      })
      .addCase(fetchOccasions.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error fetching occasions'
      })

    // Create product
    builder
      .addCase(createProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error creating product'
      })

    // Fetch product details
    builder
      .addCase(fetchProductDetails.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error fetching product details'
      })

    // Update product details
    builder
      .addCase(updateProductDetails.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateProductDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload
        // Update the product in the products list if it exists
        const index = state.products.findIndex(
          (p) => p._id === action.payload._id
        )
        if (index !== -1) {
          state.products[index] = action.payload
        }
      })
      .addCase(updateProductDetails.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error updating product'
      })

    builder
      .addCase(fetchVendorProducts.pending, (state, action) => {
        state.isLoadingMore = action.meta.arg.page > 1 // Set isLoadingMore only for additional pages
        state.isLoading = action.meta.arg.page === 1 // Set isLoading for the initial load
        state.error = null
      })
      .addCase(fetchVendorProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoadingMore = false
        const { data, hasNextPage, nextPage, status } = action.payload

        if (status !== 1) {
          state.error = 'Failed to fetch products.'
          return
        }

        if (action.meta.arg.page === 1) {
          // Replace the products list on the first page
          state.products = data
        } else {
          // Append only unique products to prevent duplication
          const existingProductIds = new Set(state.products.map((p) => p._id))
          const newUniqueProducts = data.filter(
            (p) => !existingProductIds.has(p._id)
          )
          state.products = [...state.products, ...newUniqueProducts]
        }

        state.hasNextPage = hasNextPage
        state.nextPage = nextPage
      })
      .addCase(fetchVendorProducts.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error fetching products'
      })

    // Delete product
    builder
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false
        // Remove the deleted product from the state.products array
        state.products = state.products.filter(
          (product) => product._id !== action.meta.arg
        )
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error deleting product'
      })

    // **Handle fetchColors thunk**
    builder
      .addCase(fetchColors.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchColors.fulfilled, (state, action) => {
        state.isLoading = false
        state.colors = action.payload // Assign fetched colors
      })
      .addCase(fetchColors.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload || 'Error fetching colors'
      })
  },
})

export const { setProductInfo  } = productSlice.actions
export default productSlice.reducer
