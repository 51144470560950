// redux/rootReducer.js
import { combineReducers } from 'redux'
import authReducer from './auth/authSlice'
import storeReducer from './store/storeSlice'
import orderReducer from './order/orderSlice';
import productReducer from './product/productSlice';
import vendorReducer from './vendor/vendorSlice';
const rootReducer = combineReducers({
  auth: authReducer,
  store: storeReducer, 
  order: orderReducer,
  product: productReducer,
  vendor: vendorReducer,
})

export default rootReducer
