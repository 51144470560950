// src/components/FeatureSection.js

import React from 'react';
import {
  FaUsers,
  FaRegHandshake,
  FaShippingFast,
  FaWallet,
  FaBullhorn,
  FaLifeRing,
} from 'react-icons/fa'; // Importing icons from react-icons

const features = [
  {
    key: 'reach',
    icon: <FaUsers className="w-8 h-8 mb-4 text-green-500" />,
    title: 'Reach',
    description:
      'Access millions of customers on Markaz, Pakistan\'s premier shopping destination.',
  },
  {
    key: 'freeRegistration',
    icon: <FaRegHandshake className="w-8 h-8 mb-4 text-green-500" />,
    title: 'Free Registration',
    description: 'Register your account and list items for sale at no cost.',
  },
  {
    key: 'reliableShipping',
    icon: <FaShippingFast className="w-8 h-8 mb-4 text-green-500" />,
    title: 'Reliable Shipping',
    description:
      'Enjoy fast, reliable, and hassle-free delivery through Markaz\'s logistic network.',
  },
  {
    key: 'timelyPayments',
    icon: <FaWallet className="w-8 h-8 mb-4 text-green-500" />,
    title: 'Timely Payments',
    description:
      'Receive your funds safely deposited directly to your bank account weekly.',
  },
  {
    key: 'marketingTools',
    icon: <FaBullhorn className="w-8 h-8 mb-4 text-green-500" />,
    title: 'Marketing Tools',
    description:
      'Grow your business with our comprehensive advertising and marketing tools.',
  },
  {
    key: 'supportTraining',
    icon: <FaLifeRing className="w-8 h-8 mb-4 text-green-500" />,
    title: 'Support & Training',
    description:
      'Access free eCommerce training and get support from our dedicated seller team.',
  },
];

const FeatureSection = () => {
  return (
    <section className="py-16 bg-gray-900">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="mb-12 text-4xl font-extrabold text-center text-white">
          Why Sell on Markaz?
        </h2>
        <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature) => (
            <div
              key={feature.key}
              className="flex flex-col items-center p-6 text-center transition-shadow duration-300 bg-gray-800 rounded-lg shadow-lg hover:shadow-2xl"
            >
              {feature.icon}
              <h3 className="mb-2 text-2xl font-semibold text-green-500">
                {feature.title}
              </h3>
              <p className="text-gray-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;

