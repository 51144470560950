// src/screens/MainScreen.js

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendorProducts, deleteProduct } from '../../redux/product/productThunks';
import { useNavigate } from 'react-router-dom';
import StoreInfo from '../Dashboard/StoreInfo';
import OrdersSummary from '../Dashboard/OrdersSummary';
import ProductsList from '../Dashboard/ProductsList';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const MainScreen = ({ storeData }) => {
  const { t } = useTranslation('mainScreen'); // Initialize with 'mainScreen' namespace
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products, isLoading, error, totalPages } = useSelector((state) => state.product);

  const [vendorId, setVendorId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10; // Number of products per page
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInput, setSearchInput] = useState(''); // For the search input field

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  // Get vendorId from local storage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      setVendorId(userData._id);
    }
  }, []);

  // Fetch products when vendorId, currentPage, limit, or searchQuery changes
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchVendorProducts({ page: currentPage, limit, vendorId, search: searchQuery }));
    }
  }, [dispatch, currentPage, limit, searchQuery, vendorId]);

  // Debounced search to reduce API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchQuery(searchInput.trim());
      setCurrentPage(1); // Reset to first page on new search
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  const handleEditProduct = (productId) => {
    navigate(`/manage-product/${productId}`);
  };

  const openDeleteModal = (productId) => {
    setProductToDelete(productId);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setProductToDelete(null);
    setIsModalOpen(false);
  };

  const confirmDeleteProduct = () => {
    if (productToDelete) {
      dispatch(deleteProduct(productToDelete))
        .unwrap()
        .then(() => {
          // After deletion, refetch the current page to get updated products
          dispatch(fetchVendorProducts({ page: currentPage, limit, vendorId, search: searchQuery }));
          closeDeleteModal();
        })
        .catch((error) => {
          alert(t('errors.deleteProduct', { error })); // Use translated error message
          closeDeleteModal();
        });
    }
  };

  // Combine storeData.products with fetchedProducts using useMemo to prevent re-creation on every render
  const combinedProducts = useMemo(() => {
    return Array.isArray(storeData?.products)
      ? [...storeData.products, ...products]
      : [...products];
  }, [storeData?.products, products]);

  // Remove duplicate products based on _id
  const uniqueProducts = useMemo(() => {
    const seen = new Set();
    return combinedProducts.filter((product) => {
      if (seen.has(product._id)) {
        return false;
      }
      seen.add(product._id);
      return true;
    });
  }, [combinedProducts]);

  // Determine if there are no products after search
  const noProductsFound = !isLoading && uniqueProducts && uniqueProducts.length === 0 && searchQuery !== '';

  // Handle Load More button click (Pagination)
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Handle Clear Search
  const clearSearch = () => {
    setSearchInput('');
    setSearchQuery('');
    setCurrentPage(1);
  };

  // Handle loading state for additional pages
  const isLoadingMore = isLoading && currentPage > 1;

  // Handle error state for initial and subsequent loads
  const subsequentError = error && currentPage > 1;

  // Handle deletion edge case: If a deletion leaves the current page empty, go back a page if possible
  useEffect(() => {
    if (!isLoading && uniqueProducts.length === 0 && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [uniqueProducts, isLoading, currentPage]);

  return (
    <div className="min-h-screen p-8 bg-gray-50">
      {/* Store Information */}
      <StoreInfo storeData={storeData} />

      {/* Orders Summary */}
      <OrdersSummary />

      {/* View All Orders Button */}
      <div className="mt-8">
        <button
          className="w-full py-4 text-lg font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
          onClick={() => navigate('/orders')}
        >
          {t('buttons.viewAllOrders')}
        </button>
      </div>

      {/* Products List */}
      <ProductsList
        products={uniqueProducts}
        isLoading={isLoading}
        error={error}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        clearSearch={clearSearch}
        noProductsFound={noProductsFound}
        handleEditProduct={handleEditProduct}
        openDeleteModal={openDeleteModal}
        isModalOpen={isModalOpen}
        confirmDeleteProduct={confirmDeleteProduct}
        closeDeleteModal={closeDeleteModal}
        isLoadingMore={isLoadingMore}
        subsequentError={subsequentError}
        totalPages={totalPages}
        currentPage={currentPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        productToDelete={productToDelete}
      />
    </div>
  );
};

export default MainScreen;