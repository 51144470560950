// src/pages/ManageStore.js
import React, { useState, useEffect } from 'react';
import StoreInfo from './StoreInfo';
import Delivery from './Delivery';
import Timing from './Timing';
import Location from './Location';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchStoreDetails } from '../../redux/store/storeThunks';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const ManageStore = () => {
  const { t } = useTranslation('manageStore'); // Set 'manageStore' as namespace
  const [activeTab, setActiveTab] = useState(0);
  const [storeData, setStoreData] = useState({});
  const [completedSteps, setCompletedSteps] = useState([false, false, false, false]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem('user'));
  const vendorId = user ? user._id : null;

  // Function to determine which steps are completed based on storeData
  const determineCompletedSteps = (data) => {
    const stepsCompleted = [false, false, false, false];

    // Step 0: Store Info
    if (
      data.nameBusiness &&
      data.nameBusinessAr &&
      data.mobileNo &&
      data.businiessCategory &&
      data.storeType
    ) {
      stepsCompleted[0] = true;
    }

    // Step 1: Delivery
    if (
      data.deliveryType &&
      ((data.deliveryType === 'VENDOR-DELIVERY' && data.deliveryDay !== undefined) ||
        (data.deliveryType === 'OUR-DELIVERY' && data.prepareHour !== undefined))
    ) {
      stepsCompleted[1] = true;
    }

    // Step 2: Timing
    if (data.shopTimings && data.shopTimings.length > 0) {
      stepsCompleted[2] = true;
    }

    // Step 3: Location
    if (
      data.location &&
      data.location.lat &&
      data.location.lng &&
      data.location.address &&
      data.location.placeId
    ) {
      stepsCompleted[3] = true;
    }

    return stepsCompleted;
  };

  // Fetch store details when the component loads
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchStoreDetails(vendorId))
        .unwrap()
        .then((data) => {
          if (data && data._id) {
            setStoreData(data); // Set the fetched store data
            const stepsCompleted = determineCompletedSteps(data);
            setCompletedSteps(stepsCompleted);
          }
        })
        .catch((error) => {
          console.error('Error fetching store details:', error);
        });
    }
  }, [vendorId, dispatch]);

  // Update completedSteps whenever storeData changes
  useEffect(() => {
    const stepsCompleted = determineCompletedSteps(storeData);
    setCompletedSteps(stepsCompleted);
  }, [storeData]);

  // Move to the next tab
  const nextStep = (updatedData) => {
    setStoreData((prevData) => ({ ...prevData, ...updatedData })); // Merge new data with existing store data

    setCompletedSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      newSteps[activeTab] = true; // Mark current step as completed
      return newSteps;
    });

    setActiveTab((prevTab) => prevTab + 1);
  };

  const handlePrevStep = () => {
    setActiveTab((prev) => Math.max(prev - 1, 0));
  };

  // Move to the previous tab
  const prevStep = () => {
    setActiveTab((prevTab) => Math.max(prevTab - 1, 0));
  };

  // Check if a tab is accessible
  const isTabAccessible = (tabIndex) => {
    if (tabIndex === 0) return true; // First tab is always accessible
    // All previous steps must be completed
    return completedSteps.slice(0, tabIndex).every((step) => step);
  };

  // Move to a specific tab if accessible
  const handleTabChange = (tabIndex) => {
    if (isTabAccessible(tabIndex)) {
      setActiveTab(tabIndex);
    } else {
      alert(t('alerts.completePreviousSteps')); // Use translated alert
    }
  };

  const finishSetup = () => {
    navigate('/');
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <StoreInfo nextStep={nextStep} storeData={storeData} />;
      case 1:
        return (
          <Delivery
            nextStep={nextStep}
            prevStep={prevStep}
            onPrev={handlePrevStep}
            storeData={storeData}
            updateStoreData={setStoreData}
          />
        );
      case 2:
        return (
          <Timing
            nextStep={nextStep}
            prevStep={prevStep}
            onPrev={handlePrevStep}
            storeData={storeData}
          />
        );
      case 3:
        return (
          <Location
            storeData={storeData}
            nextStep={finishSetup}
            onPrev={handlePrevStep}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };

  const tabs = [
    t('tabs.storeInfo'),
    t('tabs.delivery'),
    t('tabs.timing'),
    t('tabs.location')
  ];

  return (
    <div className="container px-4 py-4 mx-auto md:px-10">
      {/* Tab Navigation */}
      <div className="flex justify-between border-b border-gray-300">
        {tabs.map((tabLabel, index) => {
          const accessible = isTabAccessible(index);
          return (
            <button
              key={index}
              className={`flex-1 py-3 text-center text-lg font-medium ${
                activeTab === index
                  ? 'border-b-4 border-green-500 text-green-600 font-bold'
                  : accessible
                  ? 'text-gray-500 hover:text-green-500'
                  : 'text-gray-300 cursor-not-allowed'
              }`}
              onClick={() => handleTabChange(index)}
              disabled={!accessible}
            >
              {tabLabel}
            </button>
          );
        })}
      </div>

      {/* Render Tab Content */}
      <div className="p-8 bg-white rounded-lg shadow-lg">{renderTabContent()}</div>
    </div>
  );
};

export default ManageStore;