// src/components/Product/ProductInfoPage.js

import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductCategories,
  fetchOccasions,
  createProduct,
  fetchProductDetails,
  updateProductDetails,
} from '../../redux/product/productThunks';
import { fetchStoreDetails } from '../../redux/store/storeThunks';
import CircularLoader from '../Shared/CircularLoader';
import { FaTimes, FaArrowLeft } from 'react-icons/fa';
import { FiChevronDown, FiChevronRight, FiSearch } from 'react-icons/fi';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import SimpleRichTextEditor from './SimpleRichTextEditor';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StoreSelectionModal from '../Store/StoreSelectionModal';

const ProductInfoPage = React.memo(
  ({
    onNext,
    updateProductData,
    productData = {},
    productId,
    onProductIdUpdate,
  }) => {
    const { t, i18n } = useTranslation('productInfoPage');
    const currentLanguage = i18n.language || 'en';
    const isArabic = currentLanguage === 'ar';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [hasFetched, setHasFetched] = useState(false);
    const [hasProductIdUpdated, setHasProductIdUpdated] = useState(false);

    const [categorySearch, setCategorySearch] = useState('');

    // Ref to hold the latest updateProductData
    const updateProductDataRef = useRef(updateProductData);

    useEffect(() => {
      updateProductDataRef.current = updateProductData;
    }, [updateProductData]);

    // Debounced update function using useMemo to memoize the debounced function
    const debouncedUpdateProductData = useMemo(
      () =>
        debounce((data) => {
          updateProductDataRef.current(data);
        }, 300),
      []
    );

    useEffect(() => {
      return () => {
        debouncedUpdateProductData.cancel();
      };
    }, [debouncedUpdateProductData]);

    // Handler for RichTextEditor content changes
    const handleEditorChange = useCallback(
      (language, content) => {
        const updatedData =
          language === 'en'
            ? { description: content }
            : { descriptionAr: content };
        updateProductData(updatedData); // Immediate update for descriptions
      },
      [updateProductData]
    );

    const {
      categories,
      occasions,
      isLoading: isProductLoading,
      error: productError,
      product,
    } = useSelector((state) => state.product);
    const [isAdmin, setIsAdmin] = useState(false);
    const {
      store,
      isLoading: isStoreLoading,
      error: storeError,
    } = useSelector((state) => state.store) || {};


    const [showStoreModal, setShowStoreModal] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);

    // Determine if the user is admin based on their email
    useEffect(() => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const userData = JSON.parse(storedUser);
        if (userData.email === 'm.razaimtiaz@gmail.com') { // Replace with your admin email
          setIsAdmin(true);
          dispatch(fetchStoreDetails(userData._id));
        } else {
          setIsAdmin(false);
          setSelectedStore(store || null); // Set to user's store or null
        }
      }
    }, [dispatch, store]);

    

    // Show modal when admin and adding a new product without a selected store
    useEffect(() => {
      if (isAdmin && !productId && !selectedStore) {
        setShowStoreModal(true);
      }
    }, [isAdmin, productId, selectedStore]);

    // Handle store selection from modal
    const handleStoreSelect = (store) => {
      console.log('Store selected:', store);
      setSelectedStore(store);
      setShowStoreModal(false);
      updateProductData({ storeId: store._id });
      console.log("store id", store._id);
    };

    // Fetch product details when productId changes and selectedStore is available
    useEffect(() => {
      if (productId && selectedStore && !hasFetched) {
        dispatch(fetchProductDetails(productId));
        setHasFetched(true);
      }
    }, [dispatch, productId, selectedStore, hasFetched]);

    // Update product data when product details are fetched
    useEffect(() => {
      if (product && productId) {
        const materialDetails = Array.isArray(product.materialDetails)
          ? product.materialDetails
          : [];
        const materialDetailsAr = Array.isArray(product.materialDetailsAr)
          ? product.materialDetailsAr
          : [];

        updateProductData({
          productName: product.productName || '',
          productNameAr: product.productNameAr || '',
          description: product.description || '',
          descriptionAr: product.descriptionAr || '',
          materialDetails,
          materialDetailsAr,
          brandEn: product.brandEn || '',
          brandAr: product.brandAr || '',
          categoryId: product.productCategoryId || '',
          categoryNameEn: product.categoryNameEn || '',
          categoryNameAr: product.categoryNameAr || '',
          occasionIds: Array.isArray(product.occasions) ? product.occasions : [],
          barcode: product.barcode || '',
          storeId: product.storeId || (store._id),
        });

        if (onProductIdUpdate && !hasProductIdUpdated) {
          onProductIdUpdate(product._id || product.id);
          setHasProductIdUpdated(true);
        }
      }
    }, [
      product,
      productId,
      updateProductData,
      onProductIdUpdate,
      hasProductIdUpdated,
      store,
    ]);

    // Fetch categories and occasions once on mount
    useEffect(() => {
      dispatch(fetchProductCategories());
      dispatch(fetchOccasions());
    }, [dispatch]);

    // Category Dropdown State
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const categoryRef = useRef();

    // Close category dropdown when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          categoryRef.current &&
          !categoryRef.current.contains(event.target)
        ) {
          setIsCategoryOpen(false);
          setExpandedCategories([]);
          setCategorySearch('');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    // Handle input changes
    const handleChange = useCallback(
      (e) => {
        const { name, value } = e.target;
        if (name !== 'occasionIds') {
          updateProductData({ [name]: value });
        }
      },
      [updateProductData]
    );

    // Handle dynamic description changes
    const handleDynamicChange = useCallback(
      (e, index, type) => {
        const { name, value } = e.target;
        const targetArray = type === 'en' ? 'materialDetails' : 'materialDetailsAr';
        const updatedDescriptions = (productData[targetArray] || []).map(
          (desc, idx) =>
            idx === index ? { ...desc, [name]: value } : desc
        );
        updateProductData({ [targetArray]: updatedDescriptions });
      },
      [productData, updateProductData]
    );

    // Add a new description
    const addDescription = useCallback(
      (type) => {
        const targetArray = type === 'en' ? 'materialDetails' : 'materialDetailsAr';
        const newDescription = { key: '', value: '' };
        updateProductData({
          [targetArray]: [
            ...(productData[targetArray] || []),
            newDescription,
          ],
        });
      },
      [productData, updateProductData]
    );

    // Remove a description
    const removeDescription = useCallback(
      (type, index) => {
        const targetArray = type === 'en' ? 'materialDetails' : 'materialDetailsAr';
        const updatedDescriptions = [...(productData[targetArray] || [])];
        updatedDescriptions.splice(index, 1);
        updateProductData({ [targetArray]: updatedDescriptions });
      },
      [productData, updateProductData]
    );

    // Handle form submission
    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();

        if (isAdmin && !selectedStore) {
          alert(t('alerts.storeSelectionRequired'));
          return;
        }

        const isValid =
          productData.productName &&
          productData.productNameAr &&
          productData.categoryId;

        if (isValid) {
          const sanitizedDescription = productData.description
            ? productData.description.trim()
            : '';
          const sanitizedDescriptionAr = productData.descriptionAr
            ? productData.descriptionAr.trim()
            : '';

          const productPayload = {
            productName: productData.productName.trim(),
            productNameAr: productData.productNameAr.trim(),
            description: sanitizedDescription,
            descriptionAr: sanitizedDescriptionAr,
            materialDetails: productData.materialDetails || [],
            materialDetailsAr: productData.materialDetailsAr || [],
            brandEn: (productData.brandEn || '').trim(),
            brandAr: (productData.brandAr || '').trim(),
            productCategoryId: productData.categoryId,
            categoryNameEn: productData.categoryNameEn,
            categoryNameAr: productData.categoryNameAr,
            occasions: productData.occasionIds || [],
            vendorId: isAdmin ? (selectedStore && selectedStore.vendorId) : (store.vendorId),
            storeId: isAdmin ? (selectedStore && selectedStore._id) : (store._id),
            barcode: (productData.barcode || '').trim(),
          };

          console.log('Sending product data to API:', productPayload);

          if (productId) {
            try {
              const actionResult = await dispatch(
                updateProductDetails({ productId, data: productPayload })
              ).unwrap();
              console.log('API Response:', actionResult);
              onNext();
            } catch (error) {
              console.error('Error updating product:', error);
              alert(t('alerts.errorUpdatingProduct', { error }));
            }
          } else {
            try {
              const actionResult = await dispatch(
                createProduct(productPayload)
              ).unwrap();
              console.log('API Response:', actionResult);
              if (actionResult && (actionResult._id || actionResult.id)) {
                const newProductId = actionResult._id || actionResult.id;
                if (onProductIdUpdate) {
                  onProductIdUpdate(newProductId);
                }
                onNext();
              } else {
                console.error('API did not return _id or id:', actionResult);
                alert(t('alerts.productCreatedNoId'));
              }
            } catch (error) {
              console.error('Error creating product:', error);
              alert(t('alerts.errorCreatingProduct', { error }));
            }
          }
        } else {
          alert(t('alerts.fillRequiredFields'));
        }
      },
      [
        isAdmin,
        selectedStore,
        store,
        productData,
        productId,
        dispatch,
        onProductIdUpdate,
        onNext,
        t
      ]
    );

    // Toggle category expansion
    const toggleCategory = useCallback((categoryId) => {
      setExpandedCategories((prev) =>
        prev.includes(categoryId)
          ? prev.filter((id) => id !== categoryId)
          : [...prev, categoryId]
      );
    }, []);

    // Build category tree
    const categoryTree = useMemo(() => {
      const buildTree = (categoriesList, parentId = null) => {
        return categoriesList
          .filter((cat) => {
            if (parentId === null) {
              return !cat.parentId || cat.parentId.length === 0;
            } else {
              return cat.parentId && cat.parentId.includes(parentId);
            }
          })
          .map((cat) => ({
            ...cat,
            children: buildTree(categoriesList, cat._id),
          }));
      };

      return buildTree(categories);
    }, [categories]);

    // Filter category tree based on search
    const filteredCategoryTree = useMemo(() => {
      const searchTerm = categorySearch.trim().toLowerCase();
      if (!searchTerm) return categoryTree;

      const filterTree = (nodes) => {
        return nodes
          .map((node) => {
            const match =
              (node.nameEn &&
                node.nameEn.toLowerCase().includes(searchTerm)) ||
              (node.nameAr &&
                node.nameAr.toLowerCase().includes(searchTerm));

            const children = filterTree(node.children || []);

            if (match || children.length > 0) {
              return { ...node, children };
            }

            return null;
          })
          .filter((node) => node !== null);
      };

      return filterTree(categoryTree);
    }, [categoryTree, categorySearch]);

    // Render category options recursively
    const renderCategoryOptions = useCallback(
      (nodes, level = 0) => {
        if (!nodes || nodes.length === 0) return null;

        return (
          <ul
            className={`py-1 ${
              level > 0 ? 'pl-4 border-l border-gray-200' : ''
            }`}
          >
            {nodes.map((node) => {
              const hasChildren = node.children && node.children.length > 0;
              const isOpen = expandedCategories.includes(node._id);

              return (
                <li key={node._id} className="relative">
                  <div
                    className={`flex items-center justify-between cursor-pointer ${
                      hasChildren ? 'hover:bg-gray-100' : ''
                    }`}
                    onClick={() => {
                      if (hasChildren) {
                        toggleCategory(node._id);
                      } else {
                        updateProductData({
                          categoryId: node._id,
                          categoryNameEn: node.nameEn,
                          categoryNameAr: node.nameAr,
                        });
                        setIsCategoryOpen(false);
                        setExpandedCategories([]);
                        setCategorySearch('');
                      }
                    }}
                    aria-expanded={hasChildren ? isOpen : undefined}
                    role={hasChildren ? 'button' : 'menuitem'}
                  >
                    <span className="px-4 py-2 text-sm text-gray-700">
                      {node.nameEn} / {node.nameAr}
                    </span>
                    {hasChildren && (
                      <span className="pr-4">
                        {isOpen ? <FiChevronDown /> : <FiChevronRight />}
                      </span>
                    )}
                  </div>
                  {hasChildren && isOpen && renderCategoryOptions(node.children, level + 1)}
                </li>
              );
            })}
          </ul>
        );
      },
      [expandedCategories, toggleCategory, updateProductData]
    );

    // Occasion Dropdown State
    const [isOccasionDropdownOpen, setIsOccasionDropdownOpen] = useState(false);
    const occasionRef = useRef();

    // Close occasion dropdown when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          occasionRef.current &&
          !occasionRef.current.contains(event.target)
        ) {
          setIsOccasionDropdownOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    // Toggle occasion selection
    const toggleOccasion = useCallback(
      (occasionId) => {
        const selected = productData.occasionIds || [];
        if (selected.includes(occasionId)) {
          const updated = selected.filter((id) => id !== occasionId);
          updateProductData({ occasionIds: updated });
        } else {
          if (selected.length < 3) {
            updateProductData({ occasionIds: [...selected, occasionId] });
          } else {
            alert(t('alerts.maxOccasions'));
          }
        }
      },
      [productData.occasionIds, updateProductData, t]
    );

    // Get selected occasion names
    const selectedOccasionNames = useMemo(
      () =>
        occasions
          .filter((occasion) =>
            (productData.occasionIds || []).includes(occasion._id)
          )
          .map((occasion) => `${occasion.nameEn} / ${occasion.nameAr}`),
      [occasions, productData.occasionIds]
    );

    // Handle loading and error states with adjusted conditions
    if (isProductLoading || (isStoreLoading && !isAdmin)) {
      return <CircularLoader />;
    }

    if (productError || storeError) {
      return (
        <div className="p-6 text-red-500">
          {productError && <p>{t('alerts.errorUpdatingProduct', { error: productError })}</p>}
          {storeError && <p>{t('alerts.errorFetchingStoreData', { error: storeError })}</p>}
        </div>
      );
    }

    return (
      <div className="font-rubik" dir={isArabic ? 'rtl' : 'ltr'}>
        {isAdmin && showStoreModal && (
          <StoreSelectionModal
            stores={store || []} // Ensure it's always an array
            onSelect={handleStoreSelect}
            onClose={() => {
              alert(t('alerts.storeSelectionRequired'));
              navigate('/products');
            }}
          />
        )}

        <button
          className="flex items-center mb-4 text-gray-800 transition-colors duration-200 hover:text-gray-600 focus:outline-none"
          onClick={() => navigate('/')}
        >
          <FaArrowLeft className="mr-2" /> {t('buttons.back')}
        </button>
        <div className="mb-6">
          <h1 className="text-3xl font-extrabold text-dark-green">
            {t('headings.manager')}
          </h1>
        </div>
        <h2 className="mb-6 text-2xl font-semibold text-dark-green">
          {t('headings.productInformation')}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Product Name */}
          <div className="flex flex-col gap-4 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex-1">
              <label className="block text-lg font-medium text-dark-green">
                {t('labels.productNameEn')} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="productName"
                value={productData.productName || ''}
                onChange={handleChange}
                placeholder={t('placeholders.productNameEn')}
                required
                className="block w-full p-3 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
              />
            </div>

            <div className="flex-1">
              <label className="block text-lg font-medium text-dark-green">
                {t('labels.productNameAr')} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="productNameAr"
                value={productData.productNameAr || ''}
                onChange={handleChange}
                placeholder={t('placeholders.productNameAr')}
                required
                className="block w-full p-3 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
              />
            </div>
          </div>

          {/* Description */}
          <div className="flex flex-col space-y-4">
            <h3 className="block text-lg font-medium text-dark-green">
              {t('labels.description')}
            </h3>
            <div className="flex flex-col gap-4 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
              {/* English Description */}
              <div className="flex-1">
                <label className="block font-medium text-md text-dark-green">
                  {t('labels.descriptionEn')}
                </label>
                <SimpleRichTextEditor
                  initialValue={productData.description || ''}
                  onChange={(content) => handleEditorChange('en', content)}
                />
              </div>

              {/* Arabic Description */}
              <div className="flex-1">
                <label className="block font-medium text-md text-dark-green">
                  {t('labels.descriptionAr')}
                </label>
                <SimpleRichTextEditor
                  initialValue={productData.descriptionAr || ''}
                  onChange={(content) => handleEditorChange('ar', content)}
                />
              </div>
            </div>
          </div>

          {/* Dynamic Description Buttons */}
          {/* <div className="flex gap-4 space-x-4">
            <button
              type="button"
              onClick={() => addDescription('en')}
              className="px-4 py-2 text-white bg-gray-800 rounded-md hover:bg-light-green focus:outline-none focus:ring-2 focus:ring-dark-green"
            >
              {t('buttons.addDescriptionEn')}
            </button>
            <button
              type="button"
              onClick={() => addDescription('ar')}
              className="px-4 py-2 text-white bg-gray-800 rounded-md hover:bg-light-green focus:outline-none focus:ring-2 focus:ring-dark-green"
            >
              {t('buttons.addDescriptionAr')}
            </button>
          </div> */}

          {/* Dynamic English Descriptions */}
          {productData.materialDetails?.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-dark-green">
                {t('headings.additionalDescriptionsEn')}
              </h3>
              {productData.materialDetails.map((desc, index) => (
                <div
                  key={index}
                  className="relative p-4 border border-gray-300 rounded-md bg-light-green"
                >
                  <label className="block font-medium text-dark-green">
                    {t('labels.title')}
                  </label>
                  <input
                    type="text"
                    name="key"
                    value={desc.key || ''}
                    onChange={(e) => handleDynamicChange(e, index, 'en')}
                    placeholder={t('placeholders.titleEn')}
                    className="block w-full p-2 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
                  />
                  <label className="block mt-2 font-medium text-dark-green">
                    {t('labels.value')}
                  </label>
                  <input
                    type="text"
                    name="value"
                    value={desc.value || ''}
                    onChange={(e) => handleDynamicChange(e, index, 'en')}
                    placeholder={t('placeholders.valueEn')}
                    className="block w-full p-2 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
                  />
                  <button
                    type="button"
                    onClick={() => removeDescription('en', index)}
                    className="absolute text-red-500 top-2 right-2 hover:text-red-700"
                    aria-label={t('buttons.removeDescription')}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Dynamic Arabic Descriptions */}
          {productData.materialDetailsAr?.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-dark-green">
                {t('headings.additionalDescriptionsAr')}
              </h3>
              {productData.materialDetailsAr.map((desc, index) => (
                <div
                  key={index}
                  className="relative p-4 border border-gray-300 rounded-md bg-light-green"
                >
                  <label className="block font-medium text-dark-green">
                    {t('labels.title')}
                  </label>
                  <input
                    type="text"
                    name="key"
                    value={desc.key || ''}
                    onChange={(e) => handleDynamicChange(e, index, 'ar')}
                    placeholder={t('placeholders.titleAr')}
                    className="block w-full p-2 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
                  />
                  <label className="block mt-2 font-medium text-dark-green">
                    {t('labels.value')}
                  </label>
                  <input
                    type="text"
                    name="value"
                    value={desc.value || ''}
                    onChange={(e) => handleDynamicChange(e, index, 'ar')}
                    placeholder={t('placeholders.valueAr')}
                    className="block w-full p-2 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
                  />
                  <button
                    type="button"
                    onClick={() => removeDescription('ar', index)}
                    className="absolute text-red-500 top-2 right-2 hover:text-red-700"
                    aria-label={t('buttons.removeDescription')}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Brand Name */}
          <div className="flex flex-col gap-4 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex-1">
              <label className="block text-lg font-medium text-dark-green">
                {t('labels.brandNameEn')}
              </label>
              <input
                type="text"
                name="brandEn"
                value={productData.brandEn || ''}
                onChange={handleChange}
                placeholder={t('placeholders.brandNameEn')}
                className="block w-full p-3 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
              />
            </div>

            <div className="flex-1">
              <label className="block text-lg font-medium text-dark-green">
                {t('labels.brandNameAr')}
              </label>
              <input
                type="text"
                name="brandAr"
                value={productData.brandAr || ''}
                onChange={handleChange}
                placeholder={t('placeholders.brandNameAr')}
                className="block w-full p-3 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
              />
            </div>
          </div>

          {/* Product ID (Optional) */}
          <div>
            <label className="block text-lg font-medium text-dark-green">
              {t('labels.productId')}
            </label>
            <input
              type="text"
              name="barcode"
              value={productData.barcode || ''}
              onChange={handleChange}
              placeholder={t('placeholders.productId')}
              className="block w-full p-3 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
            />
          </div>

          {/* Category Selection */}
          <div className="relative" ref={categoryRef}>
            <label className="block text-lg font-medium text-dark-green">
              {t('labels.category')} <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <button
                type="button"
                onClick={() => setIsCategoryOpen((prev) => !prev)}
                className="flex items-center justify-between w-full p-3 mt-1 text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
                aria-haspopup="listbox"
                aria-expanded={isCategoryOpen}
              >
                {productData.categoryNameEn && productData.categoryNameAr
                  ? `${productData.categoryNameEn} / ${productData.categoryNameAr}`
                  : t('labels.selectCategory')}
                <FiChevronDown className="w-5 h-5 text-gray-500" />
              </button>
              {isCategoryOpen && (
                <div className="absolute left-0 z-20 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-60">
                  <div className="p-2 border-b border-gray-200">
                    <div className="relative">
                      <FiSearch className="absolute text-gray-400 top-3 left-3" />
                      <input
                        type="text"
                        value={categorySearch}
                        onChange={(e) => setCategorySearch(e.target.value)}
                        placeholder={t('labels.searchCategories')}
                        className="w-full py-2 pl-10 pr-4 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
                      />
                    </div>
                  </div>
                  {renderCategoryOptions(filteredCategoryTree)}
                </div>
              )}
            </div>
          </div>

          {/* Occasion Selection */}
          <div className="relative" ref={occasionRef}>
            <label className="block text-lg font-medium text-dark-green">
              {t('labels.occasions')}
            </label>
            <div className="relative">
              <button
                type="button"
                onClick={() => setIsOccasionDropdownOpen((prev) => !prev)}
                className="flex items-center justify-between w-full p-3 mt-1 text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
                aria-haspopup="listbox"
                aria-expanded={isOccasionDropdownOpen}
              >
                {selectedOccasionNames.length > 0
                  ? selectedOccasionNames.join(', ')
                  : t('labels.selectOccasions')}
                <FiChevronDown className="w-5 h-5 text-gray-500" />
              </button>
              {isOccasionDropdownOpen && (
                <div className="absolute left-0 z-20 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-60">
                  <ul className="py-1">
                    {occasions.map((occasion) => (
                      <li key={occasion._id}>
                        <label className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100">
                          <input
                            type="checkbox"
                            className="w-5 h-5 form-checkbox text-dark-green"
                            checked={(productData.occasionIds || []).includes(
                              occasion._id
                            )}
                            onChange={() => toggleOccasion(occasion._id)}
                          />
                          <span className="ml-2 mr-2">
                            {occasion.nameEn} / {occasion.nameAr}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <p className="mt-1 text-sm text-gray-600">
              {t('labels.selectUpTo3Occasions')}
            </p>
          </div>

          {/* Save and Next Button */}
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-lg font-semibold text-white bg-gray-800 rounded-md hover:bg-light-green focus:outline-none focus:ring-2 focus:ring-dark-green"
              disabled={isProductLoading || (isStoreLoading && !isAdmin) || (isAdmin && !selectedStore)}
            >
              {isProductLoading || (isStoreLoading && !isAdmin)
                ? t('buttons.saving')
                : t('buttons.saveAndNext')}
            </button>
          </div>
        </form>
      </div>
    );
  }
);

ProductInfoPage.propTypes = {
  onNext: PropTypes.func.isRequired,
  updateProductData: PropTypes.func.isRequired,
  productData: PropTypes.shape({
    productName: PropTypes.string,
    productNameAr: PropTypes.string,
    description: PropTypes.string,
    descriptionAr: PropTypes.string,
    materialDetails: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    materialDetailsAr: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    brandEn: PropTypes.string,
    brandAr: PropTypes.string,
    categoryId: PropTypes.string,
    categoryNameEn: PropTypes.string,
    categoryNameAr: PropTypes.string,
    occasionIds: PropTypes.arrayOf(PropTypes.string),
    barcode: PropTypes.string,
    storeId: PropTypes.string,
  }),
  productId: PropTypes.string,
  onProductIdUpdate: PropTypes.func,
};

export default ProductInfoPage;
