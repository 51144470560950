// src/components/CTASection.js

import React from 'react';
import Appstore from '../assets/Images/apple-store-icon-transparent-20.jpg';
const CTASection = () => {
  return (
    <section className="py-16 bg-gray-900">
      <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
        <h2 className="mb-6 text-4xl font-extrabold text-white">
          GO MOBILE
        </h2>
        <h3 className="mb-4 text-2xl font-semibold text-green-500">
          Use the Free Markaz Seller App
        </h3>
        <p className="mb-8 text-gray-300">
          "The Markaz Seller app is packed with features to help you manage and grow your e-commerce business wherever you are. It gives you the freedom to take care of business details right from your phone."
        </p>
        <div className="flex justify-center space-x-6">
          {/* Google Play Store Badge */}
          <a
            href="https://play.google.com/store/apps/details?id=com.markaz.buyer_app"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-transform duration-300 transform hover:scale-105"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Download on the Google Play Store"
              className="w-auto h-12"
            />
          </a>
          {/* Apple App Store Badge (PNG) */}
          <a
            href="https://apps.apple.com/app/markazz-kuwait-shopping-online/id6667114733"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-transform duration-300 transform hover:scale-105"
          >
            <img
              src={Appstore}
              alt="Download on the App Store"
              className="w-auto h-12"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
