import React from 'react';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  const { t, i18n } = useTranslation('confirmationModal');

  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center"
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Modal Content */}
      <div className="z-10 w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-semibold">
            {title || t('modals.confirmDeletion.title')}
          </h3>
          <button onClick={onCancel} className="text-gray-600 hover:text-gray-800">
            <FaTimes />
          </button>
        </div>

        {/* Message */}
        <p className="mb-6">
          {message || t('modals.confirmDeletion.message')}
        </p>

        {/* Actions */}
        <div className={`flex justify-end ${isArabic ? 'space-x-reverse' : ''} space-x-4`}>
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 bg-gray-300 rounded hover:bg-gray-400"
          >
            {t('buttons.cancel')}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
          >
            {t('buttons.delete')}
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;


