// src/components/Product/SimpleRichTextEditor.js

import React, { useRef, useEffect, useState } from 'react';
import './SimpleRichTextEditor.css'; // Ensure you have the CSS file

const SimpleRichTextEditor = ({ initialValue = '', onChange }) => {
  const editorRef = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const resizingColumnRef = useRef(null); // Track which column is being resized
  const startXRef = useRef(0);
  const startWidthRef = useRef(0);

  // Initialize editor content once on mount
  useEffect(() => {
    if (editorRef.current && isInitialLoad) {
      editorRef.current.innerHTML = initialValue;
      setIsInitialLoad(false);
      addResizersToTables();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, isInitialLoad]);

  // Update editor content when initialValue changes (for loading existing data)
  useEffect(() => {
    if (editorRef.current && !isInitialLoad) {
      const currentContent = editorRef.current.innerHTML;
      if (currentContent !== initialValue) {
        editorRef.current.innerHTML = initialValue;
        addResizersToTables();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, isInitialLoad]);

  // Function to add resizer handles to all tables in the editor
  const addResizersToTables = () => {
    const tables = editorRef.current.querySelectorAll('table.resizable-table');
    tables.forEach((table) => {
      const ths = table.querySelectorAll('th');
      ths.forEach((th, index) => {
        if (!th.querySelector('.resizer')) {
          const resizer = document.createElement('div');
          resizer.className = 'resizer';
          th.appendChild(resizer);

          // Add event listeners for resizing
          resizer.addEventListener('mousedown', (e) => initResize(e, th, index, table));
        }
      });
    });
  };

  // Initialize resizing
  const initResize = (e, th, columnIndex, table) => {
    e.preventDefault();
    resizingColumnRef.current = { th, columnIndex, table };
    startXRef.current = e.clientX;
    startWidthRef.current = th.offsetWidth;

    document.addEventListener('mousemove', handleResize);
    document.addEventListener('mouseup', stopResize);
    th.classList.add('resizing');
  };

  // Handle the resizing
  const handleResize = (e) => {
    if (!resizingColumnRef.current) return;

    const { th, columnIndex, table } = resizingColumnRef.current;
    const dx = e.clientX - startXRef.current;
    const newWidth = startWidthRef.current + dx;

    if (newWidth > 50) { // Minimum column width
      th.style.width = `${newWidth}px`;
      // Set width for all cells in the column
      const tds = table.querySelectorAll(`td:nth-child(${columnIndex + 1})`);
      tds.forEach((td) => {
        td.style.width = `${newWidth}px`;
      });
    }
  };

  // Stop resizing
  const stopResize = () => {
    if (!resizingColumnRef.current) return;
    const { th } = resizingColumnRef.current;
    th.classList.remove('resizing');
    resizingColumnRef.current = null;

    document.removeEventListener('mousemove', handleResize);
    document.removeEventListener('mouseup', stopResize);
  };

  // Execute command without disrupting cursor
  const execCommand = (command, value = null) => {
    document.execCommand(command, false, value);
    onChange(editorRef.current.innerHTML);
  };

  // Apply text alignment using execCommand
  const applyAlignment = (alignment) => {
    editorRef.current.focus(); // Ensure the editor is focused
    let command;
    switch(alignment) {
      case 'left':
        command = 'justifyLeft';
        break;
      case 'center':
        command = 'justifyCenter';
        break;
      case 'right':
        command = 'justifyRight';
        break;
      default:
        return;
    }
    execCommand(command);
  };

  // Insert a basic 2-column table
  const insertTable = () => {
    const tableHTML = `
      <table class="resizable-table" border="1">
        <thead>
          <tr>
            <th>Column 1</th>
            <th>Column 2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Row 1, Cell 1</td>
            <td>Row 1, Cell 2</td>
          </tr>
          <tr>
            <td>Row 2, Cell 1</td>
            <td>Row 2, Cell 2</td>
          </tr>
        </tbody>
      </table>
    `;
    insertHTMLAtCursor(tableHTML);
    setTimeout(addResizersToTables, 0); // Ensure resizers are added after table is inserted
    onChange(editorRef.current.innerHTML);
    editorRef.current.focus();
  };

  // Add row to the last table
  const addRow = () => {
    const tables = editorRef.current.querySelectorAll('table.resizable-table');
    if (tables.length === 0) {
      // Insert a table if none exists
      insertTable();
      return;
    }

    const lastTable = tables[tables.length - 1];
    const tbody = lastTable.querySelector('tbody');
    const ths = lastTable.querySelectorAll('th');
    const numCols = ths.length;

    const newRow = tbody.insertRow(-1);
    for (let i = 0; i < numCols; i++) {
      const newCell = newRow.insertCell(i);
      newCell.textContent = `Row ${lastTable.rows.length - 1}, Cell ${i + 1}`;
      newCell.style.width = ths[i].style.width; // Maintain column width
    }

    onChange(editorRef.current.innerHTML);
  };

  // Delete the last table
  const deleteTable = () => {
    const tables = editorRef.current.querySelectorAll('table.resizable-table');
    if (tables.length === 0) {
      // No tables to delete
      return;
    }

    const lastTable = tables[tables.length - 1];
    lastTable.remove();

    onChange(editorRef.current.innerHTML);
  };

  // Delete the last row of the last table
  const deleteRow = () => {
    const tables = editorRef.current.querySelectorAll('table.resizable-table');
    if (tables.length === 0) {
      // No tables available
      return;
    }

    const lastTable = tables[tables.length - 1];
    const tbody = lastTable.querySelector('tbody');
    if (tbody.rows.length === 0) {
      // No rows to delete
      return;
    }

    tbody.deleteRow(-1);

    onChange(editorRef.current.innerHTML);
  };

  // Helper to insert HTML at the cursor position
  const insertHTMLAtCursor = (html) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    range.deleteContents();

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const fragment = document.createDocumentFragment();
    let node, lastNode;
    while ((node = tempDiv.firstChild)) {
      lastNode = fragment.appendChild(node);
    }
    range.insertNode(fragment);

    // Move cursor to the end of the inserted HTML
    if (lastNode) {
      range.setStartAfter(lastNode);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // Notify parent of the change
    onChange(editorRef.current.innerHTML);
  };

  // Handle content changes
  const handleInput = () => {
    onChange(editorRef.current.innerHTML);
  };

  // Handle keyboard shortcuts for bold, italic, underline
  const handleKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey) { // Support Mac's Command key
      switch (e.key.toLowerCase()) {
        case 'b':
          e.preventDefault();
          execCommand('bold');
          break;
        case 'i':
          e.preventDefault();
          execCommand('italic');
          break;
        case 'u':
          e.preventDefault();
          execCommand('underline');
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="simple-rich-text-editor">
      {/* Toolbar */}
      <div className="toolbar">
        <button
          type="button"
          onClick={() => execCommand('bold')}
          className="toolbar-button"
          aria-label="Bold"
          title="Bold (Ctrl+B)"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          <strong>B</strong>
        </button>
        <button
          type="button"
          onClick={() => execCommand('italic')}
          className="toolbar-button"
          aria-label="Italic"
          title="Italic (Ctrl+I)"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          <em>I</em>
        </button>
        {/* Alignment Buttons */}
        <button
          type="button"
          onClick={() => applyAlignment('left')}
          className="toolbar-button"
          aria-label="Align Left"
          title="Align Left"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          {/* Left Alignment Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path d="M4 5h12a1 1 0 010 2H4a1 1 0 010-2zm0 4h8a1 1 0 010 2H4a1 1 0 010-2zm0 4h12a1 1 0 010 2H4a1 1 0 010-2z" />
          </svg>
        </button>
        <button
          type="button"
          onClick={() => applyAlignment('center')}
          className="toolbar-button"
          aria-label="Align Center"
          title="Align Center"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          {/* Center Alignment Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path d="M4 5h12a1 1 0 010 2H4a1 1 0 010-2zm4 4h4a1 1 0 010 2h-4a1 1 0 010-2zm-4 4h12a1 1 0 010 2H4a1 1 0 010-2z" />
          </svg>
        </button>
        <button
          type="button"
          onClick={() => applyAlignment('right')}
          className="toolbar-button"
          aria-label="Align Right"
          title="Align Right"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          {/* Right Alignment Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path d="M4 5h12a1 1 0 010 2H4a1 1 0 010-2zm8 4h4a1 1 0 010 2h-4a1 1 0 010-2zm-8 4h12a1 1 0 010 2H4a1 1 0 010-2z" />
          </svg>
        </button>
        <button
          type="button"
          onClick={insertTable}
          className="toolbar-button"
          aria-label="Insert Table"
          title="Insert Table"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          Table
        </button>
        <button
          type="button"
          onClick={addRow}
          className="toolbar-button"
          aria-label="Add Row"
          title="Add Row"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          + Row
        </button>
        <button
          type="button"
          onClick={deleteRow}
          className="toolbar-button"
          aria-label="Delete Row"
          title="Delete Last Row"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          - Row
        </button>
        <button
          type="button"
          onClick={deleteTable}
          className="toolbar-button"
          aria-label="Delete Table"
          title="Delete Last Table"
          onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
        >
          🗑️
        </button>
      </div>

      {/* Editable content area */}
      <div
        ref={editorRef}
        contentEditable
        className="editor"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        suppressContentEditableWarning={true}
        aria-label="Rich Text Editor"
      ></div>
    </div>
  );
};

export default SimpleRichTextEditor;
