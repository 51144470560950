// src/components/TestimonialSection.js

import React from 'react';

const testimonials = [
  {
    id: 1,
    name: 'Aisha Khan',
    role: 'Entrepreneur',
    feedback: 'Markaz has transformed the way I manage my online store. The tools provided are intuitive and powerful.',
    image: '/images/testimonials/aisha.jpg', // Ensure this image exists in public/images/testimonials/
  },
  {
    id: 2,
    name: 'Bilal Ahmed',
    role: 'Small Business Owner',
    feedback: 'The marketing tools on Markaz helped me reach a wider audience. My sales have increased significantly!',
    image: '/images/testimonials/bilal.jpg',
  },
  {
    id: 3,
    name: 'Sara Malik',
    role: 'Freelancer',
    feedback: 'Support from the Markaz team is outstanding. They guided me through every step of setting up my store.',
    image: '/images/testimonials/sara.jpg',
  },
  // Add more testimonials as needed
];

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className="flex flex-col items-center p-6 text-center transition-shadow duration-300 bg-gray-800 rounded-lg shadow-lg hover:shadow-2xl">
      <img
        src={testimonial.image}
        alt={`${testimonial.name}'s photo`}
        className="object-cover w-24 h-24 mb-4 rounded-full"
      />
      <h3 className="mb-1 text-xl font-semibold text-green-500">{testimonial.name}</h3>
      <p className="mb-4 text-sm text-gray-400">{testimonial.role}</p>
      <p className="italic text-gray-300">"{testimonial.feedback}"</p>
    </div>
  );
};

const TestimonialSection = () => {
  return (
    <section className="py-16 bg-gray-900">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="mb-12 text-4xl font-extrabold text-center text-white">
          What Our Users Say
        </h2>
        <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.id} testimonial={testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
