// services/api.js
import axios from 'axios';

const BASE_URL = 'https://markaz-api-ee158599a77e.herokuapp.com';
//https://markaz-api-test-80c708aeffe9.herokuapp.com
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer jCXumGJZn3333Sfsr1HiZ06Czg', // Replace with actual token
  },
});

// Check if email exists
export const checkEmailExist = (email) => api.post('/vendors/isEmailExist', { email });

// Sign in API
export const signIn = (email, password) => api.post('/vendors/signin', { email, password });

// Sign up API
export const signUp = (email, password, name) => api.post('/vendors/signup', { email, password, name });

// Forgot Password API
export const verifyEmailForgotPasswordAPI = (email) =>
  api.post('/vendors/verifyemailforgotpassword', { email });

// OTP Verification API
export const verifyOtpAPI = (id, code) => api.post('/vendors/verifyOtp', { id, code });

// Change Password API
export const changePasswordApi = (email, password) => {
  return api.patch('/vendors/updateByEmail', { email, password });
};

// Fetch business categories
export const getBusinessCategoriesAPI = () => api.get('/businessCategory/all');

// Fetch store details by vendor ID
// Fetch store details by vendor ID
export const getStoreDetailsAPI = (vendorId) => api.post('/stores/getStore', { vendorId });
// Create new store
export const createStoreAPI = (storeData) => {
  return api.post('/stores/create', storeData);
};

// Update store
export const updateStoreAPI = (storeData) => {
  return api.patch('/stores/update', storeData);
};


// Upload image API
export const uploadImageAPI = (imageFile) => {
  const formData = new FormData();
  formData.append('imgFile', imageFile); // Ensure the key matches what the backend expects

  return api.post('/image/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(response => {
    // Log the response to verify the structure
    return response;
  }).catch(error => {
   // Log detailed error information
    throw error;
  });
};
// Fetch delivery options for store
export const getDeliveryOptionsAPI = () => api.get('/stores/vendordelivery');
// Fetch product categories
export const getProductCategoriesAPI = () => api.get('/productCategory/all');

// Fetch occasions
export const getOccasionsAPI = () => api.get('/occasion/all');

// Create new product
export const createProductAPI = (data) => api.post('/products/create', data);

// **Fetch product details**
export const fetchProductAPI = (productId) => {
  // Ensure the payload is an object with productId
  return api.patch('/products/update', { productId });
};

// **Update existing product**
export const updateProductAPI = (productId, data) => {
  // Ensure the payload includes productId and the data to update
  return api.patch('/products/update', { productId, ...data });
};

export const getVendorProductsAPI = ({ limit, page, vendorId, search, sortBy }) => {
  return api.post('/products/getallVendorProductsPagination', {
    limit,
    page,
    vendorId,
    search,
    sortBy,
  });
};

// **Fetch colors**
export const getColorsAPI = () => api.get('/products/getColors');
export const deleteProductAPI = (productId) => {
  return api.post('/products/deleteProduct', { productId });
};

// Get Vendor Detail API
/**
 * Fetches vendor details based on the provided vendor ID.
 * @param {string} id - The unique identifier of the vendor.
 * @returns {Promise} - Axios response promise.
 */
export const getVendorDetailAPI = (id) => {
  return api.post('/vendors/getvendor', { id });
};

// Update Vendor API
/**
 * Updates vendor details based on the provided vendor ID and data.
 * @param {string} id - The unique identifier of the vendor.
 * @param {Object} data - The data to update (e.g., { name: "New Name" }).
 * @returns {Promise} - Axios response promise.
 */
export const updateVendorAPI = (id, data) => {
  return api.patch('/vendors/update', { id, ...data });
};
export default api; // Exporting the configured axios instance

