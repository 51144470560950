// src/pages/Auth/HelpPage.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailForgotPassword } from '../../redux/auth/authThunks'; // Assuming this is your resend API function
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const HelpPage = () => {
  const { t, i18n } = useTranslation('help'); // Specify the 'help' namespace
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email'); // Extract email from URL if available

  const { isLoading } = useSelector((state) => state.auth);
  const [resendDisabled, setResendDisabled] = useState(false); // Disable resend for a few seconds to prevent spamming

  const handleResendEmail = () => {
    if (!email) {
      toast.error(t('email_not_available')); // Error message for missing email
      return;
    }

    // Trigger the API to resend the email
    dispatch(verifyEmailForgotPassword({ email }))
      .unwrap()
      .then((response) => {
        if (response.status === 1) {
          toast.success(t('otp_resent_success')); // Success message
          setResendDisabled(true); // Disable button after resend
          setTimeout(() => setResendDisabled(false), 60000); // Re-enable after 60 seconds
        } else {
          toast.error(t('otp_resend_fail')); // Error message if status !== 1
        }
      })
      .catch((err) => {
        toast.error(t('error_resending_otp')); // Catch and show error
      });
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-50"
      dir={t('dir') === 'rtl' ? 'rtl' : 'ltr'} // Handle directionality based on language
    >
      <div
        className={`w-full max-w-md p-8 bg-white rounded-lg shadow-lg ${
          t('dir') === 'rtl' ? 'font-cairo text-right' : 'font-rubik text-left'
        }`}
      >
        <h2 className="mb-6 text-2xl font-extrabold text-gray-900">{t('title')}</h2>

        <p className="mb-4 text-sm font-semibold text-gray-600">{t('didnt_receive_email')}</p>

        <p className="mb-4 text-sm text-gray-600">{t('first_check_that_you')}</p>

        <ul className="mb-4 space-y-3 text-gray-600 list-decimal list-inside">
          <li>{t('wait_three_minutes')}</li>
          <li>{t('check_your_junk')}</li>
          <li>{t('if_you_use_gmail')}</li>
          <li>{t('if_it_still_hasnt')}</li>
          <li>
            {t('if_none_of_that_works')}{' '}
            <a href={`mailto:${t('support_email')}`} className="text-green-600 underline">
              {t('support_email')}
            </a>{' '}
            {t('and_we_will_help')}
          </li>
        </ul>

        {/* Resend Button */}
        <div className="mt-6">
          <button
            onClick={handleResendEmail}
            disabled={resendDisabled || isLoading}
            className={`w-full py-2 px-4 bg-black text-white border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
              resendDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-black'
            }`}
          >
            {isLoading ? t('resending') : t('resend_email')}
          </button>
        </div>

        {/* Back Button */}
        <div className="mt-4 text-sm text-gray-600">
          <button
            onClick={() => navigate(-1)}
            className="text-green-600 underline hover:text-green-800"
          >
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
