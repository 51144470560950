import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home/Home';
import SignIn from './pages/Auth/SignIn';
import SignUp from './pages/Auth/SignUp';
import ContinueOptionScreen from './pages/Auth/ContinueOptionScreen';
import './App.css';
import ForgotPasswordEmailScreen from './pages/Auth/ForgotPasswordEmailScreen';
import OTPCodeScreen from './pages/Auth/OTPCodeScreen';
import ForgotPasswordChangeScreen from './pages/Auth/ForgotPasswordChangeScreen';
import PrivateRoute from './components/PrivateRoute';
import HelpPage from './pages/Auth/Needhelp';
import { useTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { Toaster } from 'react-hot-toast';
import Footer from './components/Footer/Footer';
import ManageStore from './pages/ManageStore/ManageStore';
import ManageProductScreen from './pages/ManageProductScreen/ManageProductScreen';
import ViewAllProductsScreen from './components/Product/ViewAllProductsScreen';
import ContactUs from '../src/pages/ContactUs'
import PrivacyPolicy from '../src/pages/Term&Conditions'
import MyDetailScreen from './pages/MyDetailScreen';
const App = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || 'en';
  const location = useLocation();

  // Sidebar state and toggle function
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // Define routes where the sidebar should be hidden
  const noSidebarRoutes = [
    '/signin',
    '/signup',
    '/forgot-password',
    '/forgot-password-change',
    '/otp',
    '/continue',
    '/help'
  ];

  // Determine if the sidebar should be shown based on the current route
  const showSidebar = !noSidebarRoutes.includes(location.pathname);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      
      {/* Conditionally render Sidebar and adjust main layout based on sidebar visibility */}
      {showSidebar && <Sidebar isSidebarOpen={isSidebarOpen} />}

      {/* Render Header with sidebar control props */}
      <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
      
      <div className="flex flex-col min-h-screen">
        <main
          className="flex-1 transition-all duration-300"
          style={{
            marginLeft: showSidebar
              ? isSidebarOpen
                ? '16rem'  // Open sidebar width
                : '4rem'   // Closed sidebar width
              : '0',       // No sidebar
            paddingLeft: showSidebar && !isSidebarOpen ? '1rem' : '0', // Adds a gap when sidebar is closed
            paddingTop: '4rem', // Adjust for header height
          }}
        >
          <Routes>
            {/* Public Routes */}
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPasswordEmailScreen />} />
            <Route path="/forgot-password-change" element={<ForgotPasswordChangeScreen />} />
            <Route path="/otp" element={<OTPCodeScreen />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/continue" element={<ContinueOptionScreen />} />

            {/* Private Routes */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-store"
              element={
                <PrivateRoute>
                  <ManageStore />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-store/:storeId"
              element={
                <PrivateRoute>
                  <ManageStore />
                </PrivateRoute>
              }
            />
       
            <Route
              path="/products"
              element={
                <PrivateRoute>
                  <ViewAllProductsScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-product/:productId?"
              element={
                <PrivateRoute>
                  <ManageProductScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/contact-us"
              element={
                <PrivateRoute>
                  <ContactUs />
                </PrivateRoute>
              }
            />
             <Route path="/vendor-detail/:id" element={<PrivateRoute><MyDetailScreen/></PrivateRoute>} />
            <Route
              path="/privacy-policy"
              element={
                <PrivateRoute>
                  <PrivacyPolicy />
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default App;
