// src/pages/Auth/ForgotPasswordEmailScreen.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailForgotPassword } from '../../redux/auth/authThunks';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ForgotPasswordEmailScreen = () => {
  const { t, i18n } = useTranslation(); // Access the translation function and i18n instance
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.auth);
  const [emailError, setEmailError] = useState('');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = () => {
    setEmailError('');

    if (!email) {
      setEmailError(t('auth.forgotPassword.errorPleaseEnterEmail'));
      toast.error(t('auth.forgotPassword.errorPleaseEnterEmail'));
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError(t('auth.forgotPassword.errorPleaseEnterValidEmail'));
      toast.error(t('auth.forgotPassword.errorPleaseEnterValidEmail'));
      return;
    }

    dispatch(verifyEmailForgotPassword({ email }))
      .unwrap()
      .then((response) => {
        if (response.status === 1) {
          toast.success(t('auth.forgotPassword.toastOtpSent'));
          navigate(`/otp?email=${email}&verifyId=${response.data.verifyId}`);
        } else {
          toast.error(t('auth.forgotPassword.toastEmailDoesNotExist'));
          setEmailError(t('auth.forgotPassword.errorEmailDoesNotExist'));
        }
      })
      .catch((err) => {
        const errorMsg = err || t('auth.forgotPassword.toastErrorProcessing');
        setEmailError(errorMsg);
        toast.error(errorMsg);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-50"
      dir={isArabic ? 'rtl' : 'ltr'} // Handle directionality
    >
      <div
        className={`w-full max-w-md p-8 bg-white rounded-lg shadow-lg ${
          isArabic ? 'font-cairo text-right' : 'font-rubik text-left'
        }`}
      >
        <h2 className="mb-6 text-2xl font-extrabold text-gray-900">{t('auth.forgotPassword.title')}</h2>
        <p className="mb-4 text-sm text-gray-600">{t('auth.forgotPassword.description')}</p>

        {/* Email Field */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {t('auth.forgotPassword.emailLabel')}
          </label>
          <input
            type="email"
            id="email"
            className={`block w-full px-3 py-2 mt-1 border ${
              emailError ? 'border-red-500' : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            placeholder={t('auth.forgotPassword.emailPlaceholder')}
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && <p className="mt-2 text-sm text-red-500">{emailError}</p>}
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isLoading ? 'bg-gray-500' : 'bg-black'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          >
            {isLoading ? t('auth.forgotPassword.submitting') : t('auth.forgotPassword.submitButton')}
          </button>
        </div>

        {/* Sign In Link */}
        <div className="flex items-center justify-center mt-6">
          <span className="mr-2 text-gray-600">{t('auth.forgotPassword.rememberedPassword')}</span>
          <button
            onClick={() => navigate('/signin')}
            className="text-green-500 hover:text-green-700 focus:outline-none"
          >
            {t('auth.forgotPassword.signIn')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordEmailScreen;
