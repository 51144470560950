import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProductInfoPage from '../../components/Product/ProductInfoPage';
import ProductSizeColorPage from '../../components/Product/ProductSizeColorPage';
import ProductImagePage from '../../components/Product/ProductImagePage';
import { useTranslation } from 'react-i18next';

const ManageProductScreen = () => {
  const { t, i18n } = useTranslation('manageProduct');
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const navigate = useNavigate();
  const { productId: routeProductId } = useParams();

  const [activeTab, setActiveTab] = useState(0); // 0: Product Info, 1: Sizes & Colors, 2: Images & Details
  const [completedTabs, setCompletedTabs] = useState([]); // Track completed tabs
  const [productData, setProductData] = useState({
    productName: '',
    productNameAr: '',
    description: '',
    descriptionAr: '',
    materialDetails: [],
    materialDetailsAr: [],
    brandEn: '',
    brandAr: '',
    categoryId: '',
    categoryNameEn: '',
    categoryNameAr: '',
    occasionId: '',
    barcode: '', // Product barcode
    images: [], // Image URLs or files
    sizes: '',
    colors: '',
  });
  const [localProductId, setLocalProductId] = useState(routeProductId || null);

  useEffect(() => {
    setLocalProductId(routeProductId || null);
  }, [routeProductId]);

  const isEditing = Boolean(routeProductId);

  const updateProductDataHandler = useCallback((newData) => {
    setProductData((prev) => ({ ...prev, ...newData }));
  }, []);

  const handleProductIdUpdate = useCallback((newProductId) => {
    setLocalProductId(newProductId);
  }, []);

  const markTabAsCompleted = (tabIndex) => {
    setCompletedTabs((prevCompleted) =>
      prevCompleted.includes(tabIndex) ? prevCompleted : [...prevCompleted, tabIndex]
    );
  };

  const handleNextStep = () => {
    markTabAsCompleted(activeTab);
    setActiveTab((prev) => Math.min(prev + 1, 2));
  };

  const handlePrevStep = () => {
    setActiveTab((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    markTabAsCompleted(activeTab);
    navigate('/products');
  };

  const isTabAccessible = (tabIndex) => {
    // Editing mode: all tabs are accessible
    if (isEditing) {
      return true;
    }
    // Creating mode: only completed tabs and the current tab are accessible
    return completedTabs.includes(tabIndex) || tabIndex === activeTab;
  };

  const tabs = [
    t('tabs.productInfo'),
    t('tabs.sizesColors'),
    t('tabs.imagesDetails'),
  ];

  const renderActiveTab = () => {
    switch (activeTab) {
      case 0:
        return (
          <ProductInfoPage
            onNext={() => {
              handleNextStep();
              markTabAsCompleted(0);
            }}
            updateProductData={updateProductDataHandler}
            productData={productData}
            productId={localProductId}
            onProductIdUpdate={handleProductIdUpdate}
          />
        );
      case 1:
        return (
          <ProductSizeColorPage
            onNext={() => {
              handleNextStep();
              markTabAsCompleted(1);
            }}
            onPrev={handlePrevStep}
            updateProductData={updateProductDataHandler}
            productData={productData}
            productId={localProductId}
          />
        );
      case 2:
        return (
          <ProductImagePage
            onPrev={handlePrevStep}
            onNext={handleNext}
            updateProductData={updateProductDataHandler}
            productData={productData}
            productId={localProductId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container px-4 py-8 mx-auto md:px-10" dir={isArabic ? 'rtl' : 'ltr'}>
      <div className="flex justify-between border-b border-gray-300">
        {tabs.map((tabLabel, index) => {
          const accessible = isTabAccessible(index);
          const isActive = activeTab === index;
          return (
            <button
              key={index}
              className={`flex-1 py-3 text-center text-lg font-medium ${
                isActive
                  ? 'border-b-4 border-green-500 text-green-600 font-bold'
                  : accessible
                  ? 'text-gray-500 hover:text-green-500'
                  : 'text-gray-300 cursor-not-allowed'
              }`}
              onClick={() => accessible && setActiveTab(index)}
              disabled={!accessible}
            >
              {tabLabel}
            </button>
          );
        })}
      </div>

      <div className="p-8 bg-white rounded-lg shadow-lg">{renderActiveTab()}</div>
    </div>
  );
};

export default ManageProductScreen;
