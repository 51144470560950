// src/redux/vendor/vendorSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { getVendorDetail, updateVendor } from './vendorThunks';

const initialState = {
  vendor: null,
  isLoading: false,
  isUpdating: false,
  error: null,
};

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    // You can add synchronous actions here if needed
    clearVendor: (state) => {
      state.vendor = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getVendorDetail
      .addCase(getVendorDetail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getVendorDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendor = action.payload;
      })
      .addCase(getVendorDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      
      // Handle updateVendor
      .addCase(updateVendor.pending, (state) => {
        state.isUpdating = true;
        state.error = null;
      })
      .addCase(updateVendor.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.vendor = action.payload;
      })
      .addCase(updateVendor.rejected, (state, action) => {
        state.isUpdating = false;
        state.error = action.payload;
      });
  },
});

export const { clearVendor } = vendorSlice.actions;

export default vendorSlice.reducer;
