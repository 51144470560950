import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBusinessCategoriesAPI,
  getStoreDetailsAPI,
  createStoreAPI,
  updateStoreAPI,
} from '../../services/api';

// Fetch business categories
export const getBusinessCategories = createAsyncThunk(
  'store/getBusinessCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getBusinessCategoriesAPI();
      return response.data.data; // Categories array in the response
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error fetching categories');
    }
  }
);

// Fetch store details based on vendor ID
// storeThunks.js



// Adjust the import path as needed

export const fetchStoreDetails = createAsyncThunk(
  'store/fetchStoreDetails',
  async (vendorId, { rejectWithValue }) => {
    try {
      // Retrieve user email from localStorage to determine if the user is an admin
      const storedUser = localStorage.getItem('user');
      const userEmail = storedUser ? JSON.parse(storedUser).email : null;
      const isAdmin = userEmail === 'm.razaimtiaz@gmail.com'; // Replace with actual admin email

      const response = await getStoreDetailsAPI(vendorId);

      if (response.data.status === 1) {
        // If user is admin, return all stores; otherwise, return only the first store
        return isAdmin ? response.data.data : response.data.data[0];
      } else {
        return rejectWithValue(response.data.message || 'Failed to fetch store details');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error fetching store');
    }
  }
);



// Create or Update Store with logging

export const createOrUpdateStore = createAsyncThunk(
  'store/createOrUpdateStore',
  async (storeData, { rejectWithValue }) => {
    try {
      const response = storeData.storeId
        ? await updateStoreAPI(storeData) // Update store if `storeId` exists
        : await createStoreAPI(storeData); // Create store otherwis
      if (response.data.status === 1) {
        const updatedStoreData = {
          ...response.data.data,
          storeId: response.data.data._id || storeData.storeId, // Assign _id to storeId if not explicitly returned
        };
        return updatedStoreData;
      } else {
        return rejectWithValue(response.data.message || 'Error creating/updating store');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error creating/updating store');
    }
  }
);
