// src/components/Product/ViewAllProductsScreen.js

import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendorProducts, deleteProduct } from '../../redux/product/productThunks';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../components/ConfirmationModal';
import { FaArrowLeft, FaPlus, FaSearch, FaTimes, FaEdit, FaTrash } from 'react-icons/fa';
import CircularLoader from '../Shared/CircularLoader';
import throttle from 'lodash.throttle';
import { useTranslation } from 'react-i18next';

const ViewAllProductsScreen = () => {
  const { t, i18n } = useTranslation('viewAllProducts', 'confirmationModal');
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products, isLoading, isLoadingMore, error, hasNextPage } = useSelector(
    (state) => state.product
  );

  const [vendorId, setVendorId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 20;
  const [searchInput, setSearchInput] = useState('');

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  // Custom debounce hook
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearchQuery = useDebounce(searchInput, 500);

  // Get vendorId from local storage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const userData = JSON.parse(storedUser);
        setVendorId(userData._id);
      } catch (parseError) {
        console.error('Error parsing user data from localStorage:', parseError);
      }
    }
  }, []);

  // Fetch products when vendorId, currentPage, limit, or debouncedSearchQuery changes
  useEffect(() => {
    if (vendorId) {
      dispatch(
        fetchVendorProducts({
          page: currentPage,
          limit,
          vendorId,
          search: debouncedSearchQuery,
          sortBy: 'createTime',
        })
      )
        .unwrap()
        .then((response) => {
          console.log(`Fetched Page ${currentPage}:`, response);
        })
        .catch((fetchError) => {
          console.error('Failed to fetch products:', fetchError);
        });
    }
  }, [dispatch, currentPage, limit, debouncedSearchQuery, vendorId]);

  const handleEditProduct = (productId) => {
    navigate(`/manage-product/${productId}`);
  };

  const openDeleteModal = (productId) => {
    setProductToDelete(productId);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setProductToDelete(null);
    setIsModalOpen(false);
  };

  const confirmDeleteProduct = () => {
    if (productToDelete) {
      dispatch(deleteProduct(productToDelete))
        .unwrap()
        .then(() => {
          // After deletion, refetch the current page to get updated products
          dispatch(
            fetchVendorProducts({
              page: currentPage,
              limit,
              vendorId,
              search: debouncedSearchQuery,
              sortBy: 'createTime',
            })
          );
          closeDeleteModal();
        })
        .catch((deleteError) => {
          alert(`${t('errors.deleteProduct')}: ${deleteError}`);
          closeDeleteModal();
        });
    }
  };

  const renderProductStatus = (status, reason) => {
    let statusText = '';
    let statusColor = '';

    switch (status) {
      case '-1':
        statusText = t('status.rejected');
        statusColor = 'bg-red-500';
        break;
      case '0':
        statusText = t('status.underReview');
        statusColor = 'bg-yellow-500';
        break;
      case '1':
        statusText = t('status.approved');
        statusColor = 'bg-green-500';
        break;
      default:
        statusText = t('status.incomplete');
        statusColor = 'bg-gray-500';
    }

    return (
      <div className={`px-2 py-1 text-xs font-semibold text-white rounded ${statusColor}`}>
        {statusText}
        {status === '-1' && reason && (
          <div className="mt-1 text-sm">
            {t('status.reason')}: {reason}
          </div>
        )}
      </div>
    );
  };

  // Determine if there are no products after search
  const noProductsFound =
    !isLoading && products && products.length === 0 && debouncedSearchQuery !== '';

  // Determine if initial loading (before products are fetched)
  const initialLoading = isLoading && currentPage === 1;

  // Handle Clear Search
  const clearSearch = () => {
    setSearchInput('');
    setCurrentPage(1);
  };

  // Handle deletion edge case: If a deletion leaves the current page empty, go back a page if possible
  useEffect(() => {
    if (
      !isLoading &&
      !isLoadingMore &&
      products.length === 0 &&
      currentPage > 1 &&
      debouncedSearchQuery === ''
    ) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [products, isLoading, isLoadingMore, currentPage, debouncedSearchQuery]);

  // useRef to track if a fetch is in progress
  const isFetchingRef = useRef(false);

  // Function to fetch more products
  const fetchMoreProducts = useCallback(() => {
    if (hasNextPage && !isLoadingMore && !isLoading && !isFetchingRef.current) {
      console.log('Fetching next page...');
      setCurrentPage((prevPage) => prevPage + 1);
      isFetchingRef.current = true;
    }
  }, [hasNextPage, isLoadingMore, isLoading]);

  // Define the function to be throttled
  const scrollHandler = useCallback(() => {
    if (!hasNextPage || isLoadingMore || isLoading || isFetchingRef.current) return;

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;

    // Trigger when the user is within 100px of the bottom
    if (scrollTop + windowHeight >= fullHeight - 100) {
      fetchMoreProducts();
    }
  }, [hasNextPage, isLoadingMore, isLoading, fetchMoreProducts]);

  // Create a throttled version of the scroll handler
  const throttledScrollHandler = useMemo(
    () => throttle(scrollHandler, 300),
    [scrollHandler]
  );

  // Set up the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', throttledScrollHandler);
    console.log('Scroll event listener added');

    return () => {
      window.removeEventListener('scroll', throttledScrollHandler);
      throttledScrollHandler.cancel(); // Cancel any pending throttled calls
      console.log('Scroll event listener removed');
    };
  }, [throttledScrollHandler]);

  // Reset fetching flag when products are fetched
  useEffect(() => {
    if (!isLoadingMore) {
      isFetchingRef.current = false;
    }
  }, [isLoadingMore]);

  const renderPrice = (product) => {
    const originalPrice = parseFloat(product.showPrice) || 0;
    const discountPrice = parseFloat(product.showDiscountPrice) || originalPrice;
  
    const hasValidPrice = originalPrice > 0;
    const hasValidDiscountPrice = discountPrice > 0 && discountPrice < originalPrice;
  
    if (hasValidPrice && hasValidDiscountPrice) {
      return (
        <div>
          <span className="text-gray-500 line-through">
            {t('currency')} {originalPrice.toFixed(3)}
          </span>
          <span className={`ml-2 ${isArabic ? 'mr-2' : ''} text-green-600`}>
            {t('currency')} {discountPrice.toFixed(3)}
          </span>
        </div>
      );
    } else if (hasValidPrice) {
      return (
        <span>
          {t('currency')} {originalPrice.toFixed(3)}
        </span>
      );
    } else {
      return (
        <span>
          {t('currency')} 0.00
        </span>
      );
    }
  };
  

  if (initialLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <CircularLoader color="#4A90E2" size={50} />
      </div>
    );
  }

  if (error && currentPage === 1) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="p-6 text-red-500 bg-red-100 rounded-md shadow-md">
          {t('errors.fetchProducts')}: {error}
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen p-4 bg-gradient-to-r from-gray-100 to-gray-200"
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      {/* Back Button */}
      <button
        className={`flex items-center mb-4 text-gray-800 transition-colors duration-200 hover:text-gray-600 focus:outline-none ${
          isArabic ? 'flex-row-reverse' : ''
        }`}
        onClick={() => navigate('/')}
      >
        <FaArrowLeft className={`mr-2 ${isArabic ? 'rotate-180 ml-2' : ''}`} />{' '}
        {t('buttons.back')}
      </button>

      {/* Header Section */}
      <div className="flex flex-col items-center justify-between mb-4 space-y-4 md:flex-row md:space-y-0">
        <h2 className="text-2xl font-extrabold md:text-4xl">{t('headings.allProducts')}</h2>
        <button
          className="flex items-center px-4 py-2 text-white bg-gray-900 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500"
          onClick={() => navigate('/manage-product')}
        >
          <FaPlus className={`mr-2 text-base md:text-lg ${isArabic ? 'ml-2' : ''}`} />{' '}
          {t('buttons.addNewProduct')}
        </button>
      </div>

      {/* Search Field */}
      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder={t('placeholders.search')}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="w-full p-3 pl-12 pr-12 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
            aria-label={t('aria.searchProducts')}
          />
          <FaSearch className="absolute text-gray-400 left-3 top-3" />
          {searchInput && (
            <button
              type="button"
              onClick={clearSearch}
              className="absolute text-gray-400 right-3 top-3 hover:text-gray-600 focus:outline-none"
              aria-label={t('aria.clearSearch')}
            >
              <FaTimes />
            </button>
          )}
        </div>
      </div>

      {noProductsFound && (
        <div className="p-6 text-center text-gray-600 bg-white rounded-md shadow">
          {t('messages.noProductsFound')}
        </div>
      )}

      {!noProductsFound && (
        <div>
          {products && products.length > 0 ? (
            <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {products.map((product) => (
                <div
                  key={product._id}
                  className="flex flex-col p-4 bg-white border border-gray-200 rounded-lg shadow hover:shadow-lg"
                  style={{ maxWidth: '300px', width: '100%', height: '420px' }}
                >
                  {/* Product Image */}
                  <div className="relative flex items-center justify-center h-40 mb-4 bg-gray-100 rounded-md">
                    {product.baseImages && product.baseImages.length > 0 ? (
                      <img
                        src={product.baseImages[0].url}
                        alt={product.productName}
                        className="object-contain max-w-full max-h-full"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/fallback-image.jpg';
                        }}
                      />
                    ) : (
                      <span className="text-gray-500">{t('messages.noImage')}</span>
                    )}
                    <div className="absolute top-2 left-2">
                      {renderProductStatus(product.productReviewStatus, product.reason)}
                    </div>
                  </div>

                  {/* Product Details */}
                  <h3 className="mb-2 text-lg font-semibold truncate">
                    {product.productName}
                  </h3>
                  <p className="mb-1 text-sm text-gray-700">
                    <strong>{t('labels.id')}:</strong> {product.barcode || product._id}
                  </p>
                  <p className="mb-1 text-sm text-gray-700">
                    <strong>{t('labels.sizes')}:</strong>{' '}
                    {product.sizes && product.sizes.length > 0
                      ? product.sizes.join(', ')
                      : t('labels.na')}
                  </p>
                  <p className="mb-1 text-sm text-gray-700">
                    <strong>{t('labels.colors')}:</strong>{' '}
                    {product.colors && product.colors.length > 0
                      ? product.colors.map((color) => color.en).join(', ')
                      : t('labels.na')}
                  </p>
                  <p className="mb-2 text-sm text-gray-700">
                    <strong>{t('labels.stock')}:</strong> {product.totalStock}
                    {product.totalStock < 5 && (
                      <span className={`ml-2 ${isArabic ? 'mr-2' : ''} text-red-500`}>
                        {t('messages.lowStock')}
                      </span>
                    )}
                  </p>
                  <p className="mb-4 text-lg font-bold">{renderPrice(product)}</p>

                  {/* Actions */}
                  <div className="flex gap-2 mt-auto space-x-2">
                    <button
                      className="flex items-center justify-center flex-1 px-2 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onClick={() => handleEditProduct(product._id)}
                      aria-label={`${t('buttons.edit')} ${product.productName}`}
                    >
                      <FaEdit className={`mr-1 ${isArabic ? 'ml-1' : ''}`} /> {t('buttons.edit')}
                    </button>
                    <button
                      className="flex items-center justify-center flex-1 px-2 py-2 text-sm text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                      onClick={() => openDeleteModal(product._id)}
                      aria-label={`${t('buttons.delete')} ${product.productName}`}
                    >
                      <FaTrash className={`mr-1 ${isArabic ? 'ml-1' : ''}`} /> {t('buttons.delete')}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center p-8 text-gray-600 bg-white rounded-md shadow">
              <span>{t('messages.noProductsAvailable')}</span>
            </div>
          )}

          {isLoadingMore && (
            <div className="flex items-center justify-center mt-8">
              <CircularLoader color="#4A90E2" size={50} />
            </div>
          )}

          {error && currentPage > 1 && (
            <div className="flex items-center justify-center mt-8">
              <div className="p-6 text-red-500 bg-red-100 rounded-md shadow-md">
                {t('errors.fetchProducts')}: {error}
              </div>
            </div>
          )}

          <ConfirmationModal
            isOpen={isModalOpen}
            title={t('modals.confirmDeletion.title')}
            message={t('modals.confirmDeletion.message')}
            onConfirm={confirmDeleteProduct}
            onCancel={closeDeleteModal}
          />
        </div>
      )}
    </div>
  );
};

export default ViewAllProductsScreen;
