import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getProductCategoriesAPI,
  getOccasionsAPI,
  createProductAPI,
  fetchProductAPI, // Newly imported
  updateProductAPI, // Newly imported
  getVendorProductsAPI,
  deleteProductAPI,
  getColorsAPI,
} from '../../services/api'

// Fetch product categories
export const fetchProductCategories = createAsyncThunk(
  'product/fetchProductCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getProductCategoriesAPI()
      return response.data.data // Assuming the categories are inside `data`
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Error fetching categories'
      )
    }
  }
)

// Fetch occasions
export const fetchOccasions = createAsyncThunk(
  'product/fetchOccasions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getOccasionsAPI()
      return response.data.data // Assuming the occasions are inside `data`
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Error fetching occasions'
      )
    }
  }
)

// Create a new product
export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (productData, { rejectWithValue }) => {
    try {
      const response = await createProductAPI(productData)
      return response.data.data // Assuming the product data is inside `data`
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Error creating product'
      return rejectWithValue(errorMessage)
    }
  }
)

// **Fetch product details**
export const fetchProductDetails = createAsyncThunk(
  'product/fetchProductDetails',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await fetchProductAPI(productId)

      return response.data.data // Assuming the product data is inside `data`
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Error fetching product details'

      return rejectWithValue(errorMessage)
    }
  }
)

// **Update product details**
export const updateProductDetails = createAsyncThunk(
  'product/updateProductDetails',
  async ({ productId, data }, { rejectWithValue }) => {
    try {
      const response = await updateProductAPI(productId, data)

      return response.data.data // Assuming the product data is inside `data`
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Error updating product'

      return rejectWithValue(errorMessage)
    }
  }
)

// export const fetchSingleProductDetail = createAsyncThunk(
//   'product/fetchSingleProductDetail',
//   async (productId, { rejectWithValue }) => {
//     try {
//       const response = await getSingleProductDetailAPI(productId);
//       if (response.data.status === 1) {
//         return response.data.data; // Assuming the product data is inside `data`
//       } else {
//         return rejectWithValue(response.data.message || 'Error fetching product details');
//       }
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error fetching product details');
//     }
//   }
// );

// productThunks.js

// src/redux/product/productThunks.j

export const fetchVendorProducts = createAsyncThunk(
  'product/fetchVendorProducts',
  async (
    { limit = 20, page, vendorId, search, sortBy = "createTime" }, 
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        limit,
        page,
        vendorId,
        search,
        sortBy, 
      };
      const response = await getVendorProductsAPI(payload);
      if (response.data.status === 1) {
        return response.data; // Return the entire data object
      } else {
      
        return rejectWithValue(response.data.message || 'Failed to fetch products');
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Error fetching products'
      );
    }
  }
);


export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await deleteProductAPI(productId)
      return response.data
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Error deleting product'
      )
    }
  }
)

// **Fetch colors**
export const fetchColors = createAsyncThunk(
  'product/fetchColors',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getColorsAPI()
      return response.data.data // Adjust based on your API response structure
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Error fetching colors'
      )
    }
  }
)
