import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import { LanguageContext } from '../../context/LanguageContext';
import { FaUserCircle, FaBars } from 'react-icons/fa';
import { logout } from '../../redux/auth/authSlice';

const Header = ({ isSidebarOpen, toggleSidebar, showSidebar }) => {
  const { i18n, t } = useTranslation('header');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { language, toggleLanguage } = useContext(LanguageContext);

  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const handleLanguageChange = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    toggleLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/signin');
  };

  return (
    <header
      className="fixed top-0 z-30 flex items-center justify-between h-16 px-4 transition-all duration-300 bg-white border-b border-gray-200 shadow-sm"
      style={{
        left: showSidebar ? (isSidebarOpen ? '16rem' : '4rem') : '0',
        width: showSidebar ? (isSidebarOpen ? 'calc(100% - 16rem)' : 'calc(100% - 4rem)') : '100%',
      }}
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      {/* Left side */}
      <div className="flex items-center">
        {/* Toggle Sidebar Button */}
        {showSidebar && (
          <button onClick={toggleSidebar} className="text-gray-700 focus:outline-none">
            <FaBars size={20} />
          </button>
        )}
        <Link to="/">
          <span className={`text-xl font-extrabold text-[#06550f] ${isArabic ? 'mr-4' : 'ml-4'}`}>
            {t('siteTitle')}
          </span>
        </Link>
      </div>

      {/* Right side */}
      <nav className="flex items-center gap-4 space-x-4">
        {/* Language Switcher */}
        <button
          onClick={handleLanguageChange}
          className="px-2 py-1 text-sm font-medium text-gray-700 transition bg-gray-100 rounded-md hover:bg-gray-200 md:px-4 md:py-2"
        >
          {language === 'en' ? t('languageSwitcher.switchToArabic') : t('languageSwitcher.switchToEnglish')}
        </button>

        {/* User Profile */}
        {isLoggedIn && (
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 md:px-4 md:py-2">
              <FaUserCircle className={`w-5 h-5 ${isArabic ? 'ml-2' : 'mr-2'}`} />
              <span className="hidden md:block">{user?.name || t('labels.user')}</span>
            </Menu.Button>

            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg">
                <div className="py-1">
                  <Menu.Item
                    as="span"
                    className="block px-4 py-2 text-sm text-gray-700 cursor-default"
                  >
                    {user?.email}
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    onClick={handleLogout}
                    className={({ active }) =>
                      `block w-full text-left px-4 py-2 text-sm ${
                        active ? 'bg-gray-100' : ''
                      } text-gray-700`
                    }
                  >
                    {t('buttons.logout')}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </nav>
    </header>
  );
};

export default Header;
