// src/components/Product/ProductSizeColorPage.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProductDetails,
  fetchProductDetails,
  fetchColors,
} from '../../redux/product/productThunks';
import CircularLoader from '../Shared/CircularLoader';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ProductSizeColorPage = ({
  onNext,
  onPrev,
  updateProductData,
  productData,
  productId,
}) => {
  const { t, i18n } = useTranslation('productSizeColorPage');
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const dispatch = useDispatch();

  const safeJoin = (arr, separator = ', ') => {
    if (Array.isArray(arr)) {
      return arr.join(separator);
    }
    console.warn('Attempted to join non-array:', arr);
    return '';
  };

  const {
    product,
    isLoading: isProductLoading,
    error: productError,
    colors,
  } = useSelector((state) => state.product);
  const { isLoading: isStoreLoading, error: storeError } = useSelector(
    (state) => state.store
  );

  const [sizesInput, setSizesInput] = useState('');
  const [selectedColors, setSelectedColors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const [hasFetched, setHasFetched] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);

  useEffect(() => {
    if (productData) {
      if (typeof productData.sizes === 'string') {
        const sizesArray = productData.sizes
          .split(',')
          .map((size) => size.trim().toUpperCase())
          .filter(Boolean);
        setSizesInput(sizesArray.join(', '));
      } else if (Array.isArray(productData.sizes)) {
        const upperSizes = productData.sizes.map((size) => size.toUpperCase());
        setSizesInput(safeJoin(upperSizes));
      } else {
        setSizesInput('');
      }

      if (Array.isArray(productData.colors)) {
        const strippedColors = productData.colors.map(({ en, value }) => ({
          en,
          value,
        }));
        setSelectedColors(strippedColors);
      } else if (typeof productData.colors === 'string') {
        const colorsArray = productData.colors
          .split(',')
          .map((color) => ({ en: color.trim(), value: '' }));
        setSelectedColors(colorsArray);
      } else {
        setSelectedColors([]);
      }
    }
  }, [productData]);

  useEffect(() => {
    if (productId && !hasFetched) {
      dispatch(fetchProductDetails(productId));
      setHasFetched(true);
    }
  }, [dispatch, productId, hasFetched]);

  useEffect(() => {
    if (!colors.length) {
      dispatch(fetchColors());
    }
  }, [dispatch, colors.length]);

  useEffect(() => {
    if (productId && product && !hasUpdated) {
      if (typeof product.sizes === 'string') {
        const sizesArray = product.sizes
          .split(',')
          .map((size) => size.trim().toUpperCase())
          .filter(Boolean);
        setSizesInput(sizesArray.join(', '));
      } else if (Array.isArray(product.sizes)) {
        const upperSizes = product.sizes.map((size) => size.toUpperCase());
        setSizesInput(safeJoin(upperSizes));
      } else {
        setSizesInput('');
      }

      if (Array.isArray(product.colors)) {
        const strippedColors = product.colors.map(({ en, value }) => ({
          en,
          value,
        }));
        setSelectedColors(strippedColors);
      } else {
        setSelectedColors([]);
      }

      updateProductData({
        sizes:
          typeof product.sizes === 'string'
            ? product.sizes.toUpperCase()
            : safeJoin(product.sizes.map((size) => size.toUpperCase())),
        colors: Array.isArray(product.colors)
          ? product.colors.map(({ en, value }) => ({ en, value }))
          : [],
      });

      setHasUpdated(true);
    }
  }, [product, productId, hasUpdated, updateProductData]);

  const handleSizesInputChange = (e) => {
    const upperValue = e.target.value.toUpperCase();
    setSizesInput(upperValue);
  };

  const handleColorSelect = (color) => {
    if (selectedColors.find((c) => c.en === color.en)) {
      setSelectedColors(selectedColors.filter((c) => c.en !== color.en));
    } else {
      setSelectedColors([
        ...selectedColors,
        { en: color.en, value: color.value },
      ]);
    }
  };

  const handleRemoveColor = (colorEn) => {
    setSelectedColors(selectedColors.filter((c) => c.en !== colorEn));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const sizes = sizesInput
      .split(',')
      .map((size) => size.trim().toUpperCase())
      .filter(Boolean);
    if (sizes.length === 0 && selectedColors.length === 0) {
      setError(t('errors.noSizesOrColors'));
      return;
    }

    updateProductData({ sizes: sizes.join(', '), colors: selectedColors });

    if (!productId) {
      setError(t('errors.productIdMissing'));
      return;
    }

    const updatedProductData = {
      productId,
      data: {
        sizes,
        colors: selectedColors,
      },
    };

    updatedProductData.data.sizes = updatedProductData.data.sizes.map((size) =>
      size.toUpperCase()
    );

    setIsSaving(true);

    try {
      const actionResult = await dispatch(
        updateProductDetails(updatedProductData)
      );
      if (updateProductDetails.fulfilled.match(actionResult)) {
        onNext();
      } else {
        throw new Error(
          actionResult.payload?.message ||
            t('errors.updateProductFailed')
        );
      }
    } catch (err) {
      setError(`${t('errors.updateProductError')}: ${err.message || err}`);
    } finally {
      setIsSaving(false);
    }
  };

  const isDisabled = isSaving || storeError;

  return (
    <div className="font-rubik" dir={isArabic ? 'rtl' : 'ltr'}>
      <h2 className="mb-6 text-2xl font-semibold text-dark-green">
        {productId ? t('headings.editProduct') : t('headings.createProduct')} - {t('headings.sizesColors')}
      </h2>

      {(isProductLoading || isStoreLoading) && <CircularLoader />}

      {(productError || storeError) && (
        <div className="p-4 text-red-500 bg-red-100 border border-red-400 rounded-md">
          {productError || storeError}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Sizes Input */}
        <div className="mb-6">
          <label className="block text-lg font-medium text-dark-green">
            {t('labels.sizes')}
          </label>
          <input
            type="text"
            value={sizesInput}
            onChange={handleSizesInputChange}
            placeholder={t('placeholders.sizes')}
            className="block w-full p-3 mt-1 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dark-green"
            disabled={isDisabled}
          />
        </div>

        {/* Colors Selection */}
        <div className="mb-6">
          <label className="block text-lg font-medium text-dark-green">
            {t('labels.colors')}
          </label>
          <div className="grid grid-cols-2 gap-4 mt-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
            {colors.length > 0 ? (
              colors.map((color) => (
                <button
                  key={color._id}
                  type="button"
                  onClick={() => handleColorSelect(color)}
                  className={`flex items-center p-2 border rounded-md focus:outline-none ${
                    selectedColors.find((c) => c.en === color.en)
                      ? 'bg-gray-400 text-white'
                      : 'bg-white text-dark-green'
                  } hover:bg-gray-300 hover:text-white transition-colors duration-200`}
                  disabled={isDisabled}
                >
                  <span
                    className="w-6 h-6 mr-2 border rounded-full"
                    style={{ backgroundColor: color.value }}
                  ></span>
                  {color.en} / {color.ar}
                </button>
              ))
            ) : (
              <p className="text-gray-500">{t('labels.loadingColors')}</p>
            )}
          </div>
        </div>

        {/* Display Selected Colors with Remove Option */}
        {selectedColors.length > 0 && (
          <div className="mb-6">
            <h3 className="mb-2 text-lg font-medium text-dark-green">
              {t('labels.selectedColors')}:
            </h3>
            <div className="flex flex-wrap gap-2">
              {selectedColors.map((color) => (
                <div
                  key={color.en}
                  className="flex items-center p-2 bg-gray-200 rounded-md"
                >
                  <span
                    className="w-4 h-4 mr-2 border rounded-full"
                    style={{ backgroundColor: color.value }}
                  ></span>
                  <span>{color.en}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveColor(color.en)}
                    className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                    aria-label={t('buttons.removeColor')}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="p-4 text-red-500 bg-red-100 border border-red-400 rounded-md">
            {error}
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={onPrev}
            className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-gray-700"
            disabled={isDisabled}
          >
            {t('buttons.previous')}
          </button>
          <button
            type="submit"
            className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-dark-green"
            disabled={isDisabled}
          >
            {isSaving ? t('buttons.saving') : t('buttons.saveAndNext')}
          </button>
        </div>
      </form>
    </div>
  );
};

ProductSizeColorPage.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  updateProductData: PropTypes.func.isRequired,
  productData: PropTypes.shape({
    sizes: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    colors: PropTypes.arrayOf(
      PropTypes.shape({
        en: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  productId: PropTypes.string.isRequired,
};

export default ProductSizeColorPage;
