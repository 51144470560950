// src/components/Store/StoreSelectionModal.js

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FiSearch } from 'react-icons/fi';

const StoreSelectionModal = ({ stores, onSelect, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStoreId, setSelectedStoreId] = useState('');


  // Filter stores based on search term
  const filteredStores = useMemo(() => {
    return stores.filter((store) =>
      `${store.nameBusiness} ${store.nameBusinessAr}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [stores, searchTerm]);

  const handleSelect = () => {
    const selectedStore = stores.find((store) => store._id === selectedStoreId);
    if (selectedStore) {
      onSelect(selectedStore);
    } else {
      alert('Please select a store.');
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-2xl font-bold text-center">Select a Store</h2>
        
        {/* Search Bar */}
        <div className="relative mb-4">
          <FiSearch className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search stores..."
            className="w-full py-2 pl-10 pr-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        {/* Store Dropdown */}
        <div className="mb-4">
          <label htmlFor="storeSelect" className="block mb-1 text-sm font-medium text-gray-700">
            Stores
          </label>
          {filteredStores.length > 0 ? (
            <select
              id="storeSelect"
              value={selectedStoreId}
              onChange={(e) => setSelectedStoreId(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="">-- Select a Store --</option>
              {filteredStores.map((store) => (
                <option key={store._id} value={store._id}>
                  {store.nameBusiness} / {store.nameBusinessAr}
                </option>
              ))}
            </select>
          ) : (
            <p className="text-gray-500">No stores available.</p>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSelect}
            className="px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
            disabled={!selectedStoreId}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};

StoreSelectionModal.propTypes = {
  stores: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default StoreSelectionModal;
