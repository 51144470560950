// src/components/FAQSection.js

import React, { useState } from 'react';

const FAQSection = () => {
  const faqs = [
    {
      question: 'How Can I Sell On Markaz?',
      answer:
        'To start selling on Markaz, visit the Markaz Seller Center and create a new account using your phone number. Complete the sign-up process by verifying your email, adding your pickup address, and uploading the required documents for verification. Once your store is approved, add your first product, and you\'re ready to sell! You can also customize your store by adding your logo, cover, and banners through the Store Builder tool.',
    },
    {
      question: 'What Categories Can I Sell on Markaz?',
      answer:
        'With multiple categories on Markaz—ranging from fashion, lifestyle, digital goods, FMCG, and more—you’ll find the perfect fit for your products. However, it\'s essential to avoid listing counterfeit, dangerous, or prohibited items and those restricted by law or cultural norms. [Click Here](#) to learn more.',
    },
    {
      question: 'How Much Commission Does Markaz Charge?',
      answer:
        'Opening a shop on Markaz is free! However, a 15% commission is deducted from each order\'s payment. This rate applies across all product categories, ensuring transparency and consistency. You can find out more about commissions [here](#).',
    },
    {
      question: 'What is the Payment Policy of Markaz?',
      answer:
        'Seller payments are based on orders marked as "Delivered" to the customer in the Seller Center. Payments for delivered products are settled weekly. In case public holidays/weekends fall upon these dates, the payment date will be released on the first upcoming business day. You can explore Markaz\'s detailed Payment Policy and find answers to frequently asked questions [here](#).',
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close if the same question is clicked
    } else {
      setOpenIndex(index); // Open the clicked question
    }
  };

  return (
    <section className="py-32 text-white bg-gray-900 ">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="mb-12 text-4xl font-extrabold text-center text-green-500">
          Frequently Asked Questions
        </h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="pb-4 border-b border-gray-700">
              <button
                onClick={() => toggleFAQ(index)}
                className="flex items-center justify-between w-full text-left focus:outline-none"
                aria-expanded={openIndex === index}
                aria-controls={`faq-${index}`}
              >
                <span className="text-xl font-semibold">{faq.question}</span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-300 ${
                    openIndex === index ? 'rotate-180' : 'rotate-0'
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {openIndex === index && (
                <div
                  id={`faq-${index}`}
                  className="mt-4 text-lg text-gray-300"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                ></div>
              )}
            </div>
          ))}
        </div>
        <div className="mt-12 text-center">
          <h3 className="mb-4 text-3xl font-bold text-white">What are you waiting for?</h3>
          <p className="mb-6 text-lg text-gray-400">Start selling with Markaz today.</p>
          <a
            href="/signup"
            className="inline-block px-6 py-3 text-white transition duration-300 bg-green-500 rounded-lg shadow-md hover:bg-green-600"
          >
            GET STARTED
          </a>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
