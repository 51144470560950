// src/components/Product/ProductImagePage.js

import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateProductDetails,
  fetchProductDetails,
} from '../../redux/product/productThunks'
import { uploadImageAPI } from '../../services/api'
import CircularLoader from '../Shared/CircularLoader'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const ProductImagePage = ({ onPrev, onNext, productData, productId }) => {
  const { t, i18n } = useTranslation('productImagePage')
  const currentLanguage = i18n.language || 'en'
  const isArabic = currentLanguage === 'ar'

  const dispatch = useDispatch()

  const { product, isLoading, error } = useSelector((state) => state.product)
  const { isLoading: isStoreLoading, error: storeError } = useSelector(
    (state) => state.store
  )

  const [specificProductInfo, setSpecificProductInfo] = useState([])
  const [sizeChart, setSizeChart] = useState(null)
  const [sizeChartPreview, setSizeChartPreview] = useState(null)
  const [sizeChartError, setSizeChartError] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [warningMessages, setWarningMessages] = useState([]) // New state for warnings
  const [isSaving, setIsSaving] = useState(false)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [isUploadingSizeChart, setIsUploadingSizeChart] = useState(false)

  const MARKAZ_COMMISSION = 15 // 15% commission from Markaz

  const currentImageUploadIndex = useRef(null)

  const generateSpecificProductInfo = useCallback(() => {
    const sizes = productData.sizes
      ? productData.sizes.split(',').map((s) => s.trim().toUpperCase())
      : ['']
    const colors =
      productData.colors && productData.colors.length > 0
        ? productData.colors
        : [{ en: '', ar: '', value: '#000000' }] // Default color if none provided

    const info = []

    sizes.forEach((size) => {
      colors.forEach((color) => {
        info.push({
          id: '',
          size: size || '',
          color: { ...color },
          wcPrice: 0.0,
          discountPercentage: 0.0,
          wcDiscountPrice: 0.0,
          price: 0.0,
          discountPrice: 0.0,
          currency: 'KWD',
          images: [],
          stock: 0,
          comissionPercentage: MARKAZ_COMMISSION,
          position: 1,
        })
      })
    })

    setSpecificProductInfo(info)
  }, [productData])

  useEffect(() => {
    if (
      product &&
      product.specificProductInfo &&
      product.specificProductInfo.length > 0
    ) {
      const clonedInfo = product.specificProductInfo.map((info) => ({
        ...info,
        color: { ...info.color },
        images: info.images ? info.images.map((image) => ({ ...image })) : [],
        wcPrice: info.wcPrice ?? 0.0,
        discountPercentage: info.discountPercentage ?? 0.0,
        stock: info.stock ?? 0,
        wcDiscountPrice: info.wcDiscountPrice ?? 0.0,
        price: info.price ?? 0.0,
        discountPrice: info.discountPrice ?? 0.0,
        comissionPercentage: info.comissionPercentage ?? MARKAZ_COMMISSION,
        currency: info.currency ?? 'KWD',
        position: info.position ?? 1,
      }))
      setSpecificProductInfo(clonedInfo)
      setSizeChart(product.sizeChart || null)
      setSizeChartPreview(product.sizeChart || null)
    } else {
      generateSpecificProductInfo()
    }
  }, [product, generateSpecificProductInfo])

  useEffect(() => {
    if (productId) {
      dispatch(fetchProductDetails(productId))
    }
  }, [dispatch, productId])

  const handleInfoChange = useCallback((index, field, value) => {
    const parsedValue =
      field === 'wcPrice' ? parseFloat(value) || 0.0 : parseFloat(value) || 0.0

    setSpecificProductInfo((prevInfo) =>
      prevInfo.map((info, idx) => {
        if (idx !== index) return info

        const updatedField = { ...info, [field]: parsedValue }

        // Recalculate dependent fields if 'wcPrice' or 'discountPercentage' are updated
        if (['wcPrice', 'discountPercentage'].includes(field)) {
          const discountPercentage = updatedField.discountPercentage || 0
          const wcPrice = updatedField.wcPrice || 0.0
          const discountAmount = (wcPrice * discountPercentage) / 100
          const wcDiscountPrice = parseFloat(
            (wcPrice - discountAmount).toFixed(3)
          )
          updatedField.wcDiscountPrice = wcDiscountPrice

          const commission = parseFloat(
            (
              (wcDiscountPrice * updatedField.comissionPercentage) /
              100
            ).toFixed(3)
          )
          const price = parseFloat((wcDiscountPrice + commission).toFixed(3))
          updatedField.price = price
        }

        return updatedField
      })
    )
  }, [])
  const handleWcPriceIncrement = (index) => {
    const newPrice = parseFloat(
      (specificProductInfo[index].wcPrice + 0.25).toFixed(3)
    )
    handleInfoChange(index, 'wcPrice', newPrice)
  }

  const handleWcPriceDecrement = (index) => {
    let newPrice = parseFloat(
      (specificProductInfo[index].wcPrice - 0.25).toFixed(3)
    )
    if (newPrice < 0) newPrice = 0.0
    handleInfoChange(index, 'wcPrice', newPrice)
  }

  const handleDiscountIncrement = (index) => {
    const current = specificProductInfo[index].discountPercentage || 0
    const newDiscount = current + 5
    if (newDiscount > 100) return
    handleInfoChange(index, 'discountPercentage', newDiscount)
  }

  const handleDiscountDecrement = (index) => {
    const current = specificProductInfo[index].discountPercentage || 0
    const newDiscount = current - 5
    if (newDiscount < 0) return
    handleInfoChange(index, 'discountPercentage', newDiscount)
  }

  const handleSizeChartUpload = async (e) => {
    const file = e.target.files[0]
    if (file) {
      setIsUploadingSizeChart(true)
      setSizeChartError('')
      const reader = new FileReader()
      reader.onloadend = () => setSizeChartPreview(reader.result)
      reader.readAsDataURL(file)

      try {
        const response = await uploadImageAPI(file)
        if (response.data.status === 1) {
          const sizeChartUrl = response.data.message
          setSizeChart(sizeChartUrl)
        } else {
          setSizeChartError(t('errors.sizeChartUpload'))
        }
      } catch (error) {
        console.error('Error uploading size chart image:', error)
        setSizeChartError(t('errors.sizeChartUpload'))
      } finally {
        setIsUploadingSizeChart(false)
      }
    }
  }

  const handleImageUpload = useCallback(
    async (index, files) => {
      if (!files || files.length === 0) return
      setIsUploadingImage(true)
      const uploadedImages = []

      const fileArray = Array.isArray(files) ? files : Array.from(files)

      for (let i = 0; i < fileArray.length; i++) {
        const file = fileArray[i]
        try {
          const response = await uploadImageAPI(file)
          if (response.data.status === 1) {
            const imageUrl = response.data.message
            uploadedImages.push({
              url: imageUrl,
              position: (specificProductInfo[index].images.length || 0) + i + 1,
              _id: response.data.imageId,
            })
          } else {
            alert(t('errors.imageUpload'))
          }
        } catch (error) {
          console.error('Error uploading image:', error)
          alert(t('errors.imageUpload'))
        }
      }

      setSpecificProductInfo((prevInfo) =>
        prevInfo.map((info, idx) => {
          if (idx !== index) return info
          return { ...info, images: [...info.images, ...uploadedImages] }
        })
      )

      setIsUploadingImage(false)
    },
    [specificProductInfo, t]
  )

  const handleImageRemove = (infoIndex, imageIndex) => {
    setSpecificProductInfo((prevInfo) =>
      prevInfo.map((info, idx) => {
        if (idx !== infoIndex) return info
        const newImages = info.images.filter(
          (_, imgIdx) => imgIdx !== imageIndex
        )
        return { ...info, images: newImages }
      })
    )
  }

  const handleCopyPriceToAll = (index) => {
    const sourceInfo = specificProductInfo[index]
    setSpecificProductInfo((prevInfo) =>
      prevInfo.map((info, idx) => {
        if (idx !== index) {
          return {
            ...info,
            wcPrice: sourceInfo.wcPrice,
            discountPercentage: sourceInfo.discountPercentage,
            wcDiscountPrice: sourceInfo.wcDiscountPrice,
            price: sourceInfo.price,
          }
        }
        return info
      })
    )
  }

  const handleCopyStockToAll = (index) => {
    const sourceInfo = specificProductInfo[index]
    setSpecificProductInfo((prevInfo) =>
      prevInfo.map((info, idx) => {
        if (idx !== index) {
          return {
            ...info,
            stock: sourceInfo.stock,
          }
        }
        return info
      })
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrorMessage(null)
    setWarningMessages([])

    const warnings = []

    for (let i = 0; i < specificProductInfo.length; i++) {
      const info = specificProductInfo[i]
      if (info.stock === 0) {
        warnings.push(
          t('warnings.noStock', { size: info.size, color: info.color.en })
        )
        // You can set a default value or leave it as is
      }
      if (info.price === 0.0) {
        setErrorMessage(
          t('errors.enterPrice', { size: info.size, color: info.color.en })
        )
        return
      }
      if (!info.currency) {
        info.currency = 'KWD'
      }
      if (!info.comissionPercentage) {
        info.comissionPercentage = MARKAZ_COMMISSION
      }
      if (!info.images) {
        info.images = []
      }
    }

    if (warnings.length > 0) {
      setWarningMessages(warnings)
    }

    const updatedProductData = {
      productId,
      data: {
        sizeChart,
        specificProductInfo,
      },
    }

    setIsSaving(true)

    try {
      const actionResult = await dispatch(
        updateProductDetails(updatedProductData)
      )
      if (updateProductDetails.fulfilled.match(actionResult)) {
        onNext()
      } else {
        throw new Error(actionResult.payload || t('errors.updateProduct'))
      }
    } catch (error) {
      console.error('Update Product Error:', error)
      setErrorMessage(`${t('errors.updateProductError')}: ${error.message}`)
    } finally {
      setIsSaving(false)
    }
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (currentImageUploadIndex.current === null) return

      handleImageUpload(currentImageUploadIndex.current, acceptedFiles)
    },
    [handleImageUpload]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    multiple: true,
  })

  if (isLoading || isStoreLoading) {
    return <CircularLoader />
  }

  if (error || storeError) {
    return (
      <div className="p-6 text-red-500">
        <p>
          {t('errors.fetchProduct')}: {error || storeError}
        </p>
      </div>
    )
  }

  return (
    <div className="font-rubik" dir={isArabic ? 'rtl' : 'ltr'}>
      <h2 className="mb-6 text-2xl font-semibold text-dark-green">
        {t('headings.imagesDetails')}
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Size Chart Upload */}
        <div className="mb-6">
          <label className="block mb-2 text-lg font-medium text-dark-green">
            {t('labels.sizeChart')} ({t('labels.optional')})
          </label>
          <div className="flex items-center">
            {sizeChartPreview && (
              <img
                src={sizeChartPreview}
                alt={t('labels.sizeChartPreview')}
                className={`object-cover w-32 h-32 ${
                  isArabic ? 'ml-4' : 'mr-4'
                } border rounded-md`}
              />
            )}
            {/* Custom Styled File Input */}
            <label className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-md cursor-pointer hover:bg-blue-700">
              <FaCloudUploadAlt className="mr-2" />
              <span>{t('buttons.chooseFile')}</span>
              <input
                type="file"
                accept="image/*"
                onChange={handleSizeChartUpload}
                className="hidden"
              />
            </label>
          </div>
          {isUploadingSizeChart && (
            <p className="mt-2 text-sm text-gray-500">
              {t('labels.uploading')}
            </p>
          )}
          {sizeChartError && (
            <p className="mt-2 text-sm text-red-500">{sizeChartError}</p>
          )}
        </div>

        {/* Iterate over each size and color combination */}
        {specificProductInfo.map((info, index) => (
          <div key={index} className="p-4 mb-6 border rounded-md">
            {/* Size and Color Labels */}
            <div className="flex items-center mb-4">
              {info.size && (
                <span
                  className={`text-lg font-medium ${
                    isArabic ? 'ml-4' : 'mr-4'
                  }`}
                >
                  {t('labels.size')}: {info.size}
                </span>
              )}
              {info.color && info.color.en && (
                <span
                  className={`text-lg font-medium ${
                    isArabic ? 'ml-4' : 'mr-4'
                  }`}
                >
                  {t('labels.color')}: {info.color.en}
                </span>
              )}
            </div>

            {/* Price and Discount Section */}
            <div className="flex flex-wrap gap-4 mb-4">
              {/* Add Price */}
              <div className="mb-4">
                <label className="block mb-1 font-medium text-dark-green">
                  {t('labels.addPrice')} ({t('labels.currency')})
                </label>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() => handleWcPriceDecrement(index)}
                    className="px-2 py-1 text-white bg-gray-700 rounded-l-md hover:bg-gray-600 focus:outline-none"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    step="0.250"
                    value={info.wcPrice.toFixed(3)}
                    onChange={(e) =>
                      handleInfoChange(index, 'wcPrice', e.target.value)
                    }
                    className="w-24 p-2 text-center border-t border-b border-gray-300 focus:outline-none"
                  />
                  <button
                    type="button"
                    onClick={() => handleWcPriceIncrement(index)}
                    className="px-2 py-1 text-white bg-gray-700 rounded-r-md hover:bg-gray-600 focus:outline-none"
                  >
                    +
                  </button>
                </div>
              </div>

              {/* Discount Percentage */}
              <div className="mb-4">
                <label className="block mb-1 font-medium text-dark-green">
                  {t('labels.discountPercentage')} (%)
                </label>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() => handleDiscountDecrement(index)}
                    className="px-2 py-1 text-white bg-gray-700 rounded-l-md hover:bg-gray-600 focus:outline-none"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={info.discountPercentage.toFixed(0)}
                    readOnly
                    className="w-16 p-2 text-center bg-gray-100 border-t border-b border-gray-300 cursor-not-allowed"
                  />
                  <button
                    type="button"
                    onClick={() => handleDiscountIncrement(index)}
                    className="px-2 py-1 text-white bg-gray-700 rounded-r-md hover:bg-gray-600 focus:outline-none"
                  >
                    +
                  </button>
                </div>
              </div>

              {/* Total Price */}
              <div className="mb-4">
                <label className="block mb-1 font-medium text-dark-green">
                  {t('labels.totalPrice')} ({t('labels.currency')})
                </label>
                <input
                  type="number"
                  step="0.001"
                  value={info.wcDiscountPrice.toFixed(3)}
                  readOnly
                  className="w-24 p-2 bg-gray-100 border rounded-md"
                />
              </div>

              {/* Total Price (After Markaz Commission) */}
              <div className="mb-4">
                <label className="block mb-1 font-medium text-dark-green">
                  {t('labels.totalPriceAfterCommission')} (
                  {t('labels.currency')})
                </label>
                <input
                  type="number"
                  step="0.001"
                  value={info.price.toFixed(3)}
                  readOnly
                  className="w-32 p-2 bg-gray-100 border rounded-md"
                />
              </div>
            </div>

            {/* Copy Price to All Button */}
            <div className="flex mb-4">
              <button
                type="button"
                onClick={() => handleCopyPriceToAll(index)}
                className="px-4 py-2 text-sm text-white bg-gray-800 rounded-md hover:bg-dark-green focus:outline-none focus:ring-2 focus:ring-dark-green"
              >
                {t('buttons.copyPriceToAll')}
              </button>
            </div>

            {/* Images Section */}
            <div className="mb-4">
              <label className="block mb-2 text-lg font-medium text-dark-green">
                {t('labels.images')} ({t('labels.optional')})
              </label>

              {/* Enhanced Drag-and-Drop Area */}
              <div
                {...getRootProps()}
                className={`flex flex-col items-center justify-center w-full p-6 mb-4 border-2 border-dashed rounded-md cursor-pointer transition-all duration-300 ease-in-out ${
                  isDragActive
                    ? 'border-green-500 bg-gradient-to-br from-green-50 to-green-100'
                    : 'border-gray-300 bg-white hover:border-gray-400 hover:bg-gray-50'
                }`}
                onClick={() => {
                  currentImageUploadIndex.current = index // Set the current index for image upload
                }}
                onPaste={(e) => {
                  const items = e.clipboardData.items
                  const files = []
                  for (let i = 0; i < items.length; i++) {
                    if (
                      items[i].kind === 'file' &&
                      items[i].type.indexOf('image') !== -1
                    ) {
                      const file = items[i].getAsFile()
                      files.push(file)
                    }
                  }
                  if (files.length > 0) {
                    handleImageUpload(index, files)
                  }
                }}
                aria-label={t('labels.imageUploadDropzone')}
              >
                <input {...getInputProps()} />
                <FaCloudUploadAlt className="w-12 h-12 text-gray-400" />
                <p className="mt-4 text-sm text-center text-gray-500">
                  {t('labels.dragDropImages')}
                </p>
                <p className="mt-2 text-xs text-gray-400">
                  {t('labels.imageUploadGuidelines')}
                </p>
              </div>

              {/* Render each image with preview and delete button */}
              <div className="flex flex-wrap">
                {info.images &&
                  info.images.map((image, imgIndex) => (
                    <div
                      key={image._id || `${image.url}-${imgIndex}`}
                      className={`relative mb-4 ${
                        isArabic ? 'ml-4' : 'mr-4'
                      } group`}
                    >
                      <img
                        src={image.url}
                        alt={`${t('labels.size')} ${info.size}, ${t(
                          'labels.color'
                        )} ${info.color.en}`}
                        className="object-cover w-24 h-24 transition-transform duration-200 transform border rounded-md shadow-sm group-hover:scale-105"
                        onError={(e) => {
                          e.target.onerror = null
                          e.target.src =
                            'https://via.placeholder.com/96?text=Image+Not+Found'
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => handleImageRemove(index, imgIndex)}
                        className="absolute p-1 text-white transition-opacity duration-200 ease-in-out bg-red-600 rounded-full opacity-0 top-1 right-1 group-hover:opacity-100 focus:outline-none"
                        title={t('buttons.removeImage')}
                        aria-label={`${t('buttons.removeImage')} ${
                          imgIndex + 1
                        }`}
                      >
                        &times;
                      </button>
                    </div>
                  ))}

                {/* Custom Styled File Input */}
                <label className="flex items-center gap-2 px-4 py-2 mt-2 text-white transition-colors duration-200 ease-in-out bg-blue-600 rounded-md cursor-pointer hover:bg-blue-700">
                  <FaCloudUploadAlt className="mr-2" />
                  <span>{t('buttons.uploadImages')}</span>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleImageUpload(index, e.target.files)}
                    className="hidden"
                  />
                </label>
              </div>
              {isUploadingImage && (
                <p className="mt-2 text-sm text-gray-500">
                  {t('labels.uploadingImages')}
                </p>
              )}
            </div>

            {/* Stock Section with Copy Stock to All Button */}
            <div className="mb-4">
              <label className="block mb-1 font-medium text-dark-green">
                {t('labels.stock')}
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  value={info.stock}
                  onChange={(e) =>
                    handleInfoChange(index, 'stock', e.target.value)
                  }
                  className="w-24 p-2 border rounded-md"
                  min="0"
                />
                <button
                  type="button"
                  onClick={() => handleCopyStockToAll(index)}
                  className={`px-4 py-2 text-sm text-white bg-gray-800 rounded-md hover:bg-dark-green focus:outline-none focus:ring-2 focus:ring-dark-green ${
                    isArabic ? 'mr-4' : 'ml-4'
                  }`}
                >
                  {t('buttons.copyStockToAll')}
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* Error Message */}
        {errorMessage && (
          <div className="p-4 text-red-500 bg-red-100 border border-red-400 rounded-md">
            {errorMessage}
          </div>
        )}

        {/* Warning Messages */}
        {warningMessages && warningMessages.length > 0 && (
          <div className="p-4 text-yellow-700 bg-yellow-100 border border-yellow-400 rounded-md">
            {warningMessages.map((msg, idx) => (
              <p key={idx}>{msg}</p>
            ))}
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-between">
          {isArabic ? (
            <>
              <button
                type="submit"
                className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-dark-green"
                disabled={isSaving || isUploadingImage || isUploadingSizeChart}
              >
                {isSaving ? t('buttons.saving') : t('buttons.saveAndFinish')}
              </button>
              <button
                type="button"
                onClick={onPrev}
                className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-dark-green"
                disabled={isSaving || isUploadingImage || isUploadingSizeChart}
              >
                {t('buttons.previous')}
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={onPrev}
                className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-dark-green"
                disabled={isSaving || isUploadingImage || isUploadingSizeChart}
              >
                {t('buttons.previous')}
              </button>
              <button
                type="submit"
                className="px-6 py-3 text-lg font-semibold text-white bg-gray-900 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-dark-green"
                disabled={isSaving || isUploadingImage || isUploadingSizeChart}
              >
                {isSaving ? t('buttons.saving') : t('buttons.saveAndFinish')}
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

ProductImagePage.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  productData: PropTypes.shape({
    sizes: PropTypes.string,
    colors: PropTypes.arrayOf(
      PropTypes.shape({
        en: PropTypes.string,
        ar: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  productId: PropTypes.string.isRequired,
}

export default ProductImagePage
