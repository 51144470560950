import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStoreDetails } from '../../redux/store/storeThunks';
import MainScreen from '../../components/MainScreen/MainScreen';
import StoreInfo from '../../components/Dashboard/StoreInfo'; // Import for rendering multiple stores
import CircularLoader from '../../components/Shared/CircularLoader';
import { useNavigate } from 'react-router-dom';
import homeIcon from '../../assets/Images/homeIcon.jpg';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation('home');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vendorId, setVendorId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // Local state for admin status

  const { store, isLoading: isLoadingStore, error: storeError } = useSelector((state) => state.store);

  // Fetch user data from local storage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      setVendorId(userData._id);
      // Check if the user is an admin based on their email
      setIsAdmin(userData.email === 'm.razaimtiaz@gmail.com'); // Replace with actual admin email
    }
  }, []);

  // Fetch store details when the vendorId is available
  useEffect(() => {
    if (vendorId) {
      dispatch(fetchStoreDetails(vendorId)); // Fetch store details using vendorId
    }
  }, [dispatch, vendorId]);

  // Handle loading state
  if (isLoadingStore) {
    return <CircularLoader />;
  }

  // Handle errors
  if (storeError) {
    return (
      <div className="p-6 text-red-500">
        {t('errors.fetchStoreData', { error: storeError })}
      </div>
    );
  }

  // If no store exists, show the "Create Store" button
  if (!store) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        <img src={homeIcon} alt={t('alt.storeIcon')} className="w-[10%] h-[10%]" />
        <h2 className="mt-2 mb-8 text-2xl font-extrabold text-gray-900">
          {t('titles.createStorePrompt')}
        </h2>
        <button
          onClick={() => navigate('/manage-store')}
          className="px-4 py-2 text-white bg-black rounded-md shadow"
        >
          {t('buttons.createStore')}
        </button>
      </div>
    );
  }

  // If user is admin and store is an array, render all stores
  if (isAdmin && Array.isArray(store)) {
    return (
      <div className="min-h-screen p-8 bg-gray-50">
        {store.map((storeItem) => (
          <StoreInfo key={storeItem._id} storeData={storeItem} isAdmin={isAdmin} />
        ))}
      </div>
    );
  }

  // For regular users or admin with a single store, render a single store
  const userStore = Array.isArray(store) ? store[0] : store;

  return (
    <div>
      <MainScreen storeData={userStore} />
    </div>
  );
};

export default Home;
