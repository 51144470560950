// redux/auth/authThunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  checkEmailExist,
  signIn,
  signUp,
  verifyEmailForgotPasswordAPI,
  verifyOtpAPI,
  changePasswordApi,
} from '../../services/api';

// Thunk to check if the email exists
export const checkEmailExistence = createAsyncThunk(
  'auth/checkEmailExistence',
  async (email, { rejectWithValue }) => {
    try {
      const response = await checkEmailExist(email);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error checking email');
    }
  }
);

// Thunk to handle SignIn
// Thunk to handle SignIn
// redux/auth/authThunks.js

export const signInUser = createAsyncThunk(
  'auth/signInUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await signIn(email, password);

      const { status, data, message, token } = response.data;

      if (status === 1) {
        // Store the token and user data
        return { user: data, token };
      } else {
        return rejectWithValue(message || 'Invalid email or password');
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Error signing in'
      );
    }
  }
);



// Thunk to handle SignUp
export const signUpUser = createAsyncThunk(
  'auth/signUpUser',
  async ({ email, password, name }, { rejectWithValue }) => {
    try {
      const response = await signUp(email, password, name);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error signing up');
    }
  }
);

// Thunk to handle Forgot Password Email Verification
export const verifyEmailForgotPassword = createAsyncThunk(
  'auth/verifyEmailForgotPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await verifyEmailForgotPasswordAPI(email);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error verifying email');
    }
  }
);

// Thunk to handle OTP Verification
export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async ({ id, code }, { rejectWithValue }) => {
    try {
      const response = await verifyOtpAPI(id, code);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error verifying OTP');
    }
  }
);


// Thunk to handle password change
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await changePasswordApi(email, password);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error changing password');
    }
  }
);