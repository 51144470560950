// src/pages/Auth/OTPCodeScreen.js

import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOtp } from '../../redux/auth/authThunks';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import toast from 'react-hot-toast'; // Import the toast function
import { useTranslation } from 'react-i18next'; // Import useTranslation

const OTPCodeScreen = () => {
  const { t, i18n } = useTranslation(); // Access the translation function and i18n instance
  const currentLanguage = i18n.language || 'en';
  const isArabic = currentLanguage === 'ar';

  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(''); // For OTP validation error
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.auth); // Removed 'error' as we will handle OTP-specific errors here
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const verifyId = queryParams.get('verifyId'); // Assuming verifyId is part of the query params

  // Regular expression for validating OTP (5 digits)
  const otpRegex = /^\d{5}$/;

  // Handle OTP Submission
  const handleSubmit = () => {
    if (otpRegex.test(otp)) { // 5-digit OTP validation
      dispatch(verifyOtp({ id: verifyId, code: otp }))
        .unwrap()
        .then((response) => {
          if (response.status === 1) {
            toast.success(t('auth.otpCode.otpVerifiedSuccess')); // Success notification
            navigate(`/forgot-password-change?email=${email}`); // Navigate to password reset
          } else {
            setOtpError(t('auth.otpCode.otpVerifiedFailed')); // Set error message
            toast.error(t('auth.otpCode.otpVerifiedFailed')); // Show error notification
          }
        })
        .catch((err) => {
          const errorMsg = err || t('auth.otpCode.errorOccurred'); // Catch and show error
          setOtpError(errorMsg);
          toast.error(errorMsg); // Show error notification
        });
    } else {
      setOtpError(t('auth.otpCode.errorOtpLength')); // Error when OTP length is less than 5
      toast.error(t('auth.otpCode.errorOtpLength')); // Show error notification
    }
  };

  // Clear the OTP error when the user modifies the OTP input
  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
    setOtpError(''); // Clear error on change
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-4 bg-gray-50"
      dir={isArabic ? 'rtl' : 'ltr'} // Handle directionality
    >
      <div
        className={`w-full max-w-md p-8 bg-white rounded-lg shadow-lg ${
          isArabic ? 'font-cairo text-right' : 'font-rubik text-left'
        }`}
      >
        <h2 className="mb-6 text-2xl font-extrabold text-gray-900">
          {t('auth.otpCode.title')}
        </h2>

        <p className="mb-4 text-sm text-gray-600">
          {t('auth.otpCode.description')}
        </p>

        {/* OTP Input */}
        <div className="mt-6">
          <OtpInput
            value={otp}
            onChange={handleOtpChange} // Set OTP dynamically
            numInputs={5} // 5 inputs for OTP
            separator={<span>-</span>}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  width: '60px', // Set custom width
                  height: '60px', // Set custom height
                  textAlign: 'center',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  fontSize: '20px',
                  margin: '0 8px',
                }}
              />
            )}
            isInputNum // Restrict input to numbers
          />
          {/* Show error message if OTP is incorrect */}
          {otpError && <p className="mt-2 text-sm text-red-500">{otpError}</p>}
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isLoading ? 'bg-gray-500' : 'bg-black'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          >
            {isLoading ? t('auth.otpCode.submitting') : t('auth.otpCode.submitButton')}
          </button>
        </div>

        {/* "Need Help?" Link */}
        <div className="mt-4 text-sm text-gray-600">
          <Link to="/help" className="underline hover:text-green-600">
            {t('auth.otpCode.needHelp')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OTPCodeScreen;
